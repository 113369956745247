<template>
    <div class="filter-wrapper">

        <v-snackbar
                :absolute="true"
                v-model="notification"
                :color="'success'"
                :multi-line="false"
                :timeout="3000"
                :vertical="false"
                :bottom="true">

            {{ notification_message }}

            <v-btn dark text @click="notification = false">
                Close
            </v-btn>

        </v-snackbar>

        <v-form model="filter_form"
                v-on:keyup.enter="process_form()"
                ref="form"
                @submit="process_form()"
                onSubmit="return false;"
                lazy-validation="">

            <v-layout row wrap align-center justify-center fill-height>
                 <v-container class="py-0">
                     <v-row no-gutters justify="center" align="center" class="mx-8">
                         <template v-for="(field, key) in fields_to_filter">

                             <v-text-field
                                     v-if="['text', 'number', 'email', 'password'].includes(field.type)"
                                     class="ma-2"
                                     :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                     :name="field.table"
                                     v-validate="'required'"
                                     v-model="field.value"
                                     :rules="field.rules"
                                     :label="field.label"
                                     @keyup.enter="process_form()"

                                     :type="field.type"
                                     :required="field.required"
                             ></v-text-field>

                             <v-textarea
                                     v-if="['textarea'].includes(field.type)"
                                     class="ma-2"
                                     :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                     :name="field.table"
                                     v-validate="'required'"
                                     v-model="field.value"
                                     :rules="field.rules"
                                     :label="field.label"
                                     @keyup.enter="process_form()"

                                     :required="field.required"
                             ></v-textarea>

                             <v-select
                                     v-if="['select'].includes(field.type) && !field.multiple"
                                     class="ma-2"
                                     :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                     :name="field.table"
                                     v-validate="'required'"
                                     v-model="field.value"
                                     :items="field.options"
                                     :item-value="field.track_by"
                                     :item-text="field.show"
                                     :label="field.label"
                                     :required="field.required"
                                     @keyup.enter="process_form()"
                                     required="">
                             </v-select>

                             <v-select
                                     v-if="['select'].includes(field.type) && field.multiple"
                                     class="ma-2"
                                     :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                     :name="field.table"
                                     v-validate="'required'"
                                     v-model="stage"
                                     :multiple="true"
                                     :items="field.options"
                                     :item-value="field.track_by"
                                     :item-text="field.show"
                                     :label="field.label"
                                     :required="field.required"
                                     @keyup.enter="process_form()"
                                     required="">
                             </v-select>

                             <input v-if="['hidden'].includes(field.type)"
                                    type="hidden"

                                    v-model="field.value">

                         </template>

                         <v-btn fab small
                                color="primary"
                                class="mx-2"
                                @click="process_form()">
                             <v-icon> mdi-magnify </v-icon>
                         </v-btn>

                     </v-row>
                 </v-container>



            </v-layout>
        </v-form>

    </div>
</template>

<script>
    export default {
        name: 'FilterData',
        props: {
            original_url: String,
            fields_to_filter: Array,
        },
        data() {
            return {
                processed_data: [],
                notification: false,
                notification_message: '',
                submitted: false,
                stage: []
            }
        },

        methods: {
            get_text_name(field)
            {
                console.log(field);
                return field.track_by + ' - ' +field.show;
            },
            triggerNewSearch (url) {
                //Trigger with null to reset table
                return this.$emit('trigger-new-search', url);
            },

            process_form() {
                this.submitted = true;

                if (!this.$validator.validate()) {
                    console.log("invalid");
                    this.notification = true;
                    this.notification_message = 'Please fill in all required inputs';
                    return;
                }

                let _url = this.original_url;

                /**
                 * Search through the url for [tablename] and replace it
                 * with the value.
                 */
                let _items = [];
                for(let field of this.fields_to_filter){
                    if(field.value !== ''
                        && field.value !== '*'
                        && field.value !== 0 && typeof field.value !== 'undefined')
                    {
                        console.log(field.value);
                        if(!_items.length)
                        {
                            _url += '?';
                        } else {
                            _url += '&';
                        }
                        if(field.table === 'sourcers')
                        {
                            let sourcer = field.options.find(o => o.id === field.value);
                            if(typeof sourcer !== 'undefined')
                            {
                                _url+=field.table+'='+sourcer.categories.join(',');
                                _items.push(field.value);
                            }
                        } else {
                            _url+=field.table+'='+field.value;
                            _items.push(field.value);
                        }

                    }
                }
                if(typeof this.stage !=='undefined' && !this.stage.includes(0) && this.stage.length)
                {
                    let stage_ids = this.stage.join(',');
                    if(_url.includes('?'))
                    {
                        _url +='&stage_ids='+stage_ids
                    } else {
                        _url +='?stage_ids='+stage_ids
                    }
                }

                this.triggerNewSearch(_url);

            },

        }
    }
</script>
