
/**
 * @typedef {Object} field
 * @property {String} data_ref
 * @property {String} ref_string
 * @property {String} type
 * @property {String} filter
 * @property {String} multiple_method
 * @property {Array<String>} multiple_ref
 * @property {String} type
 */

/**
 * @typedef {Object} table_fields
 * @property {Array} field
 */

/**
 * @typedef {Object} fields
 * @property {Array} field
 */

/**
 * @typedef {Object} value_template
 *  @property {String} sheet_name
 *  @property {table_fields} table_fields
 *  @property {fields} fields
 *  @property {String} table_fields_init */


export default class Parser{

    getImages(workbook, worksheet){
        let media = {};

        for (let item of worksheet.getImages()){
            item.row = item.range.tl.nativeRow+1;
            item.col = item.range.tl.nativeCol+1;
            item.base64 = this.base64ArrayBuffer(workbook.media[item.imageId].buffer);
            item.extension = workbook.media[item.imageId].extension;

            delete item.worksheet;
            delete item.range;


            if(!(item.row in media))
                media[item.row] = {};

            if(!(item.col in media[item.row]))
                media[item.row][item.col] = {values: []};

            media[item.row][item.col].values.push(item);

        }

        return media;
    }

    getFieldData(values_template, worksheet){
        let values = {};
        let row_index = 1;
        let threshold = 100;

        let row = worksheet.getRow(row_index);
        let first_cell = row.getCell(1).value;

        while (row_index <= threshold && first_cell !== values_template.table_fields_init) {
            let [value, key] = this.getFieldData_single_value(values_template.fields, row);
            if(!!value) values[key] = value;
            row = worksheet.getRow(++row_index);
            first_cell = row.getCell(1).value;
        }
        return values;
    }

    getFieldData_single_value(fields, row) {
        let threshold = 5;
        let keys = Object.keys(fields);

        for(let key of keys){
            if (!!row.getCell(1).value && this.compareStrings(fields[key].ref_string, row.getCell(1).value)) {
                let index = 2;
                while(!row.getCell(index).value && index <= threshold)
                    index++;

                return [row.getCell(index).value, key];
            }
        }
        return [null, null];
    }

    getTableData(values_template, worksheet, media) {
        let threshold_x = 100;
        let row_index = 1;
        let table_values = Object.create({});


        let value = worksheet.getRow(row_index).getCell(1).value;
        while(!this.compareStrings(values_template.table_fields_init, value) && row_index <= threshold_x)
            value = worksheet.getRow(++row_index).getCell(1).value;

        this.getTableData_columnId(values_template.table_fields, worksheet.getRow(row_index++));
        media.offset = row_index;

        //Managing the table values
        while (row_index <= threshold_x) {
            let row = worksheet.getRow(row_index);
            if (!row.getCell(1).value) break;

            //Start parsing each item of the row
            let buffer = {};
            buffer.errors = [];

            for (let [key, field] of Object.entries(values_template.table_fields)) {
                if (!!field.col) {

                    buffer = Object.assign(buffer, this.getTableData_rowValues(row, key, field, media));

                    if (!!buffer[key])
                        buffer.errors.concat(this.getValueErrors(values_template.table_fields, buffer[key], values_template.regex));

                }
            }

            table_values[buffer[values_template.table_fields_index]] = buffer;

            row_index++;
        }
        return table_values;
    }

    getTableData_columnId(table_fields, row) {
        let column_index = 1;
        let threshold_y = 50;

        //Getting column locations
        let cell = row.getCell(column_index).value;

        while (column_index <= threshold_y) {
            for (let k of Object.keys(table_fields)) {
                // console.log(table_fields[k].ref_string, cell);
                if (this.compareStrings(table_fields[k].ref_string, cell)) {
                    table_fields[k].col.push(column_index);
                    if (row.getCell(column_index).isMerged)
                        for (let x = column_index; x <= row.getCell(column_index)._mergeCount; x++)
                            table_fields[k].col.push(x);
                    break;
                }
            }
            column_index++;
            cell = row.getCell(column_index).value;
        }
    }

    getTableData_rowValues(row, key, field, media) {
        let buffer = {};

        if (field.type === 'media') {
            if (!!media[row._number] && !!media[row._number][field.col[0]])
                buffer[key] = media[row._number][field.col[0]].values;
        } else {
            //Super duper hack for js to actually create a new array each iteration!!!
            let values = {};

            field.col.forEach(col => {
                if(!!row.getCell(col).value)
                    values[col] = row.getCell(col).value.text? row.getCell(col).value.text : row.getCell(col).value;
            });

            switch (field.method) {
                case 'different':
                    for(let index = 0; index < Object.entries(field.multiple_ref).length; index++)
                        buffer[key + field.multiple_ref[index]] = Object.values(values)[index];
                    break;
                case 'join':
                    buffer[key] = Object.values(values).join(", ");
                    break;
                default:
                    switch(field.type){
                        case 'integer':
                            buffer[key] = Number.parseInt(Object.values(values)[0] || 0);
                            break;
                        case 'float':
                            buffer[key] = Number.parseFloat(Object.values(values)[0] || 0);
                            break;
                        case 'string': default:
                            buffer[key] = Object.values(values).join(", ");
                            break;
                    }
            }
        }
        return buffer;
    }

    getValueErrors(fields, to_check, regex){
        for(let [key, field] of Object.entries(fields)){
            if(!!field.required && !to_check[field.data_ref])
                return "The required field \'" + field.ref_string + " | " +  field.data_ref + "\' was not found in the file";
            //TODO: check for provided data type
            if(!!field.type)
                switch(field.type){
                    case "integer":
                        if(to_check[field.data_ref] !== parseInt(to_check[field.data_ref], 10))
                            return "The required field \'" + field.ref_string + " | " +  field.data_ref + "\' does not match " + to_check[field.data_ref] + " as an integer type";
                        break;
                    case "float":
                        if(to_check[field.data_ref] !== parseFloat(to_check[field.data_ref]))
                            return "The required field \'" + field.ref_string + " | " +  field.data_ref + "\' does not match " + to_check[field.data_ref] + " as an float type";
                        break;
                    case "string":
                        if (!(typeof to_check[field.data_ref] === 'string' || to_check[field.data_ref] instanceof String))
                            return "The required field \'" + field.ref_string + " | " +  field.data_ref + "\' does not match " + to_check[field.data_ref] + " as an string type";
                        break;
                }

            //TODO: check for provided regex
            if(!!field.filter && !!field.filter.length)
                for(let regex_check of field.filter){
                    if(!!regex[regex_check]){
                        let regexExp = regex[regex_check]();
                        if(!regexExp.test(to_check[field.data_ref])){
                            console.warn(field.ref_string, to_check[field.data_ref], regexExp.toString());
                            return "The required field \'" + field.ref_string + " | " +  field.data_ref + "\' with value \'" + to_check[field.data_ref] + "\' did not pass as a valid value under the expression defined for " + regex_check ;
                        }
                    }else{
                        console.warn("A check for the Regexp with type \'" + regex_check +"\' was called, but no defined regex was found");
                    }

                }


        }
        return false;
    }

    compareStrings(string1, string2, options = 'gims'){
        let buf_string1 = this.sanitizeString(string1);
        let buf_string2 = this.sanitizeString(string2);

        return (new RegExp(buf_string1, options)).test(buf_string2);
    }

    sanitizeString(string){
        if(!string || typeof string !== 'string') return "";
        let sanitize_regex = /[()\\/\-_^".,<>$#%&*=+!?~` \n]+/g;

        return string.replace(sanitize_regex, "");
    }

    base64ArrayBuffer(bytes) {
        let base64    = '';
        let encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

        let byteLength    = bytes.byteLength;
        let byteRemainder = byteLength % 3;
        let mainLength    = byteLength - byteRemainder;

        let a, b, c, d;
        let chunk;

        // Main loop deals with bytes in chunks of 3
        for (let i = 0; i < mainLength; i = i + 3) {
            // Combine the three bytes into a single integer
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

            // Use bitmasks to extract 6-bit segments from the triplet
            a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
            b = (chunk & 258048)   >> 12; // 258048   = (2^6 - 1) << 12
            c = (chunk & 4032)     >>  6; // 4032     = (2^6 - 1) << 6
            d = chunk & 63;              // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
        }

        // Deal with the remaining bytes and padding
        if (byteRemainder === 1) {
            chunk = bytes[mainLength];

            a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

            // Set the 4 least significant bits to zero
            b = (chunk & 3)   << 4; // 3   = 2^2 - 1

            base64 += encodings[a] + encodings[b] + '=='
        }
        else if (byteRemainder === 2) {
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

            a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
            b = (chunk & 1008)  >>  4; // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            c = (chunk & 15)    <<  2; // 15    = 2^4 - 1

            base64 += encodings[a] + encodings[b] + encodings[c] + '='
        }

        return base64
    }

    runProcesses(values_template, values, table_values){
        // Post process per value
        for(let process of values_template.post_processes)
            process(values, table_values, values_template.tools);

        // Post process per value column
        for(let item of Object.values(table_values))
            for(let process of values_template.post_processes_for_each)
                process(item, values_template.tools);
    }

}
