// https://vuex.vuejs.org/en/mutations.html

let mutations = {
    updateToken: (state, data) =>{
        localStorage.setItem('access_token', data.token);

        state.accessToken = data.token;

        //Update the axios token every time the actual token is refreshed
        state.$http.defaults.headers.common['Authorization'] =  localStorage.getItem('access_token') ? 'jwt ' + localStorage.getItem('access_token') : undefined;

        let time_obj = {
            refresh_hours: 2,
            refresh_max:3,
            max_hours: 6,
            current_refresh_count:0,
            timestamp: new Date().getTime()
        };
        localStorage.setItem('storage_time', JSON.stringify(time_obj) );

        localStorage.setItem('user', JSON.stringify(data.user));
        state.user = data.user;
    },
    updateUseAsUser: (state, data) =>{
        /**
         * First get existing_data and set previous_details_
         */
        let _previous_details = {
            user: JSON.parse(localStorage.getItem('user')),
            storage_time: JSON.parse(localStorage.getItem('storage_time')),
            access_token: localStorage.getItem('access_token'),
        };

        localStorage.setItem('_previous_details', JSON.stringify(_previous_details));

        /**
         * Now update the existing info
         */
        localStorage.setItem('access_token', data.token);
        state.accessToken = data.token;

        //Update the axios token every time the actual token is refreshed
        state.$http.defaults.headers.common["Authorization"] = localStorage.getItem('access_token') ? 'jwt ' + localStorage.getItem('access_token') : undefined;

        let time_obj = {
            refresh_hours: 2,
            refresh_max:3,
            max_hours: 6,
            current_refresh_count:0,
            timestamp: new Date().getTime()
        };

        localStorage.setItem('storage_time', JSON.stringify(time_obj) );

        localStorage.setItem('user', JSON.stringify(data.user));
        localStorage.setItem('use_as_user', JSON.stringify(data.use_as_user));
        state.user = data.user;
    },
    updateLinkLogin: (state, data) => {
        localStorage.setItem('access_token', data.token);
        state.accessToken = data.token;

        localStorage.setItem('user', JSON.stringify(data.user));
        state.user = data.user;

        /** Now update the existing info  **/
        state.$http.defaults.headers.common["Authorization"] = localStorage.getItem('access_token') ? 'jwt ' + localStorage.getItem('access_token') : undefined;

        let time_obj = {
            refresh_hours: 2,
            refresh_max:3,
            max_hours: 6,
            current_refresh_count:0,
            timestamp: new Date().getTime()
        };

        localStorage.setItem('storage_time', JSON.stringify(time_obj) );
    },
    cancelUseAsUser: (state, context) =>{
        /**
         * First get _previous_details from local storage
         */
        let _previous_details = JSON.parse(localStorage.getItem('_previous_details'));


        /**
         * Now update the existing info
         */
        localStorage.setItem('access_token', _previous_details.access_token);
        state.accessToken = _previous_details.token;

        //Update the axios token every time the actual token is refreshed
        state.$http.defaults.headers.common["Authorization"] = localStorage.getItem('access_token') ? 'jwt ' + localStorage.getItem('access_token') : undefined;

        localStorage.setItem('storage_time', JSON.stringify(_previous_details.storage_time) );
        localStorage.setItem('user', JSON.stringify(_previous_details.user) );
        state.user =_previous_details.user;
        /**
         * Final Step remove _previous_details
         */
        localStorage.removeItem('_previous_details');
    },

    removeToken:(state, context) => {
        state.$http.defaults.headers.common["Authorization"] =  undefined;
        localStorage.removeItem('access_token');
        state.accessToken = null;
    },
    removeUser:(state) => {
        localStorage.removeItem('user');
        state.user = undefined;
        state.user_groups = undefined;
        state.user_groups_names = undefined;
        state.user_permissions = undefined;
    },
    removeBufferUser:(state) => {
        localStorage.removeItem('user');
        state.user = undefined;
        state.user_groups = undefined;
        state.user_groups_names = undefined;
        state.user_permissions = undefined;
    },
    setResponsive:(state, width) =>{
        state.responsive = (width < 991);
    }
};

export default mutations;

