export default {
    sheet_name: "SUPPLIER FORMAT",

    fields: {
        factory_name: {
            ref_string: "FACTORY NAME",
            label: "Factory name:",
            required: "true",
            type: "string",
            filter: ["name"]
        },
        factory_address: {
            ref_string: "ADDRESS",
            label: "Address:",
            type: "string",
            filter: ["name"]
        },
        factory_contact_number: {
            ref_string: "TEL",
            label: "Telephone:",
            type: "string",
            filter: ["phone"]
        },
        factory_contact_name: {
            ref_string: "CONTACT PERSON",
            label: "Contact person:",
            type: "string",
            filter: ["name"]
        },
        factory_email: {
            ref_string: "EMAIL",
            label: "Email:",
            type: "string",
            filter: ["email"]
        },
        factory_website: {
            ref_string: "FACTORY WEBSITE",
            label: "Factory website:",
            type: "string",
            filter: ["url"]
        },
        shipping_port: {
            ref_string: "SHIPPING PORT",
            label: "Shipping port:",
            type: "string",
            filter: ["name"]
        },
        sourcer: {
            ref_string: "sourcer",
            label: "Sourcer",
            required: "true",
            type: "string",
            filter: ["email"]
        }
    },
    table_fields_init: "Item No.",
    table_fields_index: 'item_number',
    table_fields: {
        item_number: {
            col: [],
            label: "Item No.",
            ref_string: "Item No.",
            required: "true",
            type: "string",
            filter: ["code"]
        },
        name: {
            col: [],
            label: "Product name",
            required: "true",
            ref_string: "Name",
            type: "string",
            filter: ["name"]
        },
        photo: {
            col: [],
            label: "Product media",
            required: "true",
            ref_string: "photo",
            type: "media"
        },
        description: {
            col: [],
            label: "Description",
            required: "true",
            ref_string: "Description Include material",
            type: "string",
            filter: ["name"]
        },
        packing: {
            col: [],
            label: "Packing",
            required: "true",
            ref_string: "Packing",
            type: "string",
            filter: ["name"]
        },
        item_size: {
            col: [],
            label: "Item size [Single unit](Cm)",
            required: "true",
            ref_string: "Item Size cm",
            type: "float",
            multiple: "true",
            method: "different",
            multiple_ref: ["_width", "_height", "_depth"],
            value_format: "concat"
        },
        unit: {
            col: [],
            label: 'Unit',
            ref_string: "Unit",
            required: "true",
            type: "code"
        },
        pieces_per_master_carton: {
            col: [],
            label: "Items per master carton",
            required: "true",
            ref_string: "Qty/Ctn",
            type: "integer"
        },
        qty_moq: {
            col: [],
            label: "Minimum order quantity",
            required: "true",
            ref_string: "MOQ",
            type: "string"
        },
        fob_price: {
            col: [],
            label: "FOB price",
            required: "true",
            ref_string: "FOB (USD)",
            type: "float",
            filter: ["currency"]
        },
        carton_measures: {
            col: [],
            label: "Master Carton size (Cm)",
            ref_string: "Carton Measures (cm)",
            type: "float",
            multiple: "true",
            required: "true",
            method: "different",
            multiple_ref: ["_width", "_height", "_depth"],
        },
        master_carton_cbm: {
            col: [],
            label: "Master carton CBM",
            required: "true",
            ref_string: "Carton CBM (m^2)",
            type: "integer"
        },
        gross_w: {
            col: [],
            label: "Master carton gross weight",
            ref_string: "Gross Weight (kg)",
            type: "integer"
        },
        net_weight: {
            col: [],
            label: "Master Carton net weight",
            ref_string: "Net Weight (kg)",
            type: "integer"
        },
        qty_20: {
            col: [],
            label: "20\" Qty",
            ref_string: "20\" Qty",
            required: "false",
            type: "integer"
        },
        qty_40: {
            col: [],
            label: "40\" Qty",
            ref_string: "40\" Qty",
            required: "false",
            type: "integer"
        },
        qty_40hq: {
            col: [],
            label: "40HQ Qty",
            ref_string: "40HQ Qty",
            required: "false",
            type: "integer"
        },
        category_id: {
            col: [],
            label: "Category ID",
            required: "false",
            ref_string: "Category ID",
            type: "integer"
        },
        specification: {
            col: [],
            label: "Other remarks",
            ref_string: "Remarks",
            required: "false",
            type: "string",
            filter: ["currency"]
        }
    },
    post_processes: [
        function(values, table_values, tools){
            //Function to get the text out of each string value on the upper table
            for(let key of Object.keys(values))
                if(values[key] instanceof Object)
                    if(Object.keys(values[key]).includes('text'))
                        values[key] = values[key].text

        },
    ],
    post_processes_for_each: [
        function(product, tools){
            product.description = product.description + "\t MOQ: "+ product.qty_moq;
            product.qty_moq = parseInt(product.qty_moq);
        },
        function(product, tools){
            product.slug = tools.sanitize_for_search(product.name);
        },
        function(product, tools){
            product.album = {};
            product.album.files = product.photo;
        }
    ],
    tools :{
        sanitize_for_search: function (string){
            if(!string) return "";
            // TODO: check the sinitizing regex on the backend for the unique_id on the department
            let sanitize_regex = /[()\\/\-_^".,<>$#%&*=+!?~` \n]+/g;

            return string.replace(sanitize_regex, "").toLowerCase();
        },
    },
    regex :{},
    setRegex: function (regex){
          this.regex = Object.assign(this.regex, regex);
    }
}
;

