<template>
    <div>

        <cpanel-filter-data :original_url="filter_url"
                            :fields_to_filter="filter_fields"
                            @trigger-new-search="triggerSearch">
        </cpanel-filter-data>

        <div class="split_table" v-if="headers_one.length && headers_two.length">
            <v-row>
                <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
                    <v-simple-table :loading="loading">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th v-for="(header, index) in headers_one" :key="index"
                                    :class="{'text-left': header.align === 'left', 'text-center': header.align === 'center'}"

                                >
                                    {{ header.text }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in invoices">
                                <td :class="'st-block stage-block-'+item.stage" v-for="(header, index) in headers_one">
                                    <div @mouseenter="check_load(item.id)" @mouseleave="check_load(item.id)"
                                         :style="'width:'+header.width+'px; text-align:center;'"
                                         :class="{'text-truncate': header.truncate}">
                                        <div v-if="header.custom">

                                            <div v-if="header.value === 'customer'">
                                                {{ item.customer.name }}
                                            </div>

                                            <div v-if="header.value === 'salesman'">
                                                {{ item.salesman.first_name }}
                                            </div>

                                            <div v-if="header.value === 'chat'">
                                                <v-btn x-small rounded fab @click="orderChatPopup(item)">
                                                    <v-icon>mdi-message</v-icon>
                                                </v-btn>
                                            </div>

                                            <div v-if="header.value === 'stage'">
                                                <v-btn x-small fab
                                                       v-tooltip="'Last change: ' + get_stage_change_text(item)"
                                                       v-if="[6].includes(user_type)"
                                                       class="outline_btn stage_button"
                                                       :class="'stage_btn-'+item.stage">
                                                    {{ item.stage }}
                                                </v-btn>
                                                <v-menu :offset-x="true" fixed v-if="![6].includes(user_type)">
                                                    <template v-slot:activator="{ on }">
                                                        <v-btn x-small fab v-on="on"
                                                               v-tooltip="'Last change: ' + get_stage_change_text(item)"
                                                               class="outline_btn stage_button"
                                                               :class="'stage_btn-'+item.stage">
                                                            {{ item.stage }}
                                                        </v-btn>
                                                    </template>
                                                    <v-list dense v-if="item.stage < 11">
                                                        <v-list-item v-for="stage in stages"
                                                                     :key="stage.id"
                                                                     @click="editOrderStage(stage.id, item)">
                                                            <v-list-item-title>{{stage.id}} - {{stage.name}}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>

                                            <div v-if="header.value === 'actions'">
                                                <v-btn text x-small color="#444444" class="d-inline px-1"
                                                       :loading="item.is_loading_all_file_zip"
                                                       :disabled="item.is_loading_all_file_zip"
                                                       @click="set_download_files($event, item)">
                                                    <v-icon>mdi-folder-download</v-icon>
                                                </v-btn>
                                            </div>

                                            <div v-if="header.value === 'shipped'">
                                                <v-icon>
                                                    {{(!!item.signed_off)? 'mdi-circle-slice-8': 'mdi-circle-outline'}}
                                                </v-icon>
                                            </div>
                                        </div>
                                        <div v-if="!header.custom" :class="{'text-truncate': header.truncate}">
                                            {{ item[header.value] }}
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
                <v-col cols="12" sm="6" md="6" lg="6" class="pa-0">
                    <v-simple-table :loading="loading">
                        <template v-slot:default>
                            <thead>
                            <tr>
                                <th v-for="(header, index) in headers_two" :key="index"
                                    :class="{'text-left': header.align === 'left', 'text-center': header.align === 'center'}">
                                    {{ header.text }}
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in invoices">
                                <td :class="'st-block stage-block-'+item.stage" v-for="(header, index) in headers_two">
                                    <div @mouseenter="check_load(item.id)" @mouseleave="check_load(item.id)"
                                         :style="'width:'+header.width+'px; text-align:center;'"
                                         :class="{'text-truncate': header.truncate}">
                                        <div v-if="header.custom">
                                            <div v-if="header.value === 'sales_diff'">
                                                {{ (item.sales_pi - item.clients_total_sales) | currency }}
                                            </div>
                                            <div v-if="header.value === 'profit'">
                                                {{ (((item.sales_pi - item.factory_pi) * 100) /
                                                item.sales_pi).toFixed(2) }}<sup>%</sup>
                                            </div>
                                            <div v-if="header.value === 'factory_diff'">
                                                {{ (item.factory_pi - item.factory_total_sales) | currency }}
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                        <div v-if="!header.custom">
                                            <div v-if="header.currency">
                                                {{ item[header.value] | currency }}
                                            </div>
                                            <div v-if="!header.currency">
                                                {{ item[header.value] }}
                                            </div>
                                        </div>
                                    </div>

                                </td>
                            </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </div>
        <div class="single-table" v-if="headers.length">
            <v-data-table :headers="headers"
                          :items="invoices"
                          header-props.sort-icon="mdi-menu-up"
                          hide-default-footer
                          light
                          dense
                          :loading="loading"
                          :fixed-header="true"
                          :height="550"

                          :class="'order-table'"
                          :items-per-page="1000">

                <template v-slot:item.description="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        <div style="max-width:150px;" class="text-truncate" v-tooltip="item.description">
                            {{ item.description.slice(0, 15) }}
                        </div>
                    </div>
                </template>

                <template v-slot:item.pi_number="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ item.pi_number.slice(0, 6) }}
                    </div>
                </template>

                <template v-slot:item.customer="{ item }">
                    <div style="width:250px" :class="'st-block stage-block-'+item.stage"
                         @mouseenter="check_load(item.id)" @mouseleave="check_load(item.id)">
                        {{ item.customer.name.slice(0, 24) }}
                    </div>
                </template>

                <template v-slot:item.salesman="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ item.salesman.email }}
                    </div>
                </template>

                <template v-slot:item.safe_date="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)" style="width:100px" class="caption">
                        {{ (item.safe_date !== null)? item.safe_date.match(/\d+[-\/]\d+[-\/]\d+/gm)[0] : '-' }}
                    </div>
                </template>

                <template v-slot:item.department_name="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)" style="width:200px;">
                        {{ item.department_name.slice(0,20) }}
                    </div>
                </template>

                <template v-slot:item.chat="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        <v-btn x-small rounded fab @click="orderChatPopup(item)">
                            <v-icon>mdi-message</v-icon>
                        </v-btn>
                    </div>
                </template>

                <template v-slot:item.po_number="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)" style="width:140px;">
                    <span v-if="!item.po_number || item.po_number === ''">
                        -
                    </span>
                        <span v-if="item.po_number && item.po_number !== ''">
                        {{ item.po_number.substring(0, 10) }}
                    </span>

                    </div>
                </template>

                <template v-slot:item.stage="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        <v-btn x-small fab
                               v-tooltip="'Last change: ' + get_stage_change_text(item)"
                               v-if="[6].includes(user_type)"
                               class="outline_btn stage_button" :class="'stage_btn-'+item.stage">
                            {{ item.stage }}
                        </v-btn>
                        <v-menu :offset-x="true" fixed v-if="![6].includes(user_type)">
                            <template v-slot:activator="{ on }">
                                <v-btn x-small fab v-on="on" v-tooltip="'Last change: ' + get_stage_change_text(item)"
                                       class="outline_btn stage_button" :class="'stage_btn-'+item.stage">
                                    {{ item.stage }}
                                </v-btn>
                            </template>
                            <v-list dense v-if="item.stage < 11">
                                <v-list-item v-for="stage in stages"
                                             :key="stage.id"
                                             @click="editOrderStage(stage.id, item)">
                                    <v-list-item-title>{{stage.id}} - {{stage.name}}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>

                <template v-slot:item.actions="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        <div style="display: inline-block; width:180px">
                            <v-btn text x-small color="#444444" class="d-inline px-1"
                                   @click="showInvoiceFolders(item)">
                                <v-icon>mdi-folder</v-icon>
                            </v-btn>

                            <v-btn text x-small color="#444444" class="d-inline px-1"
                                   :loading="item.is_loading_all_file_zip" :disabled="item.is_loading_all_file_zip"
                                   @click="set_download_files($event, item)">
                                <v-icon>mdi-folder-download</v-icon>
                            </v-btn>

                            <v-btn text x-small color="#444444" class="d-inline px-1"
                                   v-if="[1, 2, 3, 6, 9, 15, 21].includes(user_type)"
                                   :loading="item.is_loading_pi" :disabled="item.is_loading_pi"
                                   @click="download_generated_pi_or_quote($event, item)">
                                <v-icon>mdi-inbox-arrow-down</v-icon>
                            </v-btn>

                            <v-btn text x-small color="#444444" class="d-inline px-1"
                                   v-if="get_edit_access() === true"
                                   @click="click_manage_edit_button(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>

                            <v-btn text x-small color="#444444" class="d-inline px-1"
                                   v-if="[1, 2, 3, 9, 11, 12, 13, 15].includes(user_type)"
                                   @click="preview(item)">
                                <v-icon>mdi-magnify</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </template>

                <template v-slot:item.factory_pi="{ item }">
                    <v-row :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                           @mouseleave="check_load(item.id)"
                           @click="download_generated_all_factory_spreadsheets($event, item)">
                        <v-btn text large block class="font-weight-light">
                        <span v-if="item.minus_amount > 0">
                            {{(item.factory_pi - item.minus_amount) | currency }}
                        </span>
                            <span v-if="!item.minus_amount || item.minus_amount <= 0">
                            {{ item.factory_pi | currency }}
                        </span>

                            <v-icon small color="wst_text_color" class="ml-1">mdi-clipboard-arrow-down</v-icon>
                        </v-btn>
                    </v-row>
                </template>

                <template v-slot:item.sales_pi="{ item }">
                    <v-row :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                           @mouseleave="check_load(item.id)"
                           @click="download_generated_pi_or_quote($event, item)">
                        <v-btn text large block class="font-weight-light">
                            {{ item.sales_pi | currency }}
                            <v-icon small color="wst_text_color" class="ml-1">mdi-clipboard-arrow-down</v-icon>
                        </v-btn>
                    </v-row>
                </template>

                <template v-slot:item.percentage="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ (((item.sales_pi - item.factory_pi) * 100) / item.sales_pi).toFixed(2) }}<sup>%</sup>
                    </div>
                </template>

                <template v-slot:item.profit="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ item.sales_pi - item.factory_pi | currency }}
                    </div>
                </template>

                <template v-slot:item.final_factory_pi="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ item.factory_total_sales | currency }}
                    </div>
                </template>

                <template v-slot:item.factory_diff="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ (item.factory_pi - item.factory_total_sales) | currency }}
                    </div>
                </template>

                <template v-slot:item.final_sales_pi="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ item.clients_total_sales | currency }}
                    </div>
                </template>

                <template v-slot:item.sales_diff="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ (item.sales_pi - item.clients_total_sales) | currency }}
                    </div>
                </template>

                <template v-slot:item.partner_profit="{ item }">
                    <v-row :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                           @mouseleave="check_load(item.id)"
                           @click="download_generated_breakdown($event, item)">
                        <v-btn text large block class="font-weight-light">
                            {{ item.partner_profit | currency }}
                            <v-icon small color="wst_text_color" class="ml-1">mdi-clipboard-arrow-down</v-icon>
                        </v-btn>
                    </v-row>
                </template>

                <template v-slot:item.wellstar_final_income="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        {{ item.wellstar_final_income | currency }}
                    </div>
                </template>

                <template v-slot:item.shipped="{ item }">
                    <div :class="'st-block stage-block-'+item.stage" @mouseenter="check_load(item.id)"
                         @mouseleave="check_load(item.id)">
                        <v-icon>
                            {{(!!item.signed_off)? 'mdi-circle-slice-8': 'mdi-circle-outline'}}
                        </v-icon>
                    </div>
                </template>

                <template v-slot:footer="">

                    <v-layout align-center justify-center row fill-height
                              class="pa-4">
                        <v-progress-circular
                                v-if="!!loading"
                                :width="4"
                                color="primary"
                                indeterminate
                        ></v-progress-circular>
                        <h4 v-if="!loading && !load_url">
                            <v-icon>mdi-playlist-check</v-icon>
                            All items loaded successfully
                        </h4>
                    </v-layout>

                </template>

            </v-data-table>
        </div>


        <div class="order-chat-wrapper" v-if="chat_opened === true">
            <w-order-chat :selected_invoice="selected_invoice" @close_chat="close_chat()"/>
        </div>

        <div class="order-totals" v-if="totals_show && totals.sales_pi && totals.sales_pi.total > 0">

            <v-card :min-width="550" :height="75" class="blue-grey darken-4">
                <table class="totals-table">
                    <thead>
                    <tr>
                        <th class="text-left">Factory</th>
                        <th class="text-left">Sales</th>
                        <th class="text-left">%</th>
                        <th class="text-left">Partner</th>
                        <th class="text-left">Wellstar</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            {{ totals.factory_pi.total | currency }}
                        </td>
                        <td>
                            {{ totals.sales_pi.total | currency }}
                        </td>
                        <td>
                            {{ (((totals.sales_pi.total - totals.factory_pi.total) * 100) /
                            totals.sales_pi.total).toFixed(2) }}
                        </td>
                        <td>
                            {{ totals.partner_profit.total | currency }}
                        </td>
                        <td>
                            {{ totals.wellstar_income.total | currency }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </v-card>
        </div>

        <v-dialog v-model="download_model">
            <v-card>
                <v-card-title>
                    Download Options {{ download.order.pi_number }}
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="6" md="6">
                            <h2 class="title">
                                Downloads
                            </h2>
                            <v-divider></v-divider>
                            <v-list
                                    flat
                                    subheader
                                    three-line
                            >

                                <v-list-item-group
                                        multiple
                                        active-class=""
                                >
                                    <v-list-item
                                            v-if="[1,2,9,12,15].includes(user_type)"
                                            @click="download_generated_pi_or_quote(download.event, download.order, 'quote')">
                                        <template>
                                            <v-list-item-action style="margin-top:45px;">

                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>Customer Quote</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Download The Customer Quote
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item
                                            v-if="[1,2,9,12,15].includes(user_type)"
                                            @click="download_generated_pi_or_quote(download.event, download.order, 'pi')">
                                        <template>
                                            <v-list-item-action style="margin-top:45px;">

                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>Customer Invoice</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Download The Customer PI
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item
                                            v-if="[1,2,3,12,15].includes(user_type)"
                                            @click="download_generated_all_factory_spreadsheets(download.event, download.order)">
                                        <template>
                                            <v-list-item-action style="margin-top:45px;">

                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>Factory PO's</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Download Factory PO's
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>

                                    <v-list-item
                                            v-if="[1,2,12,15].includes(user_type)"
                                            @click="download_all_files(download.event, download.order)">
                                        <template>
                                            <v-list-item-action style="margin-top:45px;">

                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>ALL</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Download all files relating to this order.
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item
                                            v-if="[1,2,12].includes(user_type)"
                                            @click="download_generated_breakdown(download.event, download.order)">
                                        <template>
                                            <v-list-item-action style="margin-top:45px;">

                                            </v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>Breakdown</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Download Partner Breakdown
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>

                                </v-list-item-group>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="6" md="6">
                            <h2 class="title">
                                Options
                            </h2>
                            <v-divider></v-divider>
                            <v-list
                                    flat
                                    subheader
                                    three-line
                            >

                                <v-list-item-group
                                        multiple
                                        active-class=""
                                >
                                    <v-list-item
                                            v-if="[1,2,9,12,15].includes(user_type)"
                                            @click="preview(download.order)">
                                        <template>
                                            <v-list-item-action style="margin-top:45px;"></v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>Preview</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Preview PI Before Download
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item
                                            v-if="[1,2,9,12,15].includes(user_type)"
                                            @click="click_manage_edit_button(download.order)">
                                        <template>
                                            <v-list-item-action style="margin-top:45px;"></v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>Edit</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Edit qty, cbm fob prices for this PI.
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                    <v-list-item
                                            v-if="[1,2,9,12,15].includes(user_type)"
                                            @click="showInvoiceFolders(download.order)">
                                        <template>
                                            <v-list-item-action style="margin-top:45px;"></v-list-item-action>

                                            <v-list-item-content>
                                                <v-list-item-title>View Files</v-list-item-title>
                                                <v-list-item-subtitle>
                                                    Upload and Download Files relating to this PI, IE: Shipping Marks
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </template>
                                    </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                        </v-col>
                    </v-row>


                    <v-overlay :value="loading">
                        <v-progress-circular indeterminate size="64"></v-progress-circular>
                    </v-overlay>
                </v-card-text>

                <v-card-actions>
                    <v-btn
                            block outlined
                            color="blue-grey darken-4"
                            @click="download_model = false"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </div>


</template>

<script>
    import InvoiceFilesManager from "@/components/cpanel/InvoiceFilesManager";
    import OrderChat from "@/components/w/OrderChat"
    import {saveAs} from 'file-saver'
    import JSZip from 'jszip';
    import {
        download_files_digested,
        remove_slash_and_backslash,
        zip_generate_options
    } from "@/plugins/order_all_file_zip";
    import {
        gen_order_pi,
        gen_order_quote,
        gen_breakdown,
        gen_po,
        gen_factory_po
    } from '@/plugins/excel_pi_po_generator';

    export default {
        components: {InvoiceFilesManager, OrderChat},
        name: "Orders",
        props: {
            link_type: String,
            headers: {
                type: Array,
                default: function () {
                    return []
                }
            },
            headers_one: {
                type: Array,
                default: function () {
                    return []
                }
            },
            headers_two: {
                type: Array,
                default: function () {
                    return []
                }
            }
        },
        data: () => ({
            loading: false,
            chat_opened: false,
            //Url to search
            init_url: '/order/',
            load_url: undefined,
            parsed_url: '',
            filter_url: undefined,
            filter_fields: [],
            // Behaviour variables
            invoices: [],
            selected_invoice: undefined,
            load_at: 25,
            default_stages: [
                {id: 1, name: 'Sourcer To Check'},
                {id: 2, name: 'Sales To Check'},
                {id: 3, name: 'Sales Manager To Check'},
                {id: 4, name: 'Quotation Canceled'},
                {id: 5, name: 'Logistics To Check (Confirmed Order)'},
                {id: 6, name: 'Logistics Checked (Needs sending to partner)'},
                {id: 7, name: 'Send To Partner'},
                {id: 8, name: 'Partner Confirmed Received'},
                {id: 9, name: 'Partner Confirmed Production Placement'},
                {id: 10, name: 'Order Placed'},
                {id: 11, name: 'Shipped'},
            ],
            stages: [],
            user_groups: [],
            user_type: 0,

            totals_show: false,
            totals: {
                sales_pi: {total: 0},
                factory_pi: {total: 0},
                partner_profit: {total: 0},
                wellstar_income: {total: 0},
            },
            download_model: false,
            download: {
                event: {},
                order: {}
            }

        }),
        watch: {
            selected_invoice: function (value) {
                if (typeof value === 'undefined')
                    this.chat_opened = false
            }
        },
        created() {
            // this is to set permissions on who can change to what stage
            this.user = this.$store.getters.user;
            this.build_stage_permissions(this.user);

            //Setting the initial url for the loading
            this.load_url = this.filter_url = this.init_url;

            //Setting the array of objects to intexte the filter component
            this.init_filter_fields();
            this.loadOrders();
        },
        methods: {
            async loadOrders() {
                if (!!this.load_url && !this.loading) {
                    this.loading = true;

                    let data = await this.$hget(this.load_url).then(response => response.data);
                    console.log(data);
                    this.invoices = this.invoices.concat(data.results);
                    for (let i of this.invoices) {
                        this.$set(i, 'is_loading_all_file_zip', false);
                        this.$set(i, 'is_loading_pi', false);
                        this.$set(i, 'is_loading_quote', false);
                        this.$set(i, 'is_loading_po', false);
                    }
                    if (typeof this.invoices[(this.invoices.length - 1) - 20] !== 'undefined') {
                        this.load_at = this.invoices[(this.invoices.length - 1) - 20].id;
                    }

                    this.load_url = data.next ? data.next : false;
                    this.loading = false;

                    this.load_totals();
                }
            },

            check_load(id) {
                console.log('ID Check Load');
                if (id <= this.load_at) {
                    console.log("Load More");
                    if (!this.loading) {
                        this.loadOrders();
                    }

                }
            },

            set_download_files(event, order) {
                console.log(event, order);
                this.download_model = true;
                this.download = {
                    event: event,
                    order: order
                }
            },

            async download_all_files(event, order) {
                this.loading = true;

                order.is_loading_all_file_zip = true;
                let zip_buffer = await download_files_digested(this, order);
                await saveAs(new Blob([zip_buffer]), order.pi_number + ".zip");
                order.is_loading_all_file_zip = false;
                this.loading = false

            },

            async download_generated_pi_or_quote(event, order, type) {
                order.is_loading_pi = true;
                this.loading = true;

                //TODO: Get lines for factory
                let pi = await this.$hget('/admins/order/' + order.pi_number + '/pi/?type=pi').then(r => r.data);
                let worksheet_buffer = '';
                if (typeof type === 'undefined') {
                    worksheet_buffer = (order.stage > 5) ? await gen_order_pi(this, pi, pi.order_lines) : await gen_order_quote(this, pi, pi.order_lines);
                } else {
                    if (type === 'pi') {
                        worksheet_buffer = await gen_order_pi(this, pi, pi.order_lines);
                    } else {
                        worksheet_buffer = await gen_order_quote(this, pi, pi.order_lines);
                    }
                }
                await saveAs(new Blob([worksheet_buffer]), order.pi_number + ".xlsx");
                order.is_loading_pi = false;
                this.loading = false;

            },

            async download_generated_breakdown(event, order) {
                this.loading = true;
                if ([1, 2, 12, 13, 15].includes(this.user_type)) {
                    let pi = await this.$hget('/admins/order/' + order.pi_number + '/pi/?type=pi').then(r => r.data);
                    let worksheet_buffer = await gen_breakdown(this, pi, pi.order_lines);
                    await saveAs(new Blob([worksheet_buffer]), order.pi_number + ".xlsx");
                }
                this.loading = false;

            },

            async download_generated_all_factory_spreadsheets(event, order) {

                this.loading = true;

                let zip = new JSZip();
                let pi = await this.$hget('/admins/order/' + order.pi_number + '/pi/?type=pi').then(r => r.data);

                /** The order object aready has the factories that the user has access to **/
                let factories = [];

                if (typeof order.factories !== 'undefined') factories = order.factories;
                else if (typeof order.factory !== 'undefined') factories = [order.factory];
                let zip_folder = zip.folder(remove_slash_and_backslash(pi.pi_number));

                for (let factory of factories) {
                    let lines = await this.$hget('/admins/order/' + order.pi_number + '/order_lines/', {params: {factory_id: factory.id}}).then(r => r.data);
                    let sheet_buffer = (order.stage > 5) ? await gen_factory_po(this, pi, lines, true) : await gen_factory_po(this, pi, lines, true);
                    zip_folder.file(remove_slash_and_backslash(factory.name) + '.xlsx', sheet_buffer, {binary: true});
                }
                let zip_buffer = await zip.generateAsync(zip_generate_options);
                await saveAs(new Blob([zip_buffer]), order.pi_number + "__factories_pi.zip");
                this.loading = false;

            },

            click_manage_edit_button(order) {
                console.log("Edit PI");
                console.log(order);
                if (order.stage < 11 && [1, 2, 3, 15, 17, 21].includes(this.user_type))
                    this.editPI(order.invoice_id);
                else if (order.stage === 11 && [1, 2, 3, 15, 21].includes(this.user_type))
                    this.finalisePI(order.invoice_id);
                else if (order.stage <= 5 && [6].includes(this.user_type))
                    this.edit_factory_pi(order.invoice_id);
                else if (order.stage <= 4 && [4, 7, 9, 11].includes(this.user_type))
                    this.edit_sales_pi(order.invoice_id);

                if (order.stage > 4 && [4, 7, 9, 11].includes(this.user_type))
                    this.$notify({
                        title: 'Error:',
                        text: 'You can not edit orders above stage 4, please contact admin',
                        group: 'notifications',
                        position: 'top right'
                    });
            },

            build_stage_permissions(user) {
                this.user_groups = user.groups;
                let load_totals = false;
                for (let key in user.groups) {
                    let value = user.groups[key].id;

                    if (!this.stages.length) {
                        this.user_type = value;
                        if ([1, 2, 12].includes(value)) {
                            load_totals = true;
                            this.stages = this.default_stages;
                        }

                        if ([13].includes(value)) {
                            //partner
                            load_totals = true;
                            this.stages = [
                                {id: 8, name: 'Partner Confirmed Received'},
                                {id: 9, name: 'Partner Confirmed Production Placement'},
                                {id: 10, name: 'Order Placed'},
                                {id: 11, name: 'Shipped'},
                            ];
                        }

                        if ([15].includes(value)) {
                            //logistis
                            load_totals = true;
                            this.stages = [
                                {id: 1, name: 'Sourcer To Check'},
                                {id: 2, name: 'Sales To Check'},
                                {id: 4, name: 'Quotation Canceled'},
                                {id: 6, name: 'Logistics Checked (Needs sending to partner)'},
                                {id: 7, name: 'Send To Partner'},
                                {id: 11, name: 'Shipped'},
                            ];
                        }

                        if ([11].includes(value)) {
                            //sales Managers
                            this.stages = [
                                {id: 1, name: 'Sourcer To Check'},
                                {id: 2, name: 'Sales To Check'},
                                {id: 4, name: 'Quotation Canceled'},
                                {id: 5, name: 'Send To Logistics'},
                            ];
                        }

                        if ([4, 7, 8, 9].includes(value)) {
                            //sales
                            this.stages = [
                                {id: 3, name: 'Sales Manager To Check'},
                                {id: 5, name: 'Send to logistics (Confirmed Order)'},
                            ]
                        }

                        if ([3, 17].includes(value)) {
                            //sourcer
                            this.stages = [
                                {id: 1, name: 'Sourcer To Check'},
                                {id: 2, name: 'Sales To Check'},
                                {
                                    id: 3,
                                    name: 'Sales Manager To Check'
                                },
                                {
                                    id: 6,
                                    name: 'Logistics To Check'
                                },
                            ]
                        }
                    }
                }
                if (load_totals === true) this.load_totals();
            },

            get_edit_access() {
                return [1, 2, 3, 9, 12, 15, 17].includes(this.user_type);
            },
            async load_totals() {
                let groups = this.$store.getters.user_groups;
                if (groups.includes(1) || groups.includes(2)) {
                    let date = new Date();
                    let res_filter = '';
                    console.log(this.load_url);
                    if (typeof this.load_url !== undefined && typeof this.load_url !== "undefined") {
                        if (this.load_url)
                            console.log('ld inc');
                        console.log(this.load_url);
                        if (this.load_url !== false && this.load_url.includes('?'))
                            res_filter += this.load_url.split('?', 2)[1] + "&";
                        console.log(res_filter);
                    }

                    res_filter += 'year=' + date.getFullYear();
                    this.totals = await this.$hget('/order/totals/?' + res_filter).then(response => response.data);
                    this.totals_show = true;
                }
            },

            init_filter_fields() {
                let filter_fields = [
                    {
                        table: 'pi_number',
                        label: 'PI Number',
                        type: 'text',
                        value: '',
                        required: true,
                        rules: []
                    },
                    {
                        table: 'stage',
                        label: 'Stage',
                        type: 'select',
                        value: 0,
                        multiple: true,
                        required: true,
                        rules: [],

                        options: [
                            {id: 0, name: 'All'},
                            {id: 1, name: '1 - Sourcer To Check',},
                            {id: 2, name: '2 - Sales To Check',},
                            {id: 3, name: '3 - Sales Manager To Check',},
                            {id: 4, name: '4 - Canceled Quote',},
                            {id: 5, name: '5 - Logistics To Check',},
                            {id: 6, name: '6 - Logistics Confirmed',},
                            {id: 7, name: '7 - Sent To Partner',},
                            {id: 8, name: '8 - Partner To Confirm Received',},
                            {id: 9, name: '9 - Partner Needs To Place Order To Factory',},
                            {id: 10, name: '10 - Waiting To Be Shipped',},
                            {id: 11, name: '11 - Shipped',},
                        ],
                        track_by: 'id',
                        show: 'name',
                    },
                    {
                        table: 'department',
                        label: 'Department',
                        type: 'select',
                        value: 0,
                        required: true,
                        rules: [],

                        options: [
                            {id: '', name: 'All'},
                            {id: 1, name: 'Electronics'},
                            {id: 2, name: 'Bed & Bath'},
                            {id: 3, name: 'Sports, Fitness & Outdoor'},
                            {id: 4, name: 'Furniture'},
                            {id: 5, name: 'Luggage & Travel'},
                            {id: 6, name: 'Toys'},
                            {id: 7, name: 'Decoration, Gifts & Seasonal Items'},
                            {id: 8, name: 'Stationery'},
                            {id: 9, name: 'Baby & Toddler'},
                            {id: 10, name: 'Car Accessories'},
                            {id: 11, name: 'Hardware'},
                            {id: 12, name: 'Home Appliance'},
                            {id: 13, name: 'Seasonal Items'},
                            {id: 14, name: 'Pets'},
                            {id: 15, name: 'Clip Strip'},
                            {id: 16, name: 'Personal Care'},
                            {id: 17, name: 'Women Accessories'},
                            {id: 18, name: 'Gardening'},
                            {id: 19, name: 'Housewares'},
                        ],
                        track_by: 'id',
                        show: 'name',
                    }
                ];

                let groups = this.$store.getters.user_groups;
                if (groups.includes(2) || groups.includes(15)) {
                    this.$hget('/admins/department-categories/').then(r => r.data)
                        .then(list => {
                            let sourcers = [];
                            //TODO: This must have a much better way to do it
                            for (let cs of list) {
                                for (let sourcer of cs.sourcers) {

                                    let existing_sourcer = sourcers.find(o => o.id === sourcer.id);

                                    if (typeof existing_sourcer !== "undefined")
                                        existing_sourcer.categories.push(cs.id);
                                    else
                                        sourcers.push({
                                            id: sourcer.id,
                                            username: sourcer.username,
                                            categories: [cs.id]
                                        })
                                }
                            }

                            this.filter_fields.push({
                                table: 'sourcers',
                                label: 'Sourcers',
                                type: 'select',
                                value: 0,
                                required: true,
                                rules: [],
                                options: sourcers,
                                track_by: 'id',
                                show: 'username',
                            });


                        });
                }

                filter_fields.push({
                    table: 'year',
                    label: 'Year',
                    type: 'select',
                    value: 0,
                    required: true,
                    rules: [],
                    options: [
                        {
                            id: '2017',
                            name: '2017'
                        },
                        {
                            id: '2018',
                            name: '2018'
                        },
                        {
                            id: '2019',
                            name: '2019'
                        },
                        {
                            id: '2020',
                            name: '2020'
                        },
                        {
                            id: '2021',
                            name: '2021'
                        },
                    ],
                    track_by: 'id',
                    show: 'name',
                });

                filter_fields.push({
                    table: 'month',
                    label: 'Month',
                    type: 'select',
                    value: 0,
                    required: true,
                    rules: [],
                    options: [
                        {
                            id: '01',
                            name: 'Jan'
                        },
                        {
                            id: '02',
                            name: 'Feb'
                        },
                        {
                            id: '03',
                            name: 'Mar'
                        },
                        {
                            id: '04',
                            name: 'Aprl'
                        },
                        {
                            id: '05',
                            name: 'May'
                        },
                        {
                            id: '05',
                            name: 'May'
                        },
                        {
                            id: '06',
                            name: 'Jun'
                        },
                        {
                            id: '07',
                            name: 'Jul'
                        },
                        {
                            id: '08',
                            name: 'Aug'
                        },
                        {
                            id: '09',
                            name: 'Sep'
                        },
                        {
                            id: '10',
                            name: 'Oct'
                        },
                        {
                            id: '11',
                            name: 'Nov'
                        },
                        {
                            id: '12',
                            name: 'Dec'
                        },
                    ],
                    track_by: 'id',
                    show: 'name',
                });
                console.log('filter');
                this.filter_fields = filter_fields;

            },

            triggerSearch(parsed_url) {
                console.log("PARSED URL", parsed_url);
                this.parsed_url = parsed_url;
                if (!!parsed_url) this.load_url = parsed_url;
                else this.load_url = this.init_url;

                this.invoices = [];
                this.loadOrders();
            },

            editPI(invoice_id) {
                window.open('/c-panel/logistics/edit-pi/' + invoice_id, '_blank');
                // this.$router.push({path: '/c-panel/logistics/edit-pi/' + invoice_id});
            },

            finalisePI(invoice_id) {
                window.open('/c-panel/logistics/finalise-pi/' + invoice_id, '_blank');

                // this.$router.push({path: '/c-panel/logistics/finalise-pi/' + invoice_id});
            },

            edit_sales_pi(invoice_id) {
                window.open('/c-panel/sales/edit-pi/' + invoice_id, '_blank');

                // this.$router.push({path: '/c-panel/sales/edit-pi/' + invoice_id});
            },

            edit_sales_manager_pi(invoice_id) {
                window.open('/c-panel/sales_manager/edit-pi/' + invoice_id, '_blank');

                // this.$router.push({path: '/c-panel/sales_manager/edit-pi/' + invoice_id});
            },

            edit_factory_pi(invoice_id) {
                window.open('/c-panel/factory/edit-pi/' + invoice_id, '_blank');

                // this.$router.push({path: '/c-panel/factory/edit-pi/' + invoice_id});
            },

            editOrderStage(to_stage, item) {
                this.$hpost('/admins/change-stage/', {order: item, stage: to_stage})
                    .then((response) => {
                        item.stage = to_stage;
                        item.signed_off = 0;
                        if (to_stage === 11)
                            item.signed_off = 1;
                        item.pi_number = response.data.pi_number;
                        this.$forceUpdate();
                    })
            },

            orderChatPopup(invoice) {
                this.selected_invoice = invoice;
                this.chat_opened = true;
            },

            close_chat() {
                this.chat_opened = false;
            },

            showInvoiceFolders(invoice) {
                this.$modal.show(InvoiceFilesManager,
                    {
                        selected_invoice: invoice
                    },
                    {
                        adaptive: true,
                        scrollable: true,
                        reset: true,
                        height: 'auto',
                        width: '80%',
                        clickToClose: true
                    }
                );
            },

            get_stage_change_text(order) {
                let parsed_date = new Date(Date.parse(order.last_stage_change_date));
                let now = new Date(Date.now());

                if ((now.getFullYear() - parsed_date.getFullYear()) > 1)
                    return "More than a year ago";
                else if ((now.getMonth() - parsed_date.getMonth()) > 1)
                    return now.getMonth() - parsed_date.getMonth() + " months ago";
                else if ((now.getDate() - parsed_date.getDate()) > 1)
                    return now.getDate() - parsed_date.getDate() + " days ago";
                else return "Less than a day ago";
            },

            preview(item) {
                console.log('preview', item);
                window.open('/c-panel/logistics/preview-pi/' + item.invoice_id, '_blank');
            },
        },
    }
</script>

<style scoped lang="scss">
    $stages-base_color: #b2cee8 #E27E4B #e25662 #4c89be #f08686 #fcdf88 #18e2c4 #bdd6a5 #1dbf77 #e1e1e1 #888888 #5d5d5d #ffffff;

    $stages-highlight_color: #b2cee8 #E27E4B #e25662 #4c89be #f08686 #fcdf88 #18e2c4 #bdd6a5 #1dbf77 #e1e1e1 #888888 #5d5d5d #ffffff;

    .totals-table {
        padding:0;
        margin:0;
        tr {
            td, th {
                border:none!important;
                color:white;
            }
        }
    }
    .order-totals {
        width: 550px;
        height: 75px;
        margin: auto;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        position: fixed;

        table {
            width: 100%;

            tr > td, tr > th {
                border-spacing: 0;
                border-collapse: collapse;
                padding: 3px;
            }

            tr > td {
                border: 1px solid #cccccc;
            }
        }

    }

    .order-chat-wrapper {
        width: 400px;
        height: 500px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 900;
        background: white;

        .close {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 901;
        }
    }

    @mixin text_btn_mixin {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        cursor: pointer;

        &:hover {
            background-color: #00000011;
        }

        & i {

            font-size: 22px;
            color: #000000BB;
        }

    }


    @for $i from 1 through 13 {
        .stage_btn-#{$i} {
            border: 2px solid nth($stages-base_color, $i);
        }
    }

    .st-block {
        padding: 0px 15px;

    }

    @for $i from 1 through 13 {
        .stage-block-#{$i} {
            line-height: 45px;
            background: nth($stages-base_color, $i);
        }
    }
</style>
