import '@mdi/font/css/materialdesignicons.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css' // Ensure you are using css-loader

import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import 'vuetify/dist/vuetify.min.css'

import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    theme: {
       themes: {
           light: {
               primary:            '#204037',
               secondary:          '#203468',

               wst_green_lighter : '#91D2C9',
               wst_green_light :   '#6BA69D',
               wst_green :         '#30725f',
               wst_green_dark :    '#234c42',
               wst_green_darker :  '#204037',
               wst_green_darkest : '#17342b',
               wst_accent :        '#60b6aa',

               wst_green_grey:     '#446655',

               error:              '#bf5741',
               info:               '#76b9de',
               success:            '#48c658',
               warning:            '#bf9243',

               wst_white:          '#F6F6F6',
               wst_white_dim:      '#F0F0F0',
               wst_grey_lighter:   '#BCBCBC',
               wst_grey_light:     '#999999',
               wst_grey:           '#656565',
               wst_grey_dark:      '#353535',
               wst_grey_darker:    '#202020',
               wst_grey_darkest:   '#151515',
               wst_black:          '#111111',

               wst_text_color:     '#202020'
           },
       }
    },
})
