<template>

    <div id="shop_search_container">
        <div class="categories_button uk-inline" v-ripple>
            <button @click="openCategories(true)" class="uk-button uk-button-default uk-text-truncate"
                    v-bind:class="{ component_selected_category: is_category_selected }" type="button">
                {{ selected_category_name }}
            </button>
            <div class="shop_category_holder" v-show="show_cats" v-on:mouseleave="openCategories(false)">
                <v-container grid-list-md text-xs-center>
                    <v-layout row wrap>
                        <v-flex xs5 class="main_categories">
                            <div v-for="category of categories" class="">
                                <div class="uk-inline text-xs-left" v-ripple>
                                    <button class="category_button parent_category_wrapper"
                                            type="button" @click="selectCategory(category)"
                                            @hover="setHover(category)"
                                            v-on:mouseenter="setHover(category)"
                                    >
                                        {{category.name}}
                                    </button>
                                </div>
                            </div>
                        </v-flex>
                        <v-flex xs7 class="sub_categories">
                            <div v-for="category in categories">
                                <div v-if="category.sub_categories && category.sub_categories.length > 0 && hover_category === category.id">
                                    <div class="main_child_inner top-parent">
                                        <v-container grid-list-md text-xs-left>
                                            <v-layout row wrap>
                                                <v-flex xs6 v-for="sub_category in category.sub_categories"
                                                        :key="sub_category.id" class="text-xs-left">
                                                    <span><a
                                                            @click="selectCategory(sub_category)">{{sub_category.name}}</a></span>
                                                    <!-- TODO: Add another layer ob sub categories-->
                                                    <span v-for="third_child in sub_category.sub_categories"
                                                          :key="third_child.id">
                                                            <a style="padding-left:5px;"
                                                               @click="selectCategory(third_child)">{{third_child.name}}</a>
                                                        </span>
                                                </v-flex>
                                            </v-layout>
                                        </v-container>

                                    </div>
                                </div>
                            </div>
                        </v-flex>
                    </v-layout>
                </v-container>
            </div>
        </div>
        <v-text-field hide-details single-line class="main-search"
                      label="Search" height="50"
                      v-model="search_parameters.search_text"
                      v-on:keyup.enter="initSearch()"
        ></v-text-field>
        <div class="main-search-button">
            <v-btn text icon rounded @click="initSearch()">
                <v-icon>mdi-magnify</v-icon>
            </v-btn>
        </div>
        <div class="main-search-button">
            <v-btn text icon rounded @click="clearSearch()"
                   v-if="search_parameters.search_text !== '' || search_parameters.search_category">
                <v-icon>mdi-close-circle</v-icon>
            </v-btn>
        </div>
    </div>
</template>

<script>

    export default {
        name: "store-search",
        props: {
            categories: Array
        },
        data() {
            return {
                search_parameters: {
                    search_text: '',
                    search_category: undefined,
                    search_filters: [],
                    hover_category: '',
                    show_cats: false,
                }
            };
        },
        created() {
            this.show_cats = false;
            this.hover_category = '';
        },
        mounted() {

        },
        methods: {
            openCategories(bool) {
                this.show_cats = bool;
                this.$forceUpdate();
            },
            setHover(hover_category) {
                this.hover_category = hover_category.id;
                this.$forceUpdate();
            },
            selectCategory(selection) {

                this.show_cats = false;
                this.search_parameters.search_category = selection;
                this.initSearch();
            },
            clearSearch() {
                this.search_parameters.search_text = '';
                this.search_parameters.selected_category = undefined;
                this.search_parameters.search_category = {
                    name: 'Categories',
                    id:0,
                };
                this.initSearch();
                this.$router.push({path: '/search/0/undefined'});
                // this.$forceUpdate();
            },
            initSearch() {
                let category = this.search_parameters.search_category;
                let search_text = this.search_parameters.search_text;

                if (!search_text)
                    search_text = 'undefined';


                if (!!category) {
                    category = category.id;
                } else
                    category = '0';

                this.$router.push({path: '/search/' + category + '/' + search_text});
            }
        },
        computed: {
            selected_category_name: function () {
                if (this.search_parameters.search_category)
                    return this.search_parameters.search_category.name;
                else return "Categories";
            },
            is_category_selected: function () {
                return !!this.search_parameters.search_category;
            }
        }
    }
</script>

<style lang="scss" scoped>
    .shop_category_holder {
        width: 800px;
        position: absolute;
        z-index: 200;
        background: white;

        .no_parent {
            text-align: left !important;
        }

        .main_categories {
            text-align: left !important;
        }

        .sub_categories {
            text-align: left;

            .top-parent {
                a {
                    font-weight: bold;
                }
            }
        }
    }
</style>
