<template>
    <v-menu :close-on-click="true"
            :close-on-content-click="true"
            :offset-y="true"
            :open-on-hover="true"
            :max-width="400">
        <template v-slot:activator="{ on }">
            <v-btn depressed text
                   v-on="on"
                   small
                   @click="!!Object.keys(user).length? ()=>{}:$router.push('/auth/login')"
                   :color="color"
                   :class="class_string.split(' ')">
                <v-icon>mdi-account</v-icon>
                <span>
                    {{!!Object.keys(user).length? user.username:'Login'}}
                </span>
            </v-btn>
        </template>
        <v-list v-if="!!Object.keys(user).length">
            <v-list-item v-if="!!Object.keys(buffer_user).length" @click="cancel_buffer_user()">
                <v-list-item-title>Cancel Use As User</v-list-item-title>
            </v-list-item>
            <v-divider v-if="!!Object.keys(buffer_user).length" class="ma-1"></v-divider>
            <v-list-item>
                <v-list-item-title><span class="font-weight-bold">Email: </span>{{user.email}}</v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title><span class="font-weight-bold">Name: </span>{{user.last_name}}, {{user.first_name}}</v-list-item-title>
            </v-list-item>
            <v-list-item v-tooltip="user_groups_names.join(', ')">
                <v-list-item-title><span class="font-weight-bold">Type: </span>{{user_groups_names.join(', ')}}</v-list-item-title>
            </v-list-item>
            <v-divider class="ma-1"></v-divider>
            <v-list-item>
                <v-list-item-title>
                    <v-btn to="/c-panel/profile" block depressed text >
                        Profile
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn v-if="!!Object.keys(user).length && !$store.state.responsive"
                     to="/c-panel"
                    small
                           block depressed text class="my-1">
                    <span>Dashboard</span>
                    </v-btn>


                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn v-if="!!Object.keys(user).length && !$store.state.responsive"
                           to="/wishlist"
                           block depressed text
                           class="my-1 ">
                        <span>WishList</span>
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
            <v-list-item>
                <v-list-item-title>
                    <v-btn to="/auth/logout" block depressed text>
                        Logout
                    </v-btn>
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
    export default {
        name: "LoginWidget",
        props: {
            color: {
                type: String,
                default: 'white'
            },
            class_string: {
                type: String,
                default: ''
            },
        },
        data: () => ({
            title: "Login Widget"
        }),
        computed: {
            user: function (){
                return this.$store.getters.user;
            },
            buffer_user: function(){
                return this.$store.getters.buffer_user;
            },
            user_groups_names: function(){
                return this.$store.getters.user_groups_names;
            }
        },
        created() {
            // console.log('testing user log');
            // console.log(this.$store.getters.user);
        },
        methods: {
            cancel_buffer_user() {
                this.$store.dispatch('cancelUseAsUser', {context: this});
                window.location = '/c-panel/users'
            },
        }
    }
</script>

<style scoped>

    .login-widget .v-btn{
        margin-left:6px;
        padding-left: 6px;
    }
</style>
