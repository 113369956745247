const state = {
    test: "",
    products: [],
}

const actions = {
    test: (ctx, payload) => {
        ctx.commit('mutateState', payload)
    }
}

const getters = {
    test: state => {
        return state.test;
    },
}

const mutations = {
    mutateState: (state, payload) => {
        state.test = "test2"
    },
}

export default {
    state,
    actions,
    getters,
    mutations
}