<template>
    <v-container class="pa-3">
        <v-row class="ma-2">
            <div class="display-1">Select a user</div>
        </v-row>
        <v-row class="ma-0">
            <multi_select v-model="user_model"
                          class="my-2"
                          :options="user_query_results"
                          open-direction="bottom"
                          @search-change="user_find_async"
                          label="username"
                          track-by="email"
                          placeholder="Type to search a User"
                          :multiple="false"
                          :searchable="true"
                          :loading="user_is_loading"
                          :internal-search="true"
                          :clear-on-select="false"
                          :close-on-select="true"
                          :options-limit="20"
                          :max-height="600"
                          :show-no-results="false"
                          :hide-selected="false"
                          :preselectFirst="false"
                          :width="'300px'">
            </multi_select>
            <multi_select v-model="member_type"
                          class="my-2"
                          :options="member_type_options"
                          open-direction="bottom"
                          label="name"
                          :multiple="false"
                          :searchable="false"
                          :preselectFirst="true"
                          :width="'60px'"
            ></multi_select>


        </v-row>
        <v-divider class="mb-1"></v-divider>
        <v-row>
            <v-col>
                <v-btn text block @click="$emit('close')" >Cancel</v-btn>
            </v-col>
            <v-divider :vertical="true"></v-divider>
            <v-col>
                <v-btn text block @click="returnUser">Select</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import multi_select from 'vue-multiselect'

    export default {
        name: "UserSelect",
        components:{ multi_select },
        props:{
            callback_function: Function
        },
        data: () => ({
            user_model: null,
            user_query_results: [],
            user_is_loading: false,

            member_type: undefined,
            member_type_options: [
                { name: 'Natural', value: 'N' },
                { name: 'Admin', value: 'A'},
            ]
        }),
        methods: {
            user_find_async(query){
                this.user_is_loading = true;
                this.$http.get('/admins/users/',  { params: { email: query }})
                    .then(response => {
                        this.user_query_results = response.data.results;
                        this.user_is_loading = false;
                    })
            },
            returnUser(){
                this.callback_function(this.user_model, this.member_type);
                this.$emit('close');
            },



        }
    }
</script>

<style scoped>

</style>