// https://vuex.vuejs.org/en/actions.html

import jwt_decode from "jwt-decode/lib"

let actions = {
    login: (context, payload) => {
        return new Promise((resolve, reject) => {
            // Do something here... lets say, a http call using vue-resource
            context.state.$http.post(context.state.endpoints.obtainJWT, payload)
                .then((response)=>{
                    if(response.data.token !== '')
                    {
                        // Error user has been disabled, profile state = 2
                        context.commit('updateToken', response.data);
                    }
                    resolve(response);
                })
                .catch((error)=>{
                    reject(error);
                });
        });
    },
    loginAsUser: (e, payload) =>{
        e.commit('updateUseAsUser', payload);
    },
    loginFromLink: (e, payload) =>{
        e.commit('updateLinkLogin', payload);
    },
    cancelUseAsUser: (e) =>{
        e.commit('cancelUseAsUser');
    },
    logout: (e) =>{
        return Promise.all([
            e.commit('removeToken'),
            e.commit('removeUser')
        ])
    },
    refreshToken: (context) => {
        const payload = { token: this.state.accessToken };
        context.state.$http.post(this.state.endpoints.refreshJWT, payload)
            .then((response)=>{
                context.commit('updateUseAsUser', response.data);
                return response;
            })
            .catch((error)=>{
                return error;
                // console.log(error)
            })
    },
    inspectToken: () => {
        const token = this.state.accessToken;
        if(token){
            const decoded = jwt_decode(token);
            const exp = decoded.exp;
            const orig_iat = decode.orig_iat;
            if(exp - (Date.now()/1000) < 1800 && (Date.now()/1000) - orig_iat < 628200){
                this.dispatch('refreshToken')
            } else if (exp -(Date.now()/1000) < 1800){
                // DO NOTHING, DO NOT REFRESH
            } else {
                console.log("Login again!");
                //TODO: POPUP LOGIN FORM
                // PROMPT USER TO RE-LOGIN, THIS ELSE CLAUSE COVERS THE CONDITION WHERE A TOKEN IS EXPIRED AS WELL
            }
        }
    },
    setResponsive:(context, width) =>{
        context.commit('setResponsive', width);
    }
};

export default actions;
