<template>
  <div>
    <v-layout align-center justify-space-between row fill-height px-8>
      <v-flex grow><H3>{{ title }}</H3></v-flex>

      <v-flex shrink>
        <v-btn text fab rounded small @click="cancelProcess()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-flex>

    </v-layout>

    <v-divider class="ma-0"></v-divider>

    <div class="content pa-2" v-if="(!!valid_form.data_set && !valid_form.valid) || !valid_form.data_set">
      <v-container>
        <v-row class="mx-2 my-12">
          <v-spacer></v-spacer>

          <v-btn text height="200px" width="200px" @click="downloadTemplateExcelSheet()">
            <v-container>
              <v-row class="mx-0 my-2" :justify="'center'">
                <v-icon size="80">mdi-file-download</v-icon>
              </v-row>
              <v-row class="mx-0" :justify="'center'">
                Download
              </v-row>
              <v-row class="mx-0" :justify="'center'">
                template
              </v-row>
            </v-container>
          </v-btn>

          <v-divider :vertical="true" class="mx-4"></v-divider>

          <input type="file" style="display:none" @change="handleFile" ref="excel_file_upload_input">

          <v-btn text height="200px" width="200px" @click="$refs.excel_file_upload_input.click()">
            <v-container>
              <v-row class="mx-0 my-2" :justify="'center'">
                <v-icon size="80">mdi-file-upload</v-icon>
              </v-row>
              <v-row class="mx-0" :justify="'center'">
                Upload File
              </v-row>
            </v-container>
          </v-btn>

          <v-spacer></v-spacer>
        </v-row>
      </v-container>
    </div>

    <div class="content" ref="spreadsheet_content"
         v-if="!!valid_form.data_set && !!valid_form.valid && !!Object.keys(table_values).length && !!Object.keys(values).length">
      <v-stepper v-model="e1">
        <v-stepper-header style="box-shadow: none">
          <v-stepper-step :complete="e1 > 1" step="1">Factory</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 2" step="2">Products</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 > 3" step="3">Upload</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step :complete="e1 >= 4" step="4">Complete</v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-divider class="my-1"></v-divider>

          <v-stepper-content step="1" class="pt-1">

            <v-container>

              <v-row class="px-6 mb-6">
                <v-text-field clearable solo hide-details
                              v-model="department_search_input"
                              :append-icon="'mdi-magnify'"
                              @click:append="search_factory(department_search_input)">
                </v-text-field>
              </v-row>


              <template v-if="!(!department_model || typeof department_model.organisation === 'undefined')">

                <v-row class="mb-2 mx-2" :align="'center'">
                  <h3 class="my-0">Organisation</h3>

                  <v-spacer></v-spacer>

                  <v-radio-group v-model="organisation_process"
                                 hide-details
                                 :row="true"
                                 :mandatory="true">
                    <v-radio v-for="(option_text, key) of process_types"
                             :key="key"
                             :label="option_text"
                             :value="key"
                    ></v-radio>
                  </v-radio-group>
                </v-row>

                <v-row class="mx-0" v-if="organisation_process !== 'create' && !!department_model.organisation">
                  <v-text-field label="Name" class="mr-4"
                                :disabled="organisation_process === 'find'"
                                v-model="department_model.organisation.name">

                  </v-text-field>
                  <v-text-field label="Website"
                                :disabled="organisation_process === 'find'"
                                v-model="department_model.organisation.website">

                  </v-text-field>
                </v-row>

                <v-row class="mx-0" v-if="organisation_process === 'create'">
                  <v-text-field label="Name" class="mr-4"
                                v-model="values.organisation_name">
                  </v-text-field>
                  <v-text-field label="Website"
                                v-model="values.factory_website">

                  </v-text-field>
                </v-row>

                <v-divider></v-divider>

                <v-row class="mb-2 mx-2" :align="'center'">
                  <h3 class="my-0">Department</h3>

                  <v-spacer></v-spacer>

                  <v-radio-group v-model="department_process"
                                 hide-details
                                 :row="true"
                                 :mandatory="true">
                    <v-radio v-for="(option_text, key) of process_types"
                             :key="key"
                             :label="option_text"
                             :value="key"
                    ></v-radio>
                  </v-radio-group>
                </v-row>

                <v-row class="px-4" v-if="department_process !== 'create'">
                  <v-text-field label="Name"
                                :disabled="department_process === 'find'"
                                v-model="department_model.name">

                  </v-text-field>
                </v-row>

                <v-row class="px-4" v-if="department_process === 'create'">
                  <v-text-field label="Name"
                                v-model="values.factory_name">
                  </v-text-field>
                </v-row>

                <v-row class="px-4" v-if="department_process !== 'create'">
                  <v-text-field label="Shipping port" class="mr-4"
                                :disabled="department_process === 'find'"
                                v-model="department_model.shipping_port">

                  </v-text-field>
                  <v-text-field label="Zip code"
                                :disabled="department_process === 'find'"
                                v-model="department_model.zip_code">

                  </v-text-field>
                </v-row>

                <v-row class="px-4" v-if="department_process === 'create'">
                  <v-text-field label="Shipping port" class="mr-4"
                                v-model="values.shipping_port">

                  </v-text-field>
                  <v-text-field label="Zip code"
                                v-model="values.zip_code">
                  </v-text-field>
                </v-row>

                <v-row class="px-4" v-if="department_process !== 'create'">
                  <v-text-field label="Address"
                                :disabled="department_process === 'find'"
                                v-model="department_model.address">

                  </v-text-field>
                </v-row>

                <v-row class="px-4" v-if="department_process === 'create'">
                  <v-text-field label="Address"
                                v-model="values.factory_address">

                  </v-text-field>
                </v-row>

                <v-row class="px-4" v-if="department_process !== 'create'">
                  <v-select label="Country" class="mr-4"
                            :items="countries"
                            :disabled="department_process === 'find'"
                            :item-text="'name'"
                            :item-value="'id'"
                            v-model="department_model.country"
                            :no-data-text="'Select one'"
                  ></v-select>

                  <v-select label="Category"
                            :items="department_categories"
                            :disabled="department_process === 'find'"
                            :item-text="'value'"
                            :item-value="'id'"
                            v-model="department_model.category"
                            :no-data-text="'Select one'"
                  ></v-select>
                </v-row>

                <v-row class="px-4" v-if="department_process === 'create'">
                  <v-select label="Country" class="mr-4"
                            :items="countries"
                            :item-text="'name'"
                            :item-value="'id'"
                            v-model="department_create_country"
                            :no-data-text="'Select one'"
                  ></v-select>

                  <v-select label="Category"
                            :items="department_categories"
                            :item-text="'value'"
                            :item-value="'id'"
                            v-model="department_create_category"
                            :no-data-text="'Select one'"
                  ></v-select>
                </v-row>

                <v-divider></v-divider>

                <v-row class="mb-2 mx-2" :align="'center'">
                  <h4 class="my-0">Contact user</h4>
                  <v-spacer></v-spacer>
                  <v-checkbox v-model="user_add_if_not_exists"
                              label="Create new contact user"
                              :disabled="!department_model || !department_model.users.concat(department_model.organisation.users) || department_process === 'create'"
                              hide-details
                  >
                  </v-checkbox>
                </v-row>

                <v-row class="px-4" justify="center">

                  <v-col v-if="department_process !== 'create'">
                    <v-select
                        v-model="selected_contact"
                        :items="organisation_users"
                        :item-text="'username'"
                        :disabled="organisation_process === 'create'"
                        label="Available org users"
                        attach
                        chips
                        deletable-chips
                        dense
                        hide-selected
                        return-object
                        multiple

                    ></v-select>
                  </v-col>

                  <v-col>
                    <v-text-field v-model="values.factory_email"
                                  label="Email"
                                  :disabled="!user_add_if_not_exists"
                    ></v-text-field>

                    <v-text-field v-model="values.factory_contact_name"
                                  label="Name"
                                  :disabled="!user_add_if_not_exists"
                    ></v-text-field>

                    <v-text-field v-model="values.factory_contact_last_name"
                                  label="Last name"
                                  :disabled="!user_add_if_not_exists"
                    ></v-text-field>

                    <v-text-field v-model="values.factory_contact_number"
                                  label="Phone"
                                  :disabled="!user_add_if_not_exists"
                    ></v-text-field>
                  </v-col>

                </v-row>

              </template>

              <template v-else>
                <v-progress-circular indeterminate size="400"></v-progress-circular>
              </template>

              <v-row>
                <v-col>
                  <v-btn block text @click="cancelProcess()">
                    Cancel
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn block color="primary"
                         @click="processOrgAndDepartment()"
                         :disabled="!continue_from_department">
                    Accept
                  </v-btn>

                </v-col>

              </v-row>

            </v-container>

          </v-stepper-content>

          <v-stepper-content step="2">
            <v-alert type="error" v-if="has_errors">
              {{ error_message }}
            </v-alert>
            <multi_select v-model="sourcer_model"
                          :options="sourcer_query_results"
                          open-direction="bottom"
                          @search-change="sourcer_find_async"
                          label="username"
                          track-by="email"
                          placeholder="Type to search a Sourcer"
                          :multiple="false"
                          :searchable="true"
                          :loading="sourcer_is_loading"
                          :internal-search="true"
                          :clear-on-select="true"
                          :close-on-select="true"
                          :options-limit="20"
                          :max-height="600"
                          :preselectFirst="true">
            </multi_select>

            {{ /* Products to upload */ }}

            <template
                v-if="!!Object.keys(table_values).length && (Object.keys(existing_products).length < Object.keys(table_values).length)">

              <h3>Upload:</h3>

              <template v-for="(product, key) in table_values">
                <v-card v-if="!Object.keys(existing_products).includes(key)" elevation="3" class="mx-1 my-2">
                  <v-container align-start justify-start class="ma-0">

                    <v-row class="ma-2">
                      <v-text-field class="mx-2"
                                    v-model="product['item_number']"
                                    label="Item no. 1"
                                    :hide-details="true"

                      >
                      </v-text-field>

                      <v-text-field class="mx-2"
                                    v-model="product['category_id']"
                                    label="Category"
                                    :hide-details="true"
                      >
                      </v-text-field>

                    </v-row>

                    <v-row>
                      <v-col :cols="12" :sm="6">
                        <v-select return-object item-text="name"
                                  v-model="product.selected_parent_category"
                                  :items="categories_parents"
                                  @change="product.selected_child_category = undefined"
                                  label="Category"
                                  required>
                        </v-select>
                      </v-col>
                      <v-col :cols="12" :sm="6">
                        <v-select item-text="name" :item-value="'id'"
                                  v-model="product['category_id']"
                                  :items="get_possible_select_child_categories(product)"
                                  label="Sub Category"
                                  required>
                        </v-select>
                      </v-col>

                      <!--                                            <v-col :cols="12" class="py-1">-->
                      <!--                                                <v-row class="mx-0" :align="'center'">-->
                      <!--                                                    <v-autocomplete chips multiple-->
                      <!--                                                                    class="mx-1"-->
                      <!--                                                                    color="primary"-->
                      <!--                                                                    v-model="product.product_tags"-->
                      <!--                                                                    :items="default_tags"-->
                      <!--                                                                    :disabled="isUpdating"-->
                      <!--                                                                    :menu-props='{"closeOnClick":false, "closeOnContentClick":true}'-->
                      <!--                                                                    label="Tags"-->
                      <!--                                                                    item-text="name"-->
                      <!--                                                                    item-value="id">-->
                      <!--                                                        <template v-slot:selection="data">-->
                      <!--                                                            <v-chip-->
                      <!--                                                                    v-bind="data.attrs"-->
                      <!--                                                                    :input-value="data.selected"-->
                      <!--                                                                    close-->
                      <!--                                                                    @click="data.select"-->
                      <!--                                                                    @click:close="remove_tag(data.item)"-->
                      <!--                                                            >-->
                      <!--                                                                {{ data.item.name }}-->
                      <!--                                                            </v-chip>-->
                      <!--                                                        </template>-->
                      <!--                                                        <template v-slot:item="data">-->
                      <!--                                                            <template v-if="typeof data.item !== 'object'">-->
                      <!--                                                                <v-list-item-content v-text="data.item"></v-list-item-content>-->
                      <!--                                                            </template>-->
                      <!--                                                            <template v-else>-->
                      <!--                                                                <v-list-item-content>-->
                      <!--                                                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>-->
                      <!--                                                                </v-list-item-content>-->
                      <!--                                                            </template>-->
                      <!--                                                        </template>-->
                      <!--                                                    </v-autocomplete>-->

                      <!--                                                    <v-btn fab small outlined-->
                      <!--                                                           color="primary"-->
                      <!--                                                           v-tooltip="'Add Tag'"-->
                      <!--                                                           class="mx-1"-->
                      <!--                                                           @click="new_tag()"-->
                      <!--                                                    >-->
                      <!--                                                        <v-icon>mdi-plus</v-icon>-->
                      <!--                                                    </v-btn>-->
                      <!--                                                </v-row>-->
                      <!--                                            </v-col>-->
                    </v-row>

                    <v-row class="ma-2">

                      <v-text-field class="mx-2"
                                    v-model="product['name']"
                                    :label="'Name'"
                                    :hide-details="true"
                      >
                      </v-text-field>
                    </v-row>

                    <v-row class="ma-2">
                      <v-textarea class="mx-2" auto-grow rows="1"
                                  v-model="product['description']"
                                  :label="'Description'"
                                  :hide-details="true"
                      ></v-textarea>

                    </v-row>

                    <v-row class="mx-2 my-3">
                      <v-col v-for="photo of product.album.files"
                             :key="photo.id"
                             class="pa-0 my-2">
                        <v-img contain
                               class="mx-2"
                               style=" background-color: #333333"
                               height="100" width="100"
                               :src="'data:image/'+ photo.extension + ';base64,' + photo.base64"
                               :alt="product.description">
                        </v-img>
                      </v-col>

                    </v-row>

                    <v-row class="py-0 px-2 mx-0 my-2 justify-sm-space-between" cols="3">
                      <template
                          v-if="!(['name', 'item_number', 'photo', 'description', 'category_id', 'specification'].includes(product_field_key))"
                          v-for="(product_field_value, product_field_key) in product_template.table_fields">


                        <template v-if="product_field_value.method !== 'different'">
                          <v-text-field class="mx-2 flex-grow-0"
                                        style="min-width: 200px"
                                        :key="key + '_' + product_field_key"
                                        :label="product_field_value.label"
                                        v-model="product[product_field_key]"
                                        :hide-details="true"
                          ></v-text-field>
                        </template>


                        <template v-else v-for="ref in product_field_value.multiple_ref">
                          <v-text-field class="mx-2 flex-grow-0"
                                        style="min-width: 200px"
                                        :key="key + '_' + product_field_key + ref"
                                        :label="product_field_value.label +' ['+ref+']'"
                                        v-model="product[product_field_key + ref]"
                                        :hide-details="true"
                          ></v-text-field>
                        </template>


                      </template>

                    </v-row>

                    <v-row class="ma-2">
                      <v-textarea class="mx-2" auto-grow rows="1"
                                  v-model="product['specification']"
                                  :label="'Other remarks'"
                                  :hide-details="true"
                      ></v-textarea>

                    </v-row>
                  </v-container>
                </v-card>

              </template>

            </template>

            {{ /* Products to update */ }}
            <template v-if="!!Object.keys(existing_products).length">
              <h3>Update</h3>

              <v-alert type="warning">
                The following items were found in the system by the provided <span
                  class="font-weight-bold">Item No.</span> and will be updated if the field is not changed.
              </v-alert>
              <v-form ref="product_upload_form" lazy-validation v-model="products_valid">
                <template v-for="(product, key) of table_values">
                  <v-card v-if="!!Object.keys(existing_products).includes(key)" elevation="3" class="mx-1 my-2">
                    <v-container align-start justify-start class="ma-0">

                      <v-row class="ma-2">
                        <v-text-field class="mx-2"
                                      v-model="product['item_number']"
                                      :label="'Item no.'"
                                      required
                                      :rules="rules.product.item_number"
                                      :messages="existing_products[key]['item_number']"
                        >
                          <template v-slot:append>
                            <v-icon
                                :color="(product['item_number'] === existing_products[key]['item_number'])? 'error': 'warning'">
                              mdi-alert-circle-outline
                            </v-icon>
                          </template>
                        </v-text-field>

                        <!--<v-text-field class="mx-2"-->
                        <!--v-model="product['category_id']"-->
                        <!--label="Category"-->

                        <!--&gt;-->
                        <!--</v-text-field>-->

                      </v-row>
                      <v-row v-if="!product['category_id']">
                        <v-col :cols="12" :sm="6">
                          <v-select return-object item-text="name"
                                    v-model="product.selected_parent_category"
                                    :items="categories_parents"
                                    @change="product.selected_child_category = undefined"
                                    label="Category"
                                    >
                          </v-select>
                        </v-col>
                        <v-col :cols="12" :sm="6">
                          <v-select item-text="name" :item-value="'id'"
                                    v-model="product['category_id']"
                                    :items="get_possible_select_child_categories(product)"
                                    label="Sub Category"
                                    required
                                    :rules="rules.product.category"
                                    >
                          </v-select>
                        </v-col>
                      </v-row>

                      <v-row class="ma-2">

                        <v-text-field class="mx-2"
                                      v-model="product['name']"
                                      :label="'Name'"
                                      required
                                      :rules="rules.product.name"
                                      :messages="existing_products[key]['name']"
                        >
                        </v-text-field>
                      </v-row>


                      <v-row class="ma-2">
                        <v-text-field class="mx-2"
                                      v-model="product['slug']"
                                      :label="'Item URL in wellstar'"
                                      disabled
                                      required
                                      :rules="rules.product.slug"
                        >
                        </v-text-field>
                      </v-row>

                      <v-row class="ma-2">
                        <v-textarea class="mx-2" auto-grow rows="1"
                                    v-model="product['description']"
                                    :label="'Description'"
                                    required
                                    :rules="rules.product.description"
                                    :messages="existing_products[key]['description']"
                        ></v-textarea>

                      </v-row>

                      <v-row class="mx-2 my-3">
                        <v-col v-for="photo of product.album.files"
                               :key="photo.id"
                               class="pa-0 my-2">
                          <v-img contain
                                 class="mx-2"
                                 style=" background-color: #333333"
                                 height="100" width="100"
                                 :src="'data:image/'+ photo.extension + ';base64,' + photo.base64"
                                 :alt="product.description">
                          </v-img>
                        </v-col>

                      </v-row>

                      <v-row class="py-0 px-2 mx-0 my-2 justify-sm-space-between" cols="3">
                        <template
                            v-if="!(['name', 'item_number', 'photo', 'description', 'category_id', 'specification'].includes(product_field_key))"
                            v-for="(product_field_value, product_field_key) in product_template.table_fields">


                          <template v-if="product_field_value.method !== 'different'">
                            <v-text-field class="mx-2 flex-grow-0"
                                          style="min-width: 200px"
                                          :key="key + '_' + product_field_key"
                                          :label="product_field_value.label"
                                          v-model="product[product_field_key]"
                                          :messages="!!existing_products[key][product_field_key]? existing_products[key][product_field_key].toString():''"
                            ></v-text-field>
                          </template>


                          <template v-else v-for="ref in product_field_value.multiple_ref">
                            <v-text-field class="mx-2 flex-grow-0"
                                          style="min-width: 200px"
                                          :key="key + '_' + product_field_key + ref"
                                          :label="product_field_value.label +' ['+ref+']'"
                                          v-model="product[product_field_key + ref]"
                                          required
                                          :rules="rules.product[product_field_key + ref]"
                                          :messages="!!existing_products[key][product_field_key + ref]? existing_products[key][product_field_key + ref].toString():''"
                            ></v-text-field>
                          </template>


                        </template>

                      </v-row>

                      <v-row class="ma-2">
                        <v-textarea class="mx-2" auto-grow rows="1"
                                    v-model="product['specification']"
                                    :label="'Other remarks'"
                                    :messages="existing_products[key]['specification']"
                        ></v-textarea>

                      </v-row>
                    </v-container>
                  </v-card>
                </template>
              </v-form>
            </template>

            <v-btn color="primary" @click="uploadProducts()">Save Products</v-btn>

            <v-btn text @click="cancelProcess()">Cancel</v-btn>

          </v-stepper-content>

          <v-stepper-content step="3">
            <v-layout align-center justify-center row fill-height>
              <v-progress-circular
                  :width="3"
                  color="primary"
                  indeterminate
              ></v-progress-circular>

              <h3>{{ process_text }}</h3>
            </v-layout>
          </v-stepper-content>

          <v-stepper-content step="4">

            <w-whiteboard
                :icon="'mdi-check'"
                :title="'Products uploaded successfully'"
                :text="''"
                :go_back_button="false"></w-whiteboard>

          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </div>


  </div>
</template>

<script>
import multi_select from 'vue-multiselect'
import Whiteboard from "@/components/w/Whiteboard";
import Parser from '@/plugins/excel_parser'
import parser_template from '@/assets/forms/product_upload'

export default {
  name: "ProductUpload",
  components: {Whiteboard, multi_select},
  data: () => ({
    products_valid: true,
    countries: [],
    department_categories: [],

    title: "Product upload (Xlsx):",
    error_messages: [],

    /** Variables to read the Excel file**/
    product_template: undefined,

    valid_form: {data_set: false, valid: false, msg: ""},
    values: {},
    table_values: {
      name: ''
    },

    /** Stepper to upload the products **/
    e1: 0,

    categories_parents: [],
    categories_children: [],
    categories_options_children: [],
    selected_category: undefined,
    selected_parent_category: undefined,
    selected_child_category: undefined,

    default_tags: [],
    default_tag: {
      name: ''
    },
    tag: {
      name: ''
    },
    tag_dialog: false,

    //Department(Factory related variables)

    process_types: {find: "Find", edit: "Edit", create: "Create"},

    department_model: {},
    department_search_input: {},
    department_query_results: [],
    department_process: 'create',

    department_create_category: undefined,
    department_create_country: undefined,

    organisation_process: 'create',
    selected_contact: undefined,
    user_add_if_not_exists: true,


    //Sourcer search related variables

    sourcer_model: {},
    sourcer_query_results: [],
    sourcer_is_loading: false,

    existing_products: {},

    process_text: '',
    has_errors: false,
    error_message: '',
    rules: {
      product: {
        item_number: [
          v => !!v || 'Item Number is required',
        ],
        category: [
          v => !!v || 'category is required',
        ],
        name: [
          v => !!v || 'name is required',
          v => (v && v.length <= 50) || 'Name must be less than 50 characters',
        ],
        description: [
          v => !!v || 'description is required',
          v => (v && v.length <= 200) || 'Name must be less than 200 characters',
        ],
        item_size_width: [
          v => !!v || 'item_size_width is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        item_size_height: [
          v => !!v || 'item_size_height is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        item_size_depth: [
          v => !!v || 'item_size_depth is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        carton_measures_width: [
          v => !!v || 'carton_measures_width is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        carton_measures_height: [
          v => !!v || 'carton_measures_height is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        carton_measures_depth: [
          v => !!v || 'carton_measures_depth is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        pieces_per_master_carton: [
          v => !!v || 'pieces_per_master_carton is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        qty_moq: [
          v => !!v || 'qty_moq is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        fob_price: [
          v => !!v || 'fob_price is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        unit: [
          v => !!v || 'unit is required',
        ],
        master_carton_cbm: [
          v => !!v || 'master_carton_cbm is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        master_carton_gross_w: [
          v => !!v || 'gross_w is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        master_carton_net_weight: [
          v => !!v || 'net_weight is required',
          v => (parseFloat(v) > 0) || "Value must be greater than 0"
        ],
        qty_20: [
          v => !!v || 'qty_20 is required',
        ],
        qty_40: [
          v => !!v || 'qty_40 is required',
        ],
        qty_40hq: [
          v => !!v || 'qty_40hq is required',
        ],
      },
      organisation: {}
    }

  }),
  created: function () {
    this.get_system_categories().then(() => {
      this.selected_category = undefined;
      this.get_system_countries();
      this.get_system_department_categories();
    });
    //
    //
  },
  beforeDestroy() {
    this.resetData();
  },
  watch: {
    search(val) {
      val && val !== this.select && this.querySelections(val)
    },
    isUpdating(val) {
      if (val) {
        setTimeout(() => (this.isUpdating = false), 3000)
      }
    },

    department_model() {
      if (!!this.department_model) {
        this.$hget('/admins/products/', {
          params: {
            factory: this.department_model.id,
            item_numbers_array: JSON.stringify(Object.keys(this.table_values))
          }
        })
            .then(response => {
              //Setting existing items array
              let editable_columns = [
                'name',
                'description',
                'material',
                'packaging',
                'master_carton_cbm',
                'qty_moq',
                'qty_20',
                'qty_40',
                'qty_40hq',
                'item_size_width',
                'item_size_height',
                'item_size_depth',
                'fty_code',
                'gross_w',
                'net_weight',
                'unit',
                'fob_price',
                'carton_measures_width',
                'carton_measures_height',
                'carton_measures_depth',
                'pieces_per_master_carton'
              ]
              for (let item of response.data.results) {
                let item_number = item.item_number;
                Object.keys(item).forEach(key => {
                  console.log(key);

                  if (typeof this.table_values[item_number][key] === "undefined" || this.table_values[item_number][key] === "" || this.table_values[item_number][key] === "NOT SET") {
                    /**
                     * If the product upload does not have the values add them
                     * TODO: This needs completely refactoring.
                     */
                    if (editable_columns.includes(key)) {
                      this.table_values[item_number][key] = item[key];
                    }

                  } else {
                    if (parseFloat(this.table_values[item_number][key]) === 0 && parseFloat(item[key]) !== 0) {
                      if (editable_columns.includes(key)) {
                        this.table_values[item_number][key] = item[key];
                      }
                    }
                  }
                });
                this.existing_products[item.item_number] = item;
              }
            });
      }

    },

    organisation_process(value) {
      if (value === 'create')
        this.department_process = 'create';
    },

    department_process(value) {
      if (value === 'create')
        this.user_add_if_not_exists = true;
    },

    selected_contact(value) {
      this.user_add_if_not_exists = (!value.length);
    },
  },
  computed: {
    organisation_users: function () {
      return this.department_model.users.concat(this.department_model.organisation.users) || []
    },

    continue_from_department: function () {
      // Contact user must be provided
      if ((!!this.user_add_if_not_exists && !!this.values.factory_email && !!this.values.factory_contact_name) || !!this.selected_contact)
        return true;
    },

    select_child_category: function () {
      let res = this.categories_children.find(cat => cat.id === this.product.category_id);
      return (!!res) ? res : undefined;
    },

    select_parent_category: function () {
      if (!this.selected_child_category) return undefined;
      let res = this.categories_parents.find(cat => cat.id === this.selected_child_category.parent);
      return (!!res) ? res : undefined;
    },
  },
  methods: {
    querySelections(v) {
      this.loading = true;
      // Simulated ajax query
      setTimeout(() => {
        this.$hget(
            this.$http.defaults.baseURL + '/admins/products/xlsx/categories/' + v).then((response) => {
          if (typeof response.data !== 'undefined') {
            this.categories = response.data;
          }
        });
        this.loading = false;
      }, 500)
    },

    async get_system_categories() {
      return await Promise.all([
        this.load_categories_parents(),
        this.load_categories_children(),
        this.load_tags()
      ])
    },

    get_possible_select_child_categories(product) {
      if (product.selected_parent_category)
        return this.categories_children.filter(cat => cat.parent === product.selected_parent_category.id);
    },

    load_categories_parents() {
      return this.$hget('/admins/product/categories/parents')
          .then(response => response.data)
          .then(categories => {
            //console.log(categories);
            this.categories_parents = categories;
            return categories;
          })
    },

    load_categories_children() {
      return this.$hget('/admins/product/categories/children')
          .then(response => response.data)
          .then(categories => {
            //console.log(categories);
            console.log(categories)
            this.categories_children = categories;
            return categories;
          })
    },

    async load_tags() {
      return this.$hget('/admins/default-tags/')
          .then(response => response.data)
          .then(response => {
            this.default_tags = response.data;
            return this.default_tags;
          });
    },
    async get_system_countries() {
      this.countries = await this.$hget('/data/countries').then(r => r.data);
    },

    async get_system_department_categories() {
      this.department_categories = await this.$hget('/data/department_categories').then(r => r.data);
    },

    async setting_upload_interface() {
      this.department_search_input = this.values['factory_name'];
      this.search_factory(this.department_search_input);
      this.sourcer_find_async(this.values['sourcer'].toLowerCase());
    },

    cancelProcess() {
      this.resetData();
      this.$emit('close');
    },

    resetData() {
      this.valid_form.data_set = false;
      this.valid_form.msg = "";
      this.valid_form.valid = false;
      this.values = function () {
        return {}
      };
      this.table_values = function () {
        return {}
      };
    },

    async handleFile(e) {
      console.log('file handle');
      this.resetData();

      this.product_template = parser_template;
      this.product_template.setRegex((await import('@/utils/CommonRegex')).default);


      let files = e.target.files, f = files[0];

      /* Boilerplate to set up FileReader */
      let reader = new FileReader();
      reader.onload = async (e) => {
        try {
          // Loading Xlsx information
          let workbook_data = e.target.result;

          let workbook = await new (await import('exceljs')).Workbook();
          await workbook.xlsx.load(workbook_data);
          let worksheet = await workbook.getWorksheet(this.product_template.sheet_name);

          //Setting
          let parser = new Parser;

          let media = parser.getImages(workbook, worksheet);
          let temp_values = parser.getFieldData(this.product_template, worksheet);
          let temp_table_values = parser.getTableData(this.product_template, worksheet, media);

          parser.runProcesses(this.product_template, temp_values, temp_table_values);
          this.values = temp_values;

          this.table_values = temp_table_values;
          this.valid_form.valid = true;
          this.setting_upload_interface();
        } catch (e) {
          console.log(e);
          this.resetData();
          this.valid_form.valid = false;
          this.valid_form.msg = e.message;
        } finally {
          this.valid_form.data_set = true;
        }
      };

      await reader.readAsArrayBuffer(f);
    },

    async downloadTemplateExcelSheet() {
      window.open(location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/res/template/product_upload_template.xlsx');
    },

    async clear_search() {
      this.factory_search_input = undefined;
    },

    async search_factory(search_field) {


      if (!search_field || typeof search_field === "undefined")
        return;

      this.department_process = this.organisation_process = 'find';

      this.department_model = {};

      let res = await this.$hget('/admins/departments/?name=' + search_field)
          .then(r => r.data)
          .then(r => r.results);


      if (!res && res.length === 0) {
        console.log("No results found");
        this.department_process = this.organisation_process = 'create';
        return;
      }

      this.department_model = res = res[0];
      this.values.organisation_name = this.values.factory_name;

      if (Object.keys(this.values).includes('factory_contact_name')) {
        //Getting first and last name from contact name
        let space_index = this.values.factory_contact_name.indexOf(' ');

        if (space_index !== -1) {
          this.values.factory_contact_last_name = this.values.factory_contact_name.substring(space_index + 1);
          this.values.factory_contact_name = this.values.factory_contact_name.substring(0, space_index);
        } else {
          this.values.factory_contact_last_name = '';
        }
      }

      this.selected_contact = this.department_model.users;
      if (!!this.values.factory_email && !!this.department_model.organisation.users) {
        let preselected = this.department_model.organisation.users.find(i => i.email === this.values.factory_email);
        this.department_model.organisation.users.push(preselected);
      }

      this.organisation_process = this.department_process = 'find';

      let temp_category_format = [];
      if (typeof res.categories !== 'undefined')
        for (let [key, value] of Object.entries(res.categories))
          temp_category_format[key] = {'id': parseInt(key), 'value': value};
      res.categories = temp_category_format;

    },

    split_in_two(string) {

    },

    async sourcer_find_async(query) {
      this.sourcer_is_loading = true;
      console.log(query);
      let res = await this.$hget('/admins/users/', {params: {email: query, group_name: 'Sourcer'}});

      this.sourcer_query_results = res.data.results;
      this.sourcer_model = this.sourcer_query_results[0] || null;
      this.sourcer_is_loading = false;
    },

    async processOrgAndDepartment() {

      switch (this.organisation_process) {
        case "find":
          switch (this.department_process) {
            case "find":
              console.log("find:find");
              if (await this.department_assert_user())
                this.e1 = 2;
              break;
            case "edit":
              console.log("find:edit");
              if (await this.department_edit() && await this.department_assert_user())
                this.e1 = 2;
              break;
            case "create":
              console.log("find:create");
              if (await this.department_create() && await this.department_assert_user())
                this.e1 = 2;
              break;
          }
          break;
        case "edit":
          //Editing organisation
          if (await this.organisation_edit())
            switch (this.department_process) {
              case "find":
                console.log("edit:find");
                if (await this.department_assert_user())
                  this.e1 = 2;
                break;
              case "edit":
                console.log("edit:edit");
                if (await this.department_edit() && await this.department_assert_user())
                  this.e1 = 2;
                break;
              case "create":
                console.log("edit:create");
                if (await this.department_create() && await this.department_assert_user())
                  this.e1 = 2;
                break;
            }
          break;
        case "create":
          // Create Org and Department
          console.log("create:create");
          if (await this.organisation_then_department_create() && await this.department_assert_user())
            this.e1 = 2;
          break;
      }
    },

    async organisation_then_department_create() {
      let res = await this.$hpost('admins/products/xlsx/organisation/',
          {
            name: this.values.organisation_name,
            website: this.values.factory_website,

            contact_email: this.values.factory_email,
            contact_first_name: this.values.factory_contact_name,
            contact_last_name: this.values.factory_contact_last_name,
            contact_phone: this.values.factory_contact_number || null
          });


      if (!(res.status >= 200 || res.status < 300)) return false;

      this.success("Organisation created successfully: ID - " + res.data.id);
      this.organisation_process = 'find';
      await this.department_create(res.data.id);

      return true;
    },

    async organisation_edit() {
      let res = await this.$hpatch('admins/products/xlsx/organisation/' + this.department_model.organisation.id + '/',
          {
            name: this.department_model.organisation.name,
            website: this.department_model.organisation.website
          });

      if (!(res.status >= 200 || res.status < 300)) return false;

      this.success("Organisation edited successfully: ID - " + res.data.id);
      this.organisation_process = 'find';
      this.department_model.organisation = res.data;

      return true;
    },

    async department_create(organisation_id = null) {
      let res = await this.$hpost('admins/products/xlsx/organisation/' + organisation_id || this.department_model.organisation.id + '/department/',
          {
            name: this.values.factory_name,
            address: this.values.factory_address,
            shipping_port: this.values.shipping_port,

            zip_code: this.values.zip_code || null,

            country: this.department_create_country,
            category: this.department_create_category
          });

      if (!(res.status >= 200 || res.status < 300)) return false;

      this.success("Department created successfully: ID - " + res.data.id);
      this.department_process = 'find';
      this.department_model = res.data;

      return true;
    },

    async department_edit() {
      let res = await this.$hpatch('admins/products/xlsx/organisation/department/' + this.department_model.id + '/',
          {
            name: this.department_model.name,
            address: this.department_model.address,
            shipping_port: this.department_model.shipping_port,

            zip_code: this.department_model.zip_code || null,

            country: this.department_model.country,
            category: this.department_model.category
          });

      if (!(res.status >= 200 || res.status < 300)) return false;

      this.success("Department edited successfully: ID - " + res.data.id);
      this.department_process = 'find';
      this.department_model = res.data;

      return true;
    },

    async department_assert_user() {
      if (this.user_add_if_not_exists) {
        let res = await this.$hpost('admins/products/xlsx/organisation/department/' + this.department_model.id + '/user',
            {
              contact_email: (this.user_add_if_not_exists) ? this.values.factory_email : null,
              contact_first_name: (this.user_add_if_not_exists) ? this.values.factory_contact_name : null,
              contact_last_name: (this.user_add_if_not_exists) ? this.values.factory_contact_last_name : null,

              contact_phone: (this.user_add_if_not_exists) ? (this.values.factory_contact_number || null) : null,
            });

        if (!(res.status >= 200 || res.status < 300))
          return false;

        this.success("Department user added successfully [" + this.department_model.id + "]: ID - " + res.data.id);

        return true;

      } else if (!!this.selected_contact) {
        for (let user of this.selected_contact) {
          let res = await this.$hpost('admins/products/xlsx/organisation/department/' + this.department_model.id + '/user',
              {contact_email: user.email});

          if (!(res.status >= 200 || res.status < 300)) return false;
        }

        this.success("Department users added successfully");

        return true;
      }
    },

    async success(message) {
      this.$notify({
        title: 'Success:',
        text: message,
        group: 'notifications',
        position: 'top right'
      });
    },

    async uploadProducts() {

      if (!this.$refs.product_upload_form.validate()) {
        for (let p of Object.values(this.table_values)) {
          if(!p.slug && p.name)
          {
            p.slug = p.name.replace(' ', '-').toLowerCase().replaceAll(' ', '');
          }
        }

        this.$notify({
          title: 'Error:',
          text: "Please fill in required fields",
          group: 'notifications',
          position: 'top right'
        });
        return false;
      }
      this.process_text = "Uploading products";

      this.e1 = 3;

      for (let p of Object.values(this.table_values)) {
        console.log(this.department_model);
        p.factory = this.department_model.id;
        if (this.$store.getters.user_groups.includes(3) || this.$store.getters.user_groups.includes(17))
          p.sourcer = this.$store.getters.user.id;
      }

      let res = await this.$hpost('/admins/products/xlsx', {
        'product': Object.values(this.table_values)
      }).then(response => response.data);


      console.log(res);
      if (typeof res === 'undefined') {
        this.e1 = 2;
        return;
      }
      this.e1 = 4;
    },

    async uploadFiles(files) {
      let formData = new FormData();
      console.log("UPLOADING FILES");
      console.log(files);

      for (let i = 0; i < files.length; i++)
        formData.append("files[]", files[i], files[i].name);

      let headers = Object.assign(this.$http.defaults.headers.common);
      headers['Content-Type'] = 'multipart/form-data';

      return await this.$http.post('/order/file', formData, {headers: headers})
          .then(response => response.data)
          .catch(function (e) {
            console.log(e);
            console.log('FAILURE!!');
          });
    },

    sanitize_for_search(string) {
      if (!string) return "";
      // TODO: check the sinitizing regex on the backend for the unique_id on the department
      let sanitize_regex = /[()\\/\-_^".,<>$#%&*=+!?~` \n]+/g;

      return string.replace(sanitize_regex, "").toLowerCase();
    },

  }

}
</script>
