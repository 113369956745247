<template>
    <v-container fluid class="ma-0 pa-2" v-if="!!selected_invoice">
        <v-row class="mx-2" align="center">
            <v-col class="title">Invoice: {{selected_invoice.invoice_id}}</v-col>

            <v-btn fab x-small @click="chat_opened = !chat_opened">
                <v-icon>mdi-message</v-icon>
            </v-btn>

            <v-btn text fab x-small @click="$emit('close')">
                <v-icon>mdi-close</v-icon>
            </v-btn>

        </v-row>
        <v-divider class="mx-2 mt-1 mb-4"/>
        <v-row align="center" class="mx-2">
            <v-flex>
                <v-layout grow align-center justify-end row fill-height class="mx-2 historic_layout">
                    <v-btn v-for="folder in history_folders"
                           v-bind:key="folder.id"
                           @click="folderNav_select_history(folder)">
                        <v-icon class="mr-2">mdi-folder</v-icon>
                        {{folder.name}}
                    </v-btn>
                </v-layout>
            </v-flex>
            <v-flex shrink class="mx-2 py-2">
                <v-icon>mdi-clock</v-icon>
            </v-flex>

        </v-row>
        <v-row align="start" justify="space-around" fill-height wrap
               v-if="!!selected_node && selected_node.type === 'root'"
               class="mx-5 my-2">
            <template v-for="category in selected_node.categories">
                <v-flex shrink  class="pa-2" v-if="show_category_title(category)">
                    <v-list dense class="uk-width-medium">

                        <v-list-item class="pr-0">
                            <v-list-item-content  class="pb-0">
                                <v-row>
                                    <v-col class="title">{{category.name}}</v-col>
                                    <v-spacer></v-spacer>
                                    <v-col class="align-self-end overline text-gray px-1">Complete</v-col>
                                    <v-col class="align-self-end overline text-gray px-1">Audited</v-col>
                                </v-row>

                            </v-list-item-content>
                        </v-list-item>

                        <v-divider class="ma-0"> </v-divider>

                        <v-list-item v-for="folder in category.folders"
                                     v-bind:key="folder.id"
                                     @click="folderNav_select(folder)"
                                     two-line >
                            <v-list-item-icon class="mr-3"><v-icon class="mt-2">mdi-folder</v-icon></v-list-item-icon>
                            <v-list-item-content class="py-1">
                                <v-list-item-title>{{folder.name}}</v-list-item-title>
                                <v-list-item-subtitle>{{ (folder.file_group.files)? (folder.file_group.files.length - folder.count_new): 0}} new | {{ (folder.file_group.files)? folder.file_group.files.length : 0}} Items</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-row>
                                    <v-col class="pa-1"><v-checkbox v-model="folder.completed" @click.stop="set_folder_ac_status(folder, 'completed')" hide-details></v-checkbox></v-col>
                                    <v-col class="pa-1"><v-checkbox v-model="folder.audited" @click.stop="set_folder_ac_status(folder, 'audited')" hide-details></v-checkbox></v-col>
                                </v-row>
                            </v-list-item-action>

                        </v-list-item>

                        <template v-for="sub_category in category.sub_categories" >
                        <v-list-item-group v-if="sub_category.folders.length > 0"
                                            class="mt-2">

                            <v-list-item-subtitle>{{sub_category.name}}</v-list-item-subtitle>
                            <v-divider class="mt-0 mb-2"> </v-divider>

                            <v-list-item v-for="folder in sub_category.folders"
                                         v-bind:key="folder.id"
                                         @click="folderNav_select(folder)">
                                <v-list-item-icon class="mr-3"><v-icon class="mt-2">mdi-folder</v-icon></v-list-item-icon>
                                <v-list-item-content class="py-1">
                                    <v-list-item-title>{{folder.name}}</v-list-item-title>
                                    <v-list-item-subtitle>{{ (folder.file_group.files)? (folder.file_group.files.length - folder.count_new): 0}} new | {{ (folder.file_group.files)? folder.file_group.files.length : 0}} Items</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <v-row>
                                        <v-col class="pa-1"><v-checkbox v-model="folder.completed" @click.stop="set_folder_ac_status(folder, 'completed')" hide-details></v-checkbox></v-col>
                                        <v-col class="pa-1"><v-checkbox v-model="folder.audited" @click.stop="set_folder_ac_status(folder, 'audited')" hide-details></v-checkbox></v-col>
                                    </v-row>
                                </v-list-item-action>
                            </v-list-item>

                        </v-list-item-group>
                        </template>

                    </v-list>

                </v-flex>
            </template>
        </v-row>
        <v-row v-if="!!selected_node && selected_node.type === 'folder'"
               class="mx-5 my-2">
                <v-list style="width:250px">
                    <v-list-item @click="folderNav_up()" :class="'folder_tile'">
                        <v-list-item-avatar>
                            <v-icon>mdi-arrow-up-bold</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Up</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item :class="'folder_tile'"
                                 v-if="selected_node.permission === 'ED'"
                                 @click="showing_invoice_information = !showing_invoice_information">
                        <v-list-item-avatar>
                            <v-icon v-show="!showing_invoice_information">mdi-toggle-switch</v-icon>
                            <v-icon v-show="!!showing_invoice_information">mdi-toggle-switch-off</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Permissions</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <input type="file" multiple style="display:none" @change="file_upload_prompt" ref="files_uploading_input">

                    <v-list-item :class="'folder_tile'"
                                 v-if="['WT', 'ED'].includes(selected_node.permission)"
                                 @click="$refs.files_uploading_input.click()">
                        <v-list-item-avatar>
                            <v-icon>mdi-file-upload</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>Upload</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider> </v-divider>

                    <v-list-item v-for="folder in selected_node.folders"
                                 :key="folder.id"
                                 :class="'folder_tile'"
                                 @click="folderNav_select(folder)">
                        <v-list-item-avatar>
                            <v-icon>mdi-folder</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{folder.name}}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                </v-list>


            <v-col >
                <!-- Files div -->
                <div  v-if="!showing_invoice_information">
                    <v-btn text class="ma-0 pa-0" height="120px" width="120px"
                            v-for="file of selected_node.file_group.files"
                           :key="file.id"
                           @click="downloadFile(file)"
                           v-tooltip="file.name">
                        <v-container class="pa-0">
                            <v-row justify="end" class="mx-0 mt-n6 mb-4">
                                <v-btn x-small fab @click.stop="delete_file(file)" v-if="!selected_node.deleted.includes(file.id)"><v-icon>mdi-delete</v-icon></v-btn>
                                <v-btn x-small fab @click.stop="restore_file(file)" v-if="!!selected_node.deleted.includes(file.id)"><v-icon>mdi-restore</v-icon></v-btn>
                            </v-row>
                            <v-row justify="center" class="mx-0">
                                <v-icon class="mb-2" large>mdi-file</v-icon>
                            </v-row>
                            <v-row justify="center" class="mx-0">
                                <div class="uk-text-truncate" style="max-width: 110px;">{{file.name}}</div>
                            </v-row>
                        </v-container>
                    </v-btn>
                </div>
                <!-- Folder options div -->
                <div v-if="showing_invoice_information">

                    <table class="compact_table" v-if="folder_permissions">
                        <caption>
                            <v-layout>
                                <h6>Permissions table:</h6>
                                <v-spacer> </v-spacer>
                                <th class="pa-1"><v-btn @click="savePermissions()" v-show="!!permissions_editing">Save</v-btn></th>
                                <th class="pa-1"><v-btn @click="editResetPermission()">{{ (!!permissions_editing)? 'Reset': 'Edit' }}</v-btn></th>
                            </v-layout>
                        </caption>
                        <thead>
                        <tr>
                            <th><div>Group:</div></th>
                            <th>
                                <div>Audit</div>
                            </th>
                            <th>
                                <div>None</div>
                                <div>View</div>
                                <div>Upload</div>
                                <div>All access</div>
                            </th>
                        </tr>
                        </thead>
                        <tbody ref="permissions_tbody">
                        <tr v-for="group of folder_permissions" style="height: 40px;">
                            <td><span style="font-size: 10px;">{{group.name}}</span></td>
                            <td>
                                <v-checkbox  :disabled="!permissions_editing"
                                             v-model="group.permissions.permission_to_audit"
                                             class="ma-0 pa-0" style="height:30px; width:30px;" hide-details>
                                </v-checkbox>
                            </td>
                            <td>
                                <v-radio-group :disabled="!permissions_editing"
                                               v-model="group.permissions.permission"
                                               :mandatory="true" hide-details
                                               row>
                                    <v-radio :value="null"></v-radio>
                                    <v-radio :value="'RD'"></v-radio>
                                    <v-radio :value="'WT'"></v-radio>
                                    <v-radio :value="'ED'"></v-radio>
                                </v-radio-group>
                            </td>
                        </tr>
                        </tbody>
                    </table>

                </div>

            </v-col>
        </v-row>

        <div class="order-chat-wrapper" v-if="chat_opened === true">
            <w-order-chat style="z-index: 1000;" :selected_invoice="selected_invoice" @close_chat="close_chat()"/>
        </div>

    </v-container>

</template>

<script>
    export default {
        name: "InvoiceFilesManager",
        props:{
            selected_invoice: Object
        },
        data: () => ({
            title: "Store",

            fs_tree: null,
            selected_node: null,
            history_folders: [],

            files: [],
            order_folders: [],

            showingDeletedFiles: false,
            showing_invoice_information: false,

            permissions_editing: false,
            folder_permissions_for_reset: [],
            folder_permissions: [],

            files_uploading: [],
            chat_opened: false,
            selected_invoice_chat:undefined,

            a_pass: false,
        }),
        created (){
            this.check_ap_pass();
            this.getCategoriesAndFolders();
        },
        watch:{
            selected_node: function (selected_node_value){
                if(selected_node_value.type === 'folder')
                    this.getPermissionsAndUserGroups();
            }
        },
        methods:{
            async check_ap_pass(){
                let groups = await this.$store.getters.user_groups_names;
                this.a_pass = (groups.includes('Super Admin') || groups.includes('Admin') || groups.includes('Logistics'));
            },

            async getCategoriesAndFolders () {
                let category_tree_root = {type: 'root', parent: null};

                let categories = await this.$hget('/order/' + this.selected_invoice.invoice_id + '/digested');

                category_tree_root.categories = categories.data;

                for(let category of category_tree_root.categories)
                    this.setParentReferences(category, category_tree_root, 'category');

                this.fs_tree = category_tree_root;

                this.selected_node = this.fs_tree;
                console.log(category_tree_root);
            },

            async delete_file(file){
                let res = await this.$hpost('order/file/' + file.id + '/delete');
                if(res.status >= 200 && res.status < 300)
                    this.selected_node.deleted.push(file.id)

            },

            async restore_file(file){
                let res = await this.$hpost('order/file/' + file.id + '/restore');
                if(res.status >= 200 && res.status < 300)
                    this.selected_node.deleted.splice( this.selected_node.deleted.indexOf(file.id), 1 );
            },

            setParentReferences(node, parent=null, type='category'){
                node.parent = parent;
                node.type = type;

                //Folder could have more than one depth; not implemented yet on the backend
                if(!!node.folders && !!node.folders.length)
                    for(let folder of node.folders)
                        this.setParentReferences(folder, node, 'folder');

                //Categories just have one level of depth, so checking folders on each should be enough
                if(!!node.sub_categories && !!node.sub_categories.length)
                    for(let category of node.sub_categories)
                        this.setParentReferences(category, node, 'category');

                if(!!node.categories && !!node.categories.length)
                    for(let category of node.sub_categories)
                        this.setParentReferences(category, node, 'category');

            },

            /* -- General navigation --*/
            folderNav_select(folder){
                if(this.history_folders.includes(folder))
                    this.folderNav_select_history(folder);
                else{
                    this.selected_node = folder;
                    if(this.history_folders.length >= 4)
                        this.history_folders.shift();
                    this.history_folders.push(folder);
                }
            },

            folderNav_select_history(folder){
                this.history_folders.splice(this.history_folders.indexOf(folder), 1);
                this.history_folders.push(folder);
                this.selected_node = folder;
            },

            folderNav_up () {
                let node = this.selected_node.parent;
                //Category doesnt have a view so cant be selected as a node
                while(node.type === 'category')
                    node = node.parent;
                this.selected_node = node;
            },

            file_upload_prompt (){
                if(this.$refs.files_uploading_input.files === undefined)
                    return console.log("Unable to load files in the front end");

                let files = this.$refs.files_uploading_input.files;
                let names_string = files[0].name;

                if(files.length > 1)
                    for (let file of files)
                        names_string += ", " + file.name;

                if (window.confirm("Upload: " + names_string + "?"))
                    this.uploadFiles(this.$refs.files_uploading_input.files);


                this.$refs.files_uploading_input.files = undefined;
            },

            uploadFiles(files){
                if(this.selected_node.type !== 'folder'){
                    this.$notify({
                        title: 'Error:',
                        text: 'No folder selected!',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return;
                }

                console.log(files);
                let formData = new FormData();

                for(let i = 0; i<files.length; i++)
                    formData.append("files[]", files[i], files[i].name);

                let headers = Object.assign(this.$http.defaults.headers.common);
                headers['Content-Type'] = 'multipart/form-data';

                console.log(formData);
                console.log(headers);

                this.$hpost('/order/' + this.selected_invoice.invoice_id + '/folder/' + this.selected_node.id + '/file', formData, { headers: headers })
                    .then(r => { this.selected_node.file_group = r.data });

            },

            downloadFile (file) {
                window.open( this.$http.defaults.baseURL  + file.url);
                this.$hpost('order/file/' + file.id + '/count')
            },

            set_folder_ac_status(folder, type='completed'){
                if(type==='completed')
                    this.$hpost('order/' + this.selected_invoice.invoice_id + '/folder/' + folder.id + '/complete')
                        .then(r => folder.completed = r.data);

                else if(type==='audited')
                    this.$hpost('order/' + this.selected_invoice.invoice_id + '/folder/' + folder.id + '/audit')
                        .then(r => folder.audited = r.data);

                else return false
            },

            /* Permissions navigations */
            async getPermissionsAndUserGroups(){

                if(!this.selected_node
                    || this.selected_node.type !== 'folder'
                    || this.selected_node.permission !== 'ED'
                    || !this.selected_node.id)
                    return;

                let queries = [];

                // TODO: Do this a thousand times more efficient!!
                queries.push(this.$hget('/order/process-folder/' + this.selected_node.id + '/permissions'));

                if(this.user_groups === undefined || !this.user_groups.length)
                    queries.push(this.$http.get('/user/group/info'));

                let [folder_permissions, user_groups] = await Promise.all(queries);

                folder_permissions = folder_permissions.data;

                console.log(folder_permissions);

                let indexed_folder_permissions = [];
                for(let x of folder_permissions)
                    indexed_folder_permissions[x.group] = x;

                for(let x of user_groups.data){
                    x.permissions = {
                        folder: this.selected_node.id,
                        group: x.id,
                        permission_to_audit: false,
                        permission:  null
                    };
                    if(typeof indexed_folder_permissions[x.id] !== 'undefined') {
                        x.permissions.permission = indexed_folder_permissions[x.id].permission;
                        x.permissions.permission_to_audit = indexed_folder_permissions[x.id].permission_to_audit;
                    }
                }

                this.folder_permissions_for_reset  = user_groups.data;
                this.folder_permissions = JSON.parse(JSON.stringify(user_groups.data));
            },

            editResetPermission (){
                if(this.permissions_editing){
                    this.folder_permissions = JSON.parse(JSON.stringify(this.folder_permissions_for_reset));
                    this.permissions_editing = false;
                } else{
                    this.permissions_editing = true;
                }
            },

            async savePermissions(){
                this.permissions_editing = false;

                let params = [];

                for(let group of this.folder_permissions)
                    params.push(group.permissions);

                this.$hpost('/order/process-folder/' + this.selected_node.id + '/permissions',
                    {params:params}
                ).then(this.getPermissionsAndUserGroups);

            },

            show_category_title(category){

                if(category.folders.length > 0)
                    return true;
                if(!!category.sub_categories)
                    for(let scategory of category.sub_categories)
                        if(scategory.folders.length > 0) return true;
                return false;
            },

            close_chat(){
                this.chat_opened = false;
            },
        },

    }
</script>

<style scoped lang="scss">
    .order-chat-wrapper {
        width: 400px;
        height: 500px;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 900;
        background: white;

        .close {
            width: 24px;
            height: 24px;
            position: absolute;
            top: 5px;
            right: 5px;
            z-index: 901;
        }
    }
    .historic_layout{
        background-color: #EEEEEE;
        border-radius: 2px;
        height: 44px;
    }

    .compact_table{
        thead  th> div {
            font-size: 10px !important;
            display: inline-block;
            width: 50px;
        }
    }




</style>
