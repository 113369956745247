<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="6" md="6">
                <v-text-field
                        v-model="product.item_number"
                        :counter="200"
                        required
                        :error-messages="checkItemNumber"
                        @input="$v.product.item_number.$touch()"
                        @blur="$v.product.item_number.$touch()"
                        label="Item Number"

                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
                <v-text-field
                        v-model="product.name"
                        required
                        :error-messages="checkName"
                        @input="$v.product.name.$touch()"
                        @blur="$v.product.name.$touch()"
                        :counter="200"
                        label="Name"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
                <v-text-field
                        v-model="product.material"
                        :counter="200"
                        required
                        :error-messages="checkMaterial"
                        @input="$v.product.material.$touch()"
                        @blur="$v.product.material.$touch()"
                        label="Material"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
                <v-text-field
                        v-model="product.packaging"
                        :counter="200"
                        required
                        :error-messages="checkPacking"
                        @input="$v.product.packaging.$touch()"
                        @blur="$v.product.packaging.$touch()"
                        label="Packaging"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
                <v-textarea
                        v-model="product.description"
                        label="Description"
                        required
                        :error-messages="checkDesc"
                        @input="$v.product.description.$touch()"
                        @blur="$v.product.description.$touch()"

                ></v-textarea>
            </v-col>

            <v-col cols="12" sm="4" md="2">
                <v-text-field
                        v-model="product.fob_price"
                        label="FOB"
                        required
                        :error-messages="checkFobPrice"
                        @input="$v.product.fob_price.$touch()"
                        @blur="$v.product.fob_price.$touch()"
                        type="number"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2" lg="2">
                <v-text-field
                        v-model="product.qty_moq"
                        label="MOQ"
                        required
                        :error-messages="checkMOQ"
                        @input="$v.product.qty_moq.$touch()"
                        @blur="$v.product.qty_moq.$touch()"
                        type="number"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2" lg="2">
                <v-text-field
                        v-model="product.qty_20"
                        label="20' Carton"
                        required
                        :error-messages="checkQty20"
                        @input="$v.product.qty_20.$touch()"
                        @blur="$v.product.qty_20.$touch()"
                        type="number"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2" lg="2">
                <v-text-field
                        v-model="product.qty_40"
                        required
                        :error-messages="checkQty40"
                        @input="$v.product.qty_40.$touch()"
                        @blur="$v.product.qty_40.$touch()"
                        label="40' Carton"
                        type="number"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2" lg="2">
                <v-text-field
                        v-model="product.qty_40hq"
                        required
                        :error-messages="checkQty40hq"
                        @input="$v.product.qty_40hq.$touch()"
                        @blur="$v.product.qty_40hq.$touch()"
                        label="40hq' Carton"
                        type="number"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="2" lg="2">

                <v-text-field
                        v-model="product.unit"
                        required
                        :error-messages="checkUnit"
                        @input="$v.product.unit.$touch()"
                        @blur="$v.product.unit.$touch()"
                        label="Unit"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-text-field
                        v-model="product.item_size_width"
                        label="Item Width"
                        required
                        :error-messages="checkItemWidth"
                        @input="$v.product.item_size_width.$touch()"
                        @blur="$v.product.item_size_width.$touch()"
                        type="number"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-text-field
                        v-model="product.item_size_height"
                        required
                        :error-messages="checkItemHeight"
                        @input="$v.product.item_size_height.$touch()"
                        @blur="$v.product.item_size_height.$touch()"
                        label="Item Height"
                        type="number"

                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-text-field
                        v-model="product.item_size_depth"
                        required
                        :error-messages="checkItemDepth"
                        @input="$v.product.item_size_depth.$touch()"
                        @blur="$v.product.item_size_depth.$touch()"
                        label="Item Depth"
                        type="number"

                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="2" md="2">
                <v-text-field
                        v-model="product.carton_measures_width"
                        label="Carton Width"
                        required
                        :error-messages="checkCtnWidth"
                        @input="$v.product.carton_measures_width.$touch()"
                        @blur="$v.product.carton_measures_width.$touch()"
                        type="number"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-text-field
                        v-model="product.carton_measures_height"
                        label="Carton Height"
                        required
                        :error-messages="checkCtnHeight"
                        @input="$v.product.carton_measures_height.$touch()"
                        @blur="$v.product.carton_measures_height.$touch()"
                        type="number"
                ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2" md="2">
                <v-text-field
                        v-model="product.carton_measures_height"
                        label="Carton Depth"
                        required
                        :error-messages="checkCtnHeight"
                        @input="$v.product.carton_measures_height.$touch()"
                        @blur="$v.product.carton_measures_height.$touch()"
                        type="number"
                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="4">
                <v-text-field
                        v-model="product.gross_w"
                        required
                        :error-messages="checkGrossWeight"
                        @input="$v.product.gross_w.$touch()"
                        @blur="$v.product.gross_w.$touch()"
                        label="Gross Weight"

                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="4">
                <v-text-field
                        v-model="product.net_weight"
                        required
                        :error-messages="checkNetWeight"
                        @input="$v.product.net_weight.$touch()"
                        @blur="$v.product.net_weight.$touch()"
                        label="Net Weight"

                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="4" md="4">
                <v-text-field
                        v-model="product.pieces_per_master_carton"
                        required
                        :error-messages="checkPcsPerCarton"
                        @input="$v.product.pieces_per_master_carton.$touch()"
                        @blur="$v.product.pieces_per_master_carton.$touch()"
                        label="Pieces Per Carton"

                ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12" md="12">
            </v-col>

            <v-col cols="12" sm="12" md="12">
            </v-col>

            <v-col cols="12" sm="12" md="12">
            </v-col>

            <v-col cols="12" sm="12" md="12">
                <v-btn
                        color="blue-grey darken-4"
                        outlined
                        block
                        @click="save(product)"
                >
                    Save
                </v-btn>
            </v-col>

        </v-row>

    </v-container>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength, minLength, email, numeric} from 'vuelidate/lib/validators'

    export default {
        name: 'EditProduct',
        props: {
            id: Number,
            auth_type: Number
        },
        components: {},
        computed: {
            checkName() {
                const errors = [];
                if (!this.$v.product.name.$dirty) return errors;
                !this.$v.product.name.maxLength && errors.push('product.name must be at most 200 characters long');
                !this.$v.product.name.required && errors.push('product.name is required.');
                return errors;
            },
            checkDesc() {
                const errors = [];
                if (!this.$v.product.description.$dirty) return errors;
                !this.$v.product.description.required && errors.push('product.description is required.');
                return errors;
            },
            checkItemNumber() {
                const errors = [];
                if (!this.$v.product.item_number.$dirty) return errors;
                !this.$v.product.item_number.maxLength && errors.push('product.item_number must be at most 200 characters long');
                !this.$v.product.item_number.required && errors.push('product.item_number is required.');
                return errors;
            },
            checkPacking() {
                const errors = [];
                if (!this.$v.product.packaging.$dirty) return errors;
                !this.$v.product.packaging.required && errors.push('product.packing is required.');
                return errors;
            },
            checkMaterial() {
                const errors = [];
                if (!this.$v.product.material.$dirty) return errors;
                !this.$v.product.material.required && errors.push('product.material is required.');
                return errors;
            },
            checkMOQ() {
                const errors = [];
                if (!this.$v.product.qty_moq.$dirty) return errors;
                !this.$v.product.qty_moq.numeric && errors.push('product.moq must be a number value');
                !this.$v.product.qty_moq.required && errors.push('product.moq is required.');
                return errors;
            },
            checkFobPrice() {
                const errors = [];
                if (!this.$v.product.fob_price.$dirty) return errors;
                !this.$v.product.fob_price.required && errors.push('product.fob_price is required.');
                return errors;
            },
            checkQty20() {
                const errors = [];
                if (!this.$v.product.qty_20.$dirty) return errors;
                !this.$v.product.qty_20.required && errors.push('product.qty_20 is required.');
                !this.$v.product.qty_20.numeric && errors.push('product.qty_20 must be a valid number.');
                return errors;
            },
            checkQty40() {
                const errors = [];
                if (!this.$v.product.qty_40.$dirty) return errors;
                !this.$v.product.qty_40.required && errors.push('product.qty_20 is required.');
                !this.$v.product.qty_40.numeric && errors.push('product.qty_40 must be a valid number.');
                return errors;
            },
            checkQty40hq() {
                const errors = [];
                if (!this.$v.product.qty_40hq.$dirty) return errors;
                !this.$v.product.qty_40hq.required && errors.push('product.qty_40hq is required.');
                !this.$v.product.qty_40hq.numeric && errors.push('product.qty_40hq must be a valid number.');
                return errors;
            },
            checkUnit() {
                const errors = [];
                if (!this.$v.product.unit.$dirty) return errors;
                !this.$v.product.unit.required && errors.push('product.qty_40hq is required.');
                return errors;
            },
            checkItemWidth() {
                const errors = [];
                if (!this.$v.product.item_size_width.$dirty) return errors;
                !this.$v.product.item_size_width.required && errors.push('product.item_size_width is required.');
                return errors;
            },
            checkItemHeight() {
                const errors = [];
                if (!this.$v.product.item_size_height.$dirty) return errors;
                !this.$v.product.item_size_height.required && errors.push('product.item_size_height is required.');
                return errors;
            },
            checkItemDepth() {
                const errors = [];
                if (!this.$v.product.item_size_depth.$dirty) return errors;
                !this.$v.product.item_size_depth.required && errors.push('product.item_size_depth is required.');
                return errors;
            },
            checkCtnWidth() {
                const errors = [];
                if (!this.$v.product.carton_measures_width.$dirty) return errors;
                !this.$v.product.carton_measures_width.required && errors.push('product.carton_measures_width is required.');
                return errors;
            },
            checkCtnHeight() {
                const errors = [];
                if (!this.$v.product.carton_measures_height.$dirty) return errors;
                !this.$v.product.carton_measures_height.required && errors.push('product.carton_measures_height is required.');
                return errors;
            },
            checkCtnDepth() {
                const errors = [];
                if (!this.$v.product.carton_measures_depth.$dirty) return errors;
                !this.$v.product.carton_measures_depth.required && errors.push('product.carton_measures_depth is required.');
                return errors;
            },
            checkGrossWeight() {
                const errors = [];
                if (!this.$v.product.gross_w.$dirty) return errors;
                !this.$v.product.gross_w.required && errors.push('product.carton_measures_depth is required.');
                return errors;
            },
            checkNetWeight() {
                const errors = [];
                if (!this.$v.product.net_weight.$dirty) return errors;
                !this.$v.product.net_weight.required && errors.push('product.carton_measures_depth is required.');
                return errors;
            },
            checkPcsPerCarton() {
                const errors = [];
                if (!this.$v.product.pieces_per_master_carton.$dirty) return errors;
                !this.$v.product.pieces_per_master_carton.required && errors.push('product.pieces_per_master_carton is required.');
                return errors;
            },
        },
        mixins: [validationMixin],

        validations: {
            product: {
                qty_moq: {
                    required,
                    numeric
                },
                fob_price: {
                    required,
                },
                name: {
                    required,
                    maxLength: maxLength(200)
                },
                packaging: {
                    required
                },
                material: {
                    required
                },
                description: {
                    required
                },
                item_number: {
                    required,
                    maxLength: maxLength(200)
                },
                qty_20: {
                    required,
                    numeric
                },
                qty_40: {
                    required,
                    numeric
                },
                qty_40hq: {
                    required,
                    numeric
                },
                unit: {
                    required
                },
                item_size_width: {
                    required,
                },
                item_size_height: {
                    required,
                },
                item_size_depth: {
                    required,
                },
                carton_measures_width: {
                    required,
                },
                carton_measures_height: {
                    required,
                },
                carton_measures_depth: {
                    required,
                },
                gross_w: {
                    required,
                },
                net_weight: {
                    required,
                },
                pieces_per_master_carton: {
                    required,
                }

            }
        },
        data() {
            return {
                product: {},
                link: '',
            }
        },
        watch: {
            'id': function (id) {
                if (id !== '') {
                    this.link = '/factories/products/';
                    this.load(id);
                }
            },
            isUpdating (val) {
                if (val) {
                    setTimeout(() => (this.isUpdating = false), 3000)
                }
            },
        },
        created() {
            this.link = '/factories/products/';
            this.load(this.id);
        },
        methods: {
            generate_slug() {
                let name = this.product.name;
                return name.replace(' ', '-').toLowerCase().replace(/\W/g, '');
            },
            load(id) {
                this.$http.get(
                    this.$http.defaults.baseURL + this.link + id + '/').then((response) => {
                    console.log(response);
                    this.product = response.data;
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            },
            save(product) {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$notify({
                        title: 'Error:',
                        text: 'The form must be filled out correctly, please check all information has been added correctly.',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return false;
                }

                let slug = this.generate_slug();
                product.slug = slug;

                this.$http.patch(
                    this.$http.defaults.baseURL + this.link + this.id + '/', product).then((response) => {
                    console.log(response);
                    this.product = response.data;
                    this.$notify({
                        group: 'notifications',
                        title: 'Success ',
                        text: 'Successfully Saved'
                    });
                })
                    .catch((error) => {
                        this.notification = true;
                        this.notification_message = 'Error: Please contact administrator';
                        console.log("Error");
                        console.log(error);
                    });
            }
        }
    }
</script>
