import moment from "moment";

// let fix_number = function(number, to_pos)
// {
//     number = parseInt(number);
//
//     if(typeof number === undefined || !number)
//     {
//         number = 0;
//     }
//
//     return number.toFixed(to_pos);
// };

export function fix_number(number, to_pos){
    number = parseInt(number);
    return number.toFixed(to_pos);
}

export function permission_intersect(groups, intersection){
    for(let g of groups)
        if(intersection.includes(g.id))
            return true;

    return false;
}

export function calc_item_cbm(line) {
    return ((line.product.item_size_width / 100) * (line.product.item_size_height / 100) * (line.product.item_size_depth / 100));
}

export function calc_carton_cbm(line) {
    if(!line.carton_measures_width)
    {
        line.carton_measures_width = 0;
    }
    if(!line.carton_measures_height)
    {
        line.carton_measures_height = 0;
    }
    if(!line.carton_measures_depth)
    {
        line.carton_measures_depth = 0;
    }
    let sum = ((line.carton_measures_width / 100) * (line.carton_measures_height / 100) * (line.carton_measures_depth / 100));
    if(!sum || sum === 0)
    {
        if(line.master_cbm && parseFloat(line.master_cbm) > 0)
        {
            return line.master_cbm;
        }
    }
    return sum.toFixed(6)
}

export function calc_item_ttl(line) {
    if(typeof line.pieces_per_master_carton === 'undefined' || line.pieces_per_master_carton === 0)
        return "N/A";
    else
        return ((line.qty / line.pieces_per_master_carton) * calc_item_cbm(line));
}

export function calc_carton_ttl(line) {
    return ((line.qty / line.pieces_per_master_carton) * calc_carton_cbm(line))
}

export function calc_total_master_ctn(line) {
    return (line.qty / line.pieces_per_master_carton)
}

export function calc_factory_target_fob(line) {
    return (line.customer_target_price_percentage_difference >= 1)
        ? (line.factory_fob_price - ((line.customer_target_price_percentage_difference / 100) * line.factory_fob_price))
        : line.factory_fob_price;
}

export function calc_customer_target_fob(line) {
    return (line.customer_target_price > 0)?
        line.customer_target_price : line.organisation_fob_price;
}

export function formatDateForPickers(d) {
    return moment(String(d)).format('YYYY-MM-DD')
}

export function formatDateDownload(d) {
    return moment(String(d)).format('DD/MM/YYYY')
}

export function formatSearchDate(d)
{
    let f_date = new Date(moment(String(d)));
    f_date.setHours(f_date.getHours() - 10);
    console.log(new Date(moment(String(d))));
    console.log(f_date);
    console.log(moment(String(f_date.toISOString())).format('DD/MM/YYYY'));
    return moment(String(f_date.toISOString())).format('DD/MM/YYYY')

}

export function calc_line_department_increase(line){
    return ((line.organisation_fob_price - line.factory_fob_price) / line.factory_fob_price ) * 100;
}

export function calc_line_department_increase_success(line){
    return (calc_line_department_increase(line) + .1)  < line.department_percentage;
}

export function customer_target_price_percentage_difference(line){
    return line.factory_fob_price - ((line.customer_target_price_percentage_difference / 100) * line.factory_fob_price)
}
