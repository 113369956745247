<template>
    <v-layout row>
        <v-flex>
            <chat-rooms></chat-rooms>
        </v-flex>
        <v-flex>
            <chat-active></chat-active>
        </v-flex>

    </v-layout>
</template>

<script>
    export default {
        name: "composite-view"
    }
</script>

<style scoped>

</style>