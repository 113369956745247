export default [
  {
    path: '/',
    component: (resolve) => import(`@/views/Main/Wrapper.vue`).then(resolve),
    children: [
      {
        path: '',
        name: 'Store',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/Store.vue`).then(resolve),
        }
      },
      {
        path: 'our-partners',
        name: 'OurPartners',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/OurPartners.vue`).then(resolve),
        }
      },
      {
        path: 'contact-us',
        name: 'ContactUs',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/ContactUs.vue`).then(resolve),
        }
      },
      {
        path: 'category/:category_slug',
        name: 'Category',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/Category.vue`).then(resolve),
        }
      },
      {
        path: 'search/:category_id/:search_name',
        name: 'ProductSearch',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/ProductSearch.vue`).then(resolve),
        }
      },
      {
        path: 'factory-products/:factory_id',
        name: 'FactorySearch',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/FactoryProducts.vue`).then(resolve),
        }
      },
      {
        path: 'product/:erp_item_number',
        name: 'Product',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/Product.vue`).then(resolve),
        }
      },
      {
        path: 'cart',
        name: 'Cart',
        meta: {
          requiresAuth: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/Cart.vue`).then(resolve),
        }
      },
      {
        path: 'wishlist',
        name: 'WishList',
        meta: {
          requiresAuth: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Main/WishList.vue`).then(resolve),
        }
      },
    ]
  },

  {
    path: '/',
    component: (resolve) => import(`@/views/Info/Wrapper.vue`).then(resolve),
    children: [
      {
        path: '/about',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_info: (resolve) => import(`@/views/Info/About.vue`).then(resolve),
        }
      },
      {
        path: '/sitemap',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_info: (resolve) => import(`@/views/Info/Sitemap.vue`).then(resolve),
        }
      }
    ]
  },
  {
    path: '/c-panel',
    component: (resolve) => import(`@/views/CPanel/Wrapper.vue`).then(resolve),
    children: [
      {
        path: '',
        name: 'Dashboard',
        meta: {
          requiresAuth: true
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Dashboard.vue`).then(resolve),
        }
      },
      {
        path: 'categories',
        name: 'Categories',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/Categories.vue`).then(resolve),
        }
      },
      {
        path: 'presentations',
        name: 'Presentations',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/Presentations.vue`).then(resolve),
        }
      },
      {
        path: 'ipad-fair',
        name: 'IpadFair',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/IpadFair.vue`).then(resolve),
        }
      },
      {
        path: 'download-presentations/:id',
        name: 'DownloadPresentations',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/DownloadPresentation.vue`).then(resolve),
        }
      },
      {
        path: 'orders',
        name: 'Orders',
        meta: {
          requiresAuth: true,
          authGroups:[1,2,15]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/Orders.vue`).then(resolve),
        }
      },
      {
        path: 'permissions',
        name: 'Permissions',
        meta: {
          requiresAuth: true
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Permissions.vue`).then(resolve),
        }
      },
      {
        path: 'products',
        name: 'Products',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/Products.vue`).then(resolve),
        }
      },
      {
        path: 'product/:product_id',
        name: 'EditProduct',
        meta: {
          requiresAuth: true,
          authGroups:[1,2, 3, 17, 21]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/EditProduct.vue`).then(resolve),
        }
      },
      {
        path: 'factories',
        name: 'Factories',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/Factories.vue`).then(resolve),
        }
      },
      {
        path: 'profile',
        name: 'Profile',
        meta: {
          requiresAuth: true,
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/Profile.vue`).then(resolve),
        }
      },
      {
        path: 'users',
        name: 'Users',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/Users.vue`).then(resolve),
        }
      },
      {
        path: 'user/:id',
        name: 'User',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/EditUser.vue`).then(resolve),
        }
      },
      {
        path: 'organisations',
        name: 'Organisations',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/Organisations.vue`).then(resolve),
        }
      },
      {
        path: 'customer/:id',
        name: 'Customer',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/EditCustomer.vue`).then(resolve),
        }
      },
      {
        path: 'factory/:factory_id/upload-products',
        name: 'UploadProducts',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/organisation/UploadProducts.vue`).then(resolve),
        }
      },
      {
        path: 'factory/edit-pi/:invoice_id',
        name: 'EditFactoryProforma',
        meta: {
          requiresAuth: true,
          authGroups:[1,2,3,6,12,15,17,21]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/organisation/EditPi.vue`).then(resolve),
        }
      },
        //sourcer links
      {
        path: 'sourcer/factories',
        name: 'Sourcer Factories',
        meta: {
          requiresAuth: true,
          authGroups:[3, 17]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sourcer/Factories.vue`).then(resolve),
        }
      },
      {
        path: 'sourcer/factory/:factory_id',
        name: 'SourcerFactory',
        meta: {
          requiresAuth: true,
          authGroups:[3, 17]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sourcer/EditFactory.vue`).then(resolve),
        }
      },
      {
        path: 'sourcer/products',
        name: 'Sourcer Products',
        meta: {
          requiresAuth: true,
          authGroups:[3, 17]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sourcer/Products.vue`).then(resolve),
        }
      },
      {
        path: 'sourcer/products/:id',
        name: 'SourcerProductsFactorySearch',
        meta: {
          requiresAuth: true,
          authGroups:[3, 17]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sourcer/Products.vue`).then(resolve),
        }
      },
      {
        path: 'sourcer/orders',
        name: 'Sourcer Orders',
        meta: {
          requiresAuth: true,
          authGroups:[3, 17]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sourcer/Orders.vue`).then(resolve),
        }
      },
      {
        path: 'sourcer/items-to-source',
        name: 'Sourcering items',
        meta: {
          requiresAuth: true,
          authGroups:[3, 17]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sourcer/ItemsToSource.vue`).then(resolve),
        }
      },
        //end sourcer links
        //start logistics
      {
        path: 'logistics/logistics-creator',
        name: 'Logistics Creator',
        meta: {
          requiresAuth: true,
          authGroups:[15]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/logistics/LogisticsCreator.vue`).then(resolve),
        }
      },
      // START BUSINESS INTEL
      {
        path: 'business-intel/data',
        name: 'BI Data',
        meta: {
          requiresAuth: true,
          authGroups:[1,2,19]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/bi/Data.vue`).then(resolve),
        }
      },

      {
        path: 'business-intel/pre-pis',
        name: 'Pre Proformas',
        meta: {
          requiresAuth: true,
          authGroups:[1,2,19]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/bi/PrePis.vue`).then(resolve),
        }
      },
      {
        path: 'business-intel/stock',
        name: 'Stock',
        meta: {
          requiresAuth: true,
          authGroups:[1,2,19]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/bi/Stock.vue`).then(resolve),
        }
      },

      {
        path: 'business-intel/barcodes',
        name: 'Barcodes',
        meta: {
          requiresAuth: true,
          authGroups:[1,2,19]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/bi/Barcodes.vue`).then(resolve),
        }
      },
        // END BUSINESS INTEL
      {
        path: 'logistics/orders',
        name: 'LogisticsOrders',
        meta: {
          requiresAuth: true,
          authGroups:[15]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/logistics/Orders.vue`).then(resolve),
        }
      },
      {
        path: 'logistics/labels',
        name: 'LogisticsLabels',
        meta: {
          requiresAuth: true,
          authGroups:[15]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/logistics/Labels.vue`).then(resolve),
        }
      },
      {
        path: 'logistics/boxes',
        name: 'LogisticsBoxes',
        meta: {
          requiresAuth: true,
          authGroups:[15]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/logistics/Boxes.vue`).then(resolve),
        }
      },
      {
        path: 'logistics/preview-pi/:invoice_id',
        name: 'Edit Proforma Preview',
        meta: {
          requiresAuth: true,
          authGroups:[1, 2, 3, 9, 11, 12, 13, 15]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/logistics/Preview.vue`).then(resolve),
        }
      },
      {
        path: 'logistics/edit-pi/:invoice_id',
        name: 'Edit Proforma',
        meta: {
          requiresAuth: true,
          authGroups:[1,2,3,15,17]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/logistics/EditPi.vue`).then(resolve),
        }
      },
      {
        path: 'logistics/finalise-pi/:invoice_id',
        name: 'Finalise Proforma',
        meta: {
          requiresAuth: true,
          authGroups:[1,2, 3, 15]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/logistics/FinalisePi.vue`).then(resolve),
        }
      },
        //end logistics

        //start audits
      {
        path: 'audits/orders',
        name: 'Audit orders',
        meta: {
          requiresAuth: true,
          authGroups:[18]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/admin/Orders.vue`).then(resolve),
        }
      },
        //end audits

        //start factories
      {
        path: 'org/orders',
        name: 'Factory orders',
        meta: {
          requiresAuth: true,
          authGroups:[6]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/organisation/Orders.vue`).then(resolve),
        }
      },
      {
        path: 'org/factories',
        name: 'Factory factories',
        meta: {
          requiresAuth: true,
          authGroups:[6]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/organisation/Factories.vue`).then(resolve),
        }
      },
      {
        path: 'org/users',
        name: 'OrganisationUsers',
        meta: {
          requiresAuth: true,
          authGroups:[6]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/organisation/Users.vue`).then(resolve),
        }
      },
      {
        path: 'org/products',
        name: 'Factory products',
        meta: {
          requiresAuth: true,
          authGroups:[6]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/organisation/Products.vue`).then(resolve),
        }
      },
      {
        path: 'org/product/:id',
        name: 'OrgEditProduct',
        meta: {
          requiresAuth: true,
          authGroups:[6]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/organisation/EditProduct.vue`).then(resolve),
        }
      },
        //ennd factories

        //start partners
      {
        path: 'partners/orders',
        name: 'Partner orders',
        meta: {
          requiresAuth: true,
          authGroups:[13]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/partners/Orders.vue`).then(resolve),
        }
      },
      {
        path: 'partners/users',
        name: 'Partner users',
        meta: {
          requiresAuth: true,
          authGroups:[13]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/partners/Users.vue`).then(resolve),
        }
      },
        //end partners
        //start sales
      {
        path: 'sales/orders',
        name: 'Sales orders',
        meta: {
          requiresAuth: true,
          authGroups:[7,8,9]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sales/Orders.vue`).then(resolve),
        }
      },
      {
        path: 'sales/departments',
        name: 'SalesDepartments',
        meta: {
          requiresAuth: true,
          authGroups:[7,8,9]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sales/Departments.vue`).then(resolve),
        }
      },
      {
        path: 'sales/edit-pi/:invoice_id',
        name: 'EditSalesProforma',
        meta: {
          requiresAuth: true,
          authGroups:[4,7,8,9,11]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sales/EditPi.vue`).then(resolve),
        }
      },
        //end sales
        // start sales manager
      {
        path: 'sales-manager/orders',
        name: 'SalesManagerOrders',
        meta: {
          requiresAuth: true,
          authGroups:[11]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sales_manager/Orders.vue`).then(resolve),
        }
      },
      {
        path: 'sales-manager/users',
        name: 'SalesManagerUsers',
        meta: {
          requiresAuth: true,
          authGroups:[11]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/sales_manager/Users.vue`).then(resolve),
        }
      },
        // end sales manager
        //category manager
      {
        path: 'category-manager/tags',
        name: 'CMTags',
        meta: {
          requiresAuth: true,
          authGroups:[]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/category_manager/Tags.vue`).then(resolve),
        }
      },
      {
        path: 'category-manager/categories',
        name: 'CMCategories',
        meta: {
          requiresAuth: true,
          authGroups:[1,2,3]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/category_manager/Categories.vue`).then(resolve),
        }
      },
      {
        path: 'category-manager/products',
        name: 'CMProducts',
        meta: {
          requiresAuth: true,
          authGroups:[]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/category_manager/Products.vue`).then(resolve),
        }
      },
        //end category manager
        //start developer
      {
        path: 'developer/work-times',
        name: 'Work Times',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/developer/WorkTimes.vue`).then(resolve),
        }
      },

      {
        path: 'developer/scripts',
        name: 'Scripts',
        meta: {
          requiresAuth: true,
          authGroups:[1]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/developer/Scripts.vue`).then(resolve)
        },
      },
      {
        path: 'developer/tasks',
        name: 'Tasks',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/developer/Tasks.vue`).then(resolve),
        }
      },
      {
        path: 'developer/logs',
        name: 'Logs',
        meta: {
          requiresAuth: true,
          authGroups:[1,2]
        },
        components: {
          router_wrapper_cpanel: (resolve) => import(`@/views/CPanel/developer/Logs.vue`).then(resolve),
        }
      },

        //end developer
    ]
  },
  {
    path: '/auth',
    component: (resolve) => import(`@/views/Auth/Wrapper.vue`).then(resolve),
    children: [
      {
        path: '',
        redirect: 'login'
      },
      {
        path: 'login',
        name: 'Login',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_auth: (resolve) => import(`@/views/Auth/Login.vue`).then(resolve),
        }
      },
      {
        path: 'link-login/:user/:key/:invoice_id',
        name: 'LinkLogin',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_auth: (resolve) => import(`@/views/Auth/LinkLogin.vue`).then(resolve),
        }
      },
      {
        path: 'register',
        name: 'Register',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_auth: (resolve) => import(`@/views/Auth/Register.vue`).then(resolve)
        }
      },
      {
        path: 'logout',
        name: 'Logout',
        meta: {
          guest: true,
          requiresAuth: true
        },
        components: {
          router_wrapper_auth: (resolve) => import(`@/views/Auth/Logout.vue`).then(resolve)
        }
      },
      {
        path: 'reset',
        name: 'ResetPassword',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_auth: (resolve) => import(`@/views/Auth/ResetPassword.vue`).then(resolve)
        }
      },
    ]
  },
  {
    path: '/messenger',
    component: (resolve) => import(`@/views/Chat/Wrapper.vue`).then(resolve)
  },
  {
    path: '/errors',
    component: (resolve) => import(`@/views/Main/Wrapper.vue`).then(resolve),
    children: [
      {
        path: '401',
        name: 'Permission Denied',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Errors/401.vue`).then(resolve),
        }
      },
      {
        path: '500',
        name: 'Server Error',
        meta: {
          guest: true
        },
        components: {
          router_wrapper_main: (resolve) => import(`@/views/Errors/500.vue`).then(resolve),
        }
      },
    ]
  },

  {
    path: '*',
    component: (resolve) => import(`@/components/w/PageNotFound.vue`).then(resolve)
  },
];
