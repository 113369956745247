<template>
  <v-card v-bind="$attrs"
          :style="styles"
          v-on="$listeners">

    <v-card-title >
      <div class="pa-2" style="display:block; width:100%;">
        <v-card style="width: 100%; height: 80px; margin-top:-60px;"
                :class="`elevation-${elevation}`"
                :color="color">
          <v-layout justify-center align-center row style="height: 100%;" class="ma-0 px-8">
            <v-flex shrink v-if="!title && !text" name="header"></v-flex>
            <v-flex shrink v-else name="header" class="my-2">
              <p class="headline font-weight-bold ma-0" :style="{color: text_color}" v-text="title"></p>
              <p class="category font-weight-thin ml-1" :style="{color: text_color}" v-text="text"></p>
            </v-flex>
            <v-spacer></v-spacer>
            <v-flex shrink class="my-2">
              <slot name="header_actions"></slot>
            </v-flex>
          </v-layout>
        </v-card>
      </div>
    </v-card-title>

    <v-card-text>
      <slot/>
    </v-card-text>

    <v-divider
      v-if="$slots.actions"
      class="mx-3"
    />

    <v-card-actions v-if="$slots.actions">
      <slot name="actions" />
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    color: {
      type: String,
      default: 'wst_green_dark'
    },
    text_color: {
      type: String,
      default: 'white'
    },
    elevation: {
      type: [Number, String],
      default: 10
    },
    inline: {
      type: Boolean,
      default: false
    },
    offset: {
      type: [Number, String],
      default: 24
    },
    title: {
      type: String,
      default: undefined
    },
    text: {
      type: String,
      default: undefined
    }
  },

  computed: {
    styles () {
      return {
        marginBottom: `${this.offset}px`,
        marginTop: `${this.offset * 2}px`
      }
    }
  }
}
</script>

<style lang="scss">
  .v-card--material {
    &__header {
      &.v-card {
        border-radius: 4px;
      }
    }
  }
</style>
