<template>
    <v-card class="pa-0" height="100%" v-if="selected_invoice !== undefined">
        <v-container style="height: 100%;" class="d-flex flex-column">

            <v-row class="my-0 ml-2 mr-1 flex-shrink-1 flex-grow-0" align="center" justify="center">
                <span class="ma-0 headline" size="20">Invoice: {{selected_invoice.pi_number}}</span>

                <v-spacer/>

                <v-menu open-on-hover offset-y

                        :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" fab text x-small
                               :disabled="!selected_room">
                            <v-icon>mdi-account-supervisor-circle</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item
                                v-if="selected_room !== undefined"
                                v-for="(item, index) in selected_room_members"
                                :key="index">
                            <v-list-item-action>
                                <v-icon v-show="item.member_type === 'A'"
                                        v-tooltip="'Admin'">
                                    mdi-key-variant
                                </v-icon>
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title>{{ item.username }}</v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                                <v-icon @click="delete_member(item)"
                                        v-if="is_room_admin">mdi-close</v-icon>
                            </v-list-item-action>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-title>
                                <v-btn depressed small block color="primary" @click="add_member_user_selection()">Add member</v-btn>
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-menu open-on-hover offset-y
                        :close-on-content-click="false">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" fab text x-small
                               :disabled="!selected_room">
                            <v-icon>mdi-settings</v-icon>
                        </v-btn>
                    </template>

                    <v-list dense>
                        <v-list-item @click="download_chat_history()">
                            <v-list-item-icon><v-icon>mdi-download</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>History</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <v-btn text x-small fab @click="close_chat()"><v-icon>mdi-close</v-icon></v-btn>
            </v-row>

            <v-row class="ma-2 flex-shrink-1 flex-grow-0">
                <v-select hide-details item return-object
                        class="pa-0 ma-0"
                        v-model="selected_room_type"
                        :items="room_types"
                        item-text="name">
                </v-select>
            </v-row>


            <v-divider class="mt-1 flex-shrink-1 flex-grow-0"/>

            <v-row class="ma-0 flex-shrink-1 flex-grow-0" v-show="false">
                <v-banner v-if="typeof this.selected_room !== undefined && !this.is_member(this.active_user) && this.permission_intersect(this.groups, 'Admin')" width="100%"
                          :elevation="1"
                          class="ma-1">
                    Not a member of this group
                    <v-spacer/>
                    <template v-slot:actions>
                        <v-btn x-small text fab color="deep-purple accent-4">
                            <v-icon v-tooltip="'Add self'">mdi-plus</v-icon>
                        </v-btn>
                    </template>
                </v-banner>
            </v-row>

            <v-row class="mx-0 my-1 px-0 flex-shrink-0 flex-grow-1">
                <div style="height: 100%; width:100%;">
                    <div style="height:350px; overflow-y: auto; background-color: #00000010" ref="chat_messages">
                        <v-container v-if="!!selected_room && selected_room.messages.length" >
                            <div class="d-flex flex-row-reverse my-2 mx-0"
                                 :class="{'flex-row-reverse': active_member === message.member}"
                                 v-for="message of selected_room.messages"
                                 :key="message.id">
                                <v-card grow class="py-1 px-3 ma-1 elevation-1">
                                    <div class="overline">{{ seek_member(message.member).username }} - {{process_timestamp(message.timestamp)}}</div>
                                    <div>{{message.content}}</div>
                                </v-card>
                            </div>
                        </v-container>
                    </div>
                </div>
            </v-row>

            <v-row class="ma-0 flex-shrink-1 flex-grow-0">
                <v-textarea hide-details auto-grow autofocus clearable
                            class="ma-0 pa-0"
                            rows="1"
                            v-model="current_message"
                            :disabled="selected_room === undefined"
                            v-on:keydown.enter="sendText()"
                />
                <v-btn text rounded fab x-small
                       color="primary"
                       @click="sendText()"
                       :disabled="selected_room === undefined">
                    <v-icon>mdi-send</v-icon>
                </v-btn>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    import user_select from 'components/w/UserSelect'

    export default {
        name: "OrderChat",
        props:{
            selected_invoice: Object,
        },
        data: () => ({
            groups: undefined,
            factories: undefined,
            current_message: "",

            room_types: [],
            selected_room_type: undefined,

            selected_room: undefined,
            selected_room_members: [],

            active_user: undefined,
            active_member: undefined,
        }),
        created () {
            this.active_user = this.$store.getters.user.id;
            this.groups = this.$store.getters.user_groups_names;
            this.loadRoomTypes();
        },
        computed: {
            // a computed getter
            is_room_admin: function () {
                let member = this.selected_room.members.find(m => m.user === this.active_user);
                return !!member && member.member_type === 'A';
            }
        },
        watch: {
            'selected_room_type': function (selected_room_type) {
                if(selected_room_type !== undefined)
                    this.loadRoom(selected_room_type);
            },
            'selected_invoice': function (selected_invoice) {
                this.loadRoomTypes();
            },
        },
        methods: {
            process_timestamp(timestamp){
                let date = new Date(timestamp);
                let date_dif = (Date.now() - date.getTime()) / (1000 * 3600 * 24);

                if (date_dif <= 1)
                    return  String(date.getHours()).padStart(2, '0')  + ":" + String(date.getMinutes()).padStart(2, '0');
                else if (date_dif > 1 && date_dif <= 2)
                    return "Yesterday";
                else return Math.ceil(date_dif) + " days ago";
            },

            close_chat(){
              this.$emit('close_chat')
            },

            permission_intersect(groups, intersection){
                if(groups.includes('Admin') || groups.includes('Super Admin'))
                    return true;

                for(let value of intersection)
                    if(groups.includes(value))
                        return true;
                return false;
            },

            loadRoomTypes(){
                this.room_types = [];
                this.selected_room_type = undefined;

                // Chat types for factory user
                if(this.permission_intersect(this.groups, ['Factory User'])){
                    // Factory chat
                    if(typeof this.selected_invoice.factories !== 'undefined'){
                        for(let factory of this.selected_invoice.factories)
                            this.room_types.push({
                                id:2,
                                name: factory.name.substring(0,30),
                                factory: factory.id
                            });

                    } else if(typeof this.selected_invoice.factory !== 'undefined')
                        this.room_types.push({
                            id:2,
                            name: this.selected_invoice.factory.name.substring(0,30),
                            factory: this.selected_invoice.factory.id
                        });

                }

                // Chat types for Sourcer users
                if(this.permission_intersect(this.groups, ['Sourcer', 'Sourcing Manager'])){

                    for(let factory of this.selected_invoice.factories)
                        this.room_types.push({
                            id:2,
                            name: factory.name.substring(0,30),
                            factory: factory.id
                        });

                    this.room_types.push({ id: 3, name: 'Sourcer-SalesAdmin Chat'});
                    this.room_types.push({ id: 5, name: 'Logistics'});
                    this.room_types.push({ id: 6, name: 'Partner'});
                }

                // Chat types for Sales Manager personnel
                if(this.permission_intersect(this.groups, ['Sales Manager'])){
                    this.room_types.push({ id: 3, name: 'Sourcer-SalesAdmin Chat'});
                    this.room_types.push({ id: 4, name: 'Sales Chat' });
                }

                // Chat types for Sales personnel
                if(this.permission_intersect(this.groups, ['Alt Sales', 'Sales Exec', 'Sales Direct'])){
                    this.room_types.push({ id: 4, name: 'Sales Chat' })
                }

                //Chat types for Logistics
                if(this.permission_intersect(this.groups, ['Logistics'])){
                    for(let factory of this.selected_invoice.factories)
                        this.room_types.push({
                            id:2,
                            name: factory.name.substring(0,30),
                            factory: factory.id
                        });

                    this.room_types.push({ id: 3, name: 'Sourcer-SalesAdmin Chat'});
                    this.room_types.push({ id: 4, name: 'Sales Chat' });
                    this.room_types.push({ id: 5, name: 'Logistics' });
                    this.room_types.push({ id: 6, name: 'Partner'});
                }

                //Chat types for Partners
                if(this.permission_intersect(this.groups, ['WellStar Partner'])){
                    this.room_types.push({id:6, name: 'Partner'});
                }

                // After loading the available room types, we auto-select the first one
                if(!!this.room_types.length)
                    this.selected_room_type = this.room_types[0];

            },

            loadRoom(){
                if(this.selected_room_type === undefined)
                    return;

                this.$hpost('/admins/order/rooms/', {order: this.selected_invoice.id, room_type:this.selected_room_type.id, factory: this.selected_room_type.factory || null})
                    .then((res)=> { console.log(res); return res.data })
                    .then( order_room => this.selected_room = order_room.room)
                    .then( _ => this.replace_member_object())
                    .then( _ => { this.$refs['chat_messages'].scrollTop =  this.$refs['chat_messages'].scrollHeight; });

            },

            async sendText(){
                if(!!this.current_message){
                    let res = await this.$hpost('/comms/room/' + this.selected_room.id + '/', {content:this.current_message}).then(res => {
                        console.log(res);

                        if(res.status === 202){
                            this.selected_room = res.data;

                            console.log(res.data)
                        }else this.$notify({ group: 'notifications', title: 'Error ' + data.status, text: data.data });

                        this.current_message = null;
                    }).then( _ =>{
                        this.$refs['chat_messages'].scrollTop =  this.$refs['chat_messages'].scrollHeight;
                    });
                }
            },

            seek_member(id){
                if(Object.keys(this.selected_room.members).length > 0){
                    for(let member of this.selected_room.members)
                        if(member.id === id)
                            return member
                }
                return null;
            },

            is_member(user_id){
                if(this.selected_room === undefined)
                    return false;

                if(Object.keys(this.selected_room.members).length > 0)
                    for(let member of this.selected_room.members)
                        if(member.user ===  this.active_user)
                            return true;

                return false

            },

            async delete_member(member){

                let data = await this.$hdelete('/comms/room/' + this.selected_room.id + '/member', {data: {member:member.id}});

                console.log(data);

                if( parseInt(data.status) === 202){
                    this.selected_room = data.data;
                    this.replace_member_object();
                }else this.$notify({ group: 'notifications', title: 'Error ' + data.status, text: data.data });

            },

            async replace_member_object(){
                this.selected_room_members = [];
                for(let member of this.selected_room.members){
                    this.selected_room_members.push(member);
                    if(member.user === this.active_user)
                        this.active_member = member;
                }
            },

            add_member_user_selection(){
                this.$modal.show(user_select, {
                    room: this.selected_room,
                    callback_function: this.add_member
                },{
                    clickToClose: false
                });
            },

            async add_member(user, member_type){
                console.log(user, member_type);

                let data = await this.$hpost('/comms/room/' + this.selected_room.id + '/member', {user: user.id, member_type:member_type.value});

                console.log(data);

                if( parseInt(data.status) === 202){
                    this.selected_room = data.data;
                    this.replace_member_object();
                }

            },

            async get_member_initials(member){
                return member.username.substring(0, 2)
            },

            async download_chat_history(){
                let file = {};
                file['order'] = this.selected_invoice.invoice_id;
                file['room_id'] = this.selected_room.id;
                file['room_name'] = this.selected_room.name;
                file['room_description'] = this.selected_room.description;
                file['room_members'] = this.selected_room.members;
                file['room_messages'] = [];



                for( let message of this.selected_room.messages){
                    let member = file['room_members'].find(m => m.id === message.member);

                    file['room_messages'].push({
                        'timestamp': message.timestamp,
                        'member_username': member.username,
                        'message': message.content
                    })
                }

                const data = JSON.stringify(file);
                const blob = new Blob([data], {type: 'text/plain'});
                const e = document.createEvent('MouseEvents'),
                    a = document.createElement('a');
                a.download = "test.json";
                a.href = window.URL.createObjectURL(blob);
                a.dataset.downloadurl = ['text/json', a.download, a.href].join(':');
                e.initEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
                a.dispatchEvent(e);

            }
        }
    }
</script>
