import Vue from 'vue';

let state = {
    accessToken:  localStorage.getItem('access_token') ||  '',
    user : {},
    user_groups: {},
    user_groups_names: {},
    user_permissions: {},
    $http: Vue.prototype.$http,
    endpoints: {
        obtainJWT: '/auth/api-token-auth/',
        refreshJWT: '/auth/api-token-refresh/',
        verifyJWT:'/auth/api-token-verify/'
    },
    backend_url: Vue.prototype.$http.defaults.baseURL,
    headers: Vue.prototype.$http.defaults.headers,

    responsive: false,

};

export default state
