import Vue from 'vue';
import UI_kit from 'uikit'

Vue.directive('tooltip',{
    bind: function (el, binding) {
        UI_kit.tooltip(el, {title: binding.value});
    }

});

import infiniteScroll from 'vue-infinite-scroll'

Vue.directive('infinite-scroll', infiniteScroll);