// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'

//Custom imports
import './plugins'
import './components'
import './custom'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
import i18n from './i18n'
import router from './router'
import store from './store'
import VeeValidate from "vee-validate";
import vuetify from './plugins/vuetify'
import moment from 'moment'

Vue.use(VeeValidate);

// Sync store with router
sync(store, router);
// TODO: Add stage const app wide

Vue.config.productionTip = false;

Vue.filter('formatDate', function(value) {
    if (value) {
        return moment(String(value)).format('DD/MM/YYYY hh:mm')
    }
});

/* eslint-disable no-new */
new Vue({
    i18n,
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app');

