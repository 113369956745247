import Vue from 'vue'

import axios from 'axios/index'
import Notifications from 'vue-notification'

axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    "Content-Type": 'application/json',
    "Authorization": localStorage.getItem('access_token') ? 'jwt ' + localStorage.getItem('access_token') : undefined,
    "Access-Control-Allow-Origin": "*"
};

axios.defaults.baseURL = window.location.protocol + '//' + window.location.host + '/api';

Vue.prototype.$http = axios;

let error_filter = function(res){
  if(res !== undefined && res.status !== undefined && (parseInt(res.status) < 200 || parseInt(res.status) >= 300)){
      console.error(res);
      Vue.prototype.$notify(
          {group: 'notifications',
              title: 'Error ' + res.status,
              text: res.data + ""});
  }
  return res
};

let error_handler = function(res){
    console.error(res);
    Vue.prototype.$notify(
        {group: 'notifications',
            title: 'Error',
            text: res});
    return res;
};

Vue.prototype.$hget = function(url, params){
    return axios.get(url, params)
                .then(error_filter)
                .then(res => res)
                .catch(error_handler);
};

Vue.prototype.$hpost = function(url, params){
    return axios.post(url, params)
                .then(error_filter)
                .then(res => res)
                .catch(error_handler);
};

Vue.prototype.$hdelete = function(url, params){
    return axios.delete(url, params)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

Vue.prototype.$hpatch = function(url, params){
    return axios.patch(url, params)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

Vue.prototype.$hput = function(url, params){
    return axios.put(url, params)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

Vue.prototype.$hrequest = function(config){
    return axios.request(config)
        .then(error_filter)
        .then(res => res)
        .catch(error_handler);
};

//TODO: Debuging error with authentication not passing on file upload on server
//console.log(axios.defaults.baseURL);
//console.log(axios.defaults.headers.common);




