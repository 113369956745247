<template>
    <v-card class="pa-5">
        <v-card-title> Email to: {{ factory.name }}</v-card-title>
        <v-card-text>
            <v-container>
                <v-row>The following users representing the factory will receive the message and a link to log in</v-row>



                <v-row>
                    <template v-for="user of factory.users">
                        <v-col>
                            <v-card class="pa-2">
                                <strong> Email: {{ user.email }} </strong><br>
                                <strong> Phone: {{ user.phone }} </strong><br>
                                <strong> Mobile: {{ user.mobile }} </strong> <br>
                                <strong> Website: {{ user.website }} </strong>
                            </v-card>
                        </v-col>
                    </template>
                </v-row>

                <v-row>
                    <a :href="auth_link" target="_blank"> https://wellstartrading.com{{ auth_link }} </a>
                </v-row>
                <v-row>
                    <v-textarea v-model="factory.message"
                                :counter="500"
                                label="Message">
                    </v-textarea>
                </v-row>

            </v-container>
        </v-card-text>
        <v-card-actions>
            <v-spacer> </v-spacer>
            <v-btn block color="primary"
                   :disabled="!factory.message || factory.message === ''"
                   @click="send_factory_email()">
                Send
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "SendFactoryEmail",
        props:{
            factory: Object,
            invoice_id: String
        },
        data() {
            return {
                auth_link: ''
            }
        },
        created() {
            this.get_auth_link()
        },
        methods:{
            async get_auth_link(){
                this.auth_link = await this
                    .$hget( '/admins/send-factory-email/?id=' + this.factory.id + '&invoice_id=' + this.invoice_id)
                    .then(r => r.data.link);
            },

            async send_factory_email() {
                this.factory.invoice_id = this.invoice_id;

                let res = await this.$hpost('/admins/send-factory-email/', this.factory);

                if(res.status >=200 && res.status < 300){
                    this.$notify({
                        title: 'Success:',
                        message: 'successfully sent email',
                        group: 'notifications',
                        position: 'top right'
                    });
                }
            },
        }
    }
</script>

<style scoped>

</style>
