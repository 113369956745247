import { render, staticRenderFns } from "./Rooms.vue?vue&type=template&id=2b898756&scoped=true"
import script from "./Rooms.vue?vue&type=script&lang=js"
export * from "./Rooms.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b898756",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
installComponents(component, {VList})
