<template>
    <v-container fluid>
        <v-snackbar
                v-model="alert"
                :color="alert_type"
                :multi-line="false"
                :timeout="timeout"
                :vertical="false"
                :top="true"
        >
            {{ alert_message }}
            <v-btn
                    dark
                    text
                    @click="alert = false"
            >
                Close
            </v-btn>
        </v-snackbar>
        <v-content>
            <v-layout row wrap>
                <v-flex xs6 md4 lg3 pa-2 v-for="product in products" :key="product.id"
                        style="margin-bottom:20px;"
                >
                    <v-card>
                        <v-img
                                v-if="product.album && product.album.files.length"
                                @click="navToProduct(product)"
                                class="white--text"
                                height="200px"
                                :src="product.album.files[0].url"
                        >
                        </v-img>
                        <v-card-title>

                            <div>
                                <div class="updated_at">
                                    Last Updated: {{ get_date(product.updated_at) }}
                                </div>
                                <span v-if="can_see_prices === true" class="grey--text"> {{ product.fob_price | currency }} <sup>usd</sup> </span><br>

                                <div style="height:80px; overflow: auto;">
                                    <span class="body-1 font-weight-bold" @click="navToProduct(product)"> {{ product.name }} </span><br>
                                </div>
                            </div>
                        </v-card-title>
                        <v-card-actions style="margin:0;">
                            <v-spacer></v-spacer>
                            <v-btn text color="success"
                                   @click="add_to_cart(product)"
                                   v-if="can_see_prices === true"
                            >
                                <v-icon>
                                    mdi-plus
                                </v-icon> Cart
                            </v-btn>

                            <v-btn text color="success"
                                   :to="'/factory-products/'+product.factory_id">TFP</v-btn>

                        </v-card-actions>
                    </v-card>


                </v-flex>
            </v-layout>
        </v-content>
    </v-container>
</template>

<script>
    import moment from "moment"
    export default {
        name: "store-products",
        props: {
            products: Array,
            user: Object
        },
        data: () => ({
            alert:false,
            alert_message:'',
            alert_type:'success',
            timeout: 6000,
            can_see_prices: false
        }),
        created() {
            if(this.user)
            {
                let groups = this.user.groups;
                let dev = groups.find(o => o.id === 1);
                let add = groups.find(o => o.id === 2);
                let sourcer = groups.find(o => o.id === 3);
                let sourcer_manager = groups.find(o => o.id === 17);
                let sales = groups.find(o => o.id === 9);
                let sales_m = groups.find(o => o.id === 11);
                this.can_see_prices = !!(dev || add || sourcer || sourcer_manager || sales || sales_m);
            } else {
                this.can_see_prices = false;
            }
        },
        methods: {
            navToProduct: function (product){
                // this.$router.push({path:});
                window.open('/product/'+product.erp_item_number, '_blank');
            },
            add_to_cart (product) {
                let default_info = {
                    artwork: 'OEM Packing',
                    battery_included: 'NO',
                    display: 'PDQ',
                    fob_price: product.fob_price,
                    inner_carton_qty: 0,
                    master_carton_qty: 0,
                    packing: 'Color Box',
                    product: product.id,
                    qty: 1000,
                    remarks: 'N/A',
                    target_price: product.fob_price,
                };

                this.$http.post(this.$http.defaults.baseURL + '/store/cart', default_info)
                    .then((response)=>{
                        console.log(response);
                        this.alert = true;
                        this.alert_type = 'success';
                        this.alert_message = 'Successfully added to cart';
                        this.$emit('get-cart-products');
                    })
                    .catch((error)=>{
                        this.alert = true;
                        this.alert_type = 'error';
                        this.alert_message = 'Server Error';
                        console.log(error);
                    });
            },
            get_date(d)
            {
                return moment(String(d)).format('MM/YYYY')
            }

        }
    }
</script>

<style lang="scss">
    .updated_at {
        font-size:10px;
    }
</style>
