
// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex, Vue.$http);

// Store functionality
import actions from './actions'
import getters from './getters'
import mutations from './mutations'
import state from './state'

//Modules
import product_uploads from './modules/product_uploads'


// Create a new store
const store = new Vuex.Store({
  actions: actions,
  getters: getters,
  mutations: mutations,
  state: state,
  modules: {
    product_uploads
  }
});

export default store
