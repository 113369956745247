<template>
    <w-whiteboard
            title="Page not found"
            icon="mdi-alert">
    </w-whiteboard>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>
