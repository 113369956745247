// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import VueAnalytics from 'vue-analytics'
import Meta from 'vue-meta'
import store from '../store'

// Routes
import paths from './paths'
import axios from "axios";
import * as Sentry from "@sentry/browser";
import * as Integrations from "@sentry/integrations";
Vue.use(Router);

// Create a new router
const router = new Router({
  mode: 'history',
  routes: paths,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    if (to.hash) return { selector: to.hash };
    else return { x: 0, y: 0 }
  }
});

//TODO: Define the routes that actually require Authentication
router.beforeEach((to, from, next) => {

    store.hotUpdate('getters');
    // console.log(store.getters.access_token);
    // return false;
    if (localStorage.getItem('access_token') !== null) {
        axios.post(store.state.endpoints.verifyJWT, {
            token: localStorage.getItem('access_token')
        })
            .then((response)=>{
                // success token matches
            })
            .catch((error)=>{
                console.log(error);
                console.log("ERROR using  token but not this one, cancel auth");
                localStorage.removeItem('user');
                store.state.user = undefined;
                store.state.user_groups = undefined;
                store.state.user_groups_names = undefined;
                store.state.user_permissions = undefined;
                store.state.$http.defaults.headers.common["Authorization"] =  undefined;
                localStorage.removeItem('access_token');
                store.state.accessToken = null;
                location.reload();

            });
    }



    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('access_token') == null) {
          next({
            path: '/auth',
            params: { nextUrl: to.fullPath }
          })
        } else {
            /**
             * Check the code is correct
             */
            /**
             * time_set = {
                    refresh_hours: 2,
                    refresh_max:3,
                    max_hours: 6,
                    current_refresh_count:0,
                    timestamp: new Date().getTime()
                };
             * @type {string}
             */
            // let user = JSON.parse(localStorage.getItem('user'));
            let time_set = JSON.parse(localStorage.getItem('storage_time'));
            if(!time_set || typeof time_set == 'undefined')
            {
                localStorage.removeItem("access_token");
                next({
                    path: '/auth',
                    params: { nextUrl: to.fullPath }
                })
            } else {
                let dateString = time_set.timestamp;
                let now = new Date().getTime().toString();

                let diff =(now - dateString) / 1000;
                diff /= (60 * 60);
                let compare_time = Math.abs(Math.round(diff));
                let compare_hours = compare_time;
                if(compare_hours >= time_set.max_hours || time_set.current_refresh_count >= time_set.refresh_max)
                {
                    /**
                     * If less that max hours allowed based on 2h refresh
                     * or it has passed the max number of refreshes 3
                     * TODO: instead of logout show a popup login box
                     */
                    localStorage.removeItem("access_token");
                    next({
                        path: '/auth',
                        params: { nextUrl: to.fullPath }
                    });
                    console.log("Out of time ");
                } else {

                    /**
                     * check compare is less than refresh hour rate
                     */
                    if(compare_hours >= time_set.refresh_hours)
                    {
                        console.log("Token Needs to be refreshed");
                        /**
                         * time has passed the refresh mark so update the token
                         * TODO: add to method in mutations and call it
                         */

                        axios.post(store.state.endpoints.refreshJWT, {
                            token: localStorage.getItem('access_token')
                        })
                            .then((response)=>{
                                let data = response.data;
                                localStorage.setItem('access_token', data.token);
                                store.state.accessToken = data.token;
                                let time_obj = {
                                    refresh_hours: 2,
                                    refresh_max:3,
                                    max_hours: 6,
                                    current_refresh_count:(dateString.current_refresh_count + 1),
                                    timestamp: new Date().getTime()
                                };
                                localStorage.setItem('storage_time', JSON.stringify(time_obj) );
                                localStorage.setItem('user', JSON.stringify(data.user));
                            })
                            .catch((error)=>{
                                console.log(error)
                            })
                    }

                }
            }


            // Step One: Get the user and get the user groups
            if (to.matched.some(record => record.meta.authGroups)) {
                let user = JSON.parse(localStorage.getItem('user'));

                let required_groups = to.matched.slice().reverse().find(r => r.meta && r.meta.authGroups).meta.authGroups;
                let matching_groups = user.groups.filter(arr1Item => required_groups.includes(arr1Item)); //returns array of items in the array
                let access_allowed = false;

                user.groups.forEach(function(n){
                    if(required_groups.find(o => o === n.id))
                    {
                        access_allowed = true;
                    }
                });
                if(access_allowed === true) {
                    next();
                }
                else
                {
                    //todo: add an error with user ID and link to the developer logs
                    console.log("Error access denied");
                    console.log("Group auth: required:[" + required_groups.toString() + "] matching:[" + matching_groups.toString()+"]");
                    next({
                        path: '/errors/401',
                        params: { nextUrl: to.fullPath }
                    })
                }

            } else {
                //if no group is required return to the page
                next()
            }
        }
  } else {
      //No Auth Required
      if(to.path === '/auth/login' && localStorage.getItem('access_token'))
      {
          console.log("Logged In Already Will Redirect to Dashboard");
          next({
              path: '/c-panel',
              params: { nextUrl: to.fullPath }
          });

          return false;
      }

      if(to.path === '/auth/logout' && !localStorage.getItem('access_token'))
      {
          console.log("Logout reached but no user is logged in, will redirect to login");
          next({
              path: '',
              params: { nextUrl: to.fullPath }
          });
      }
    next();
  }
});



Vue.use(Meta);

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
// if (process.env.GOOGLE_ANALYTICS) {
//   Vue.use(VueAnalytics, {
//     id: process.env.GOOGLE_ANALYTICS,
//     router,
//     autoTracking: {
//       page: process.env.NODE_ENV !== 'development'
//     }
//   })
// }

export default router
