<template>
    <!-- Result tracker -->
</template>

<script>

    export default {
        components: {},
        name: "BackendManager",
        props: {
            callback_function: Function,
            url: String,
            type: String,
            count: Number,
            data: Array,
            filter: Object
        },
        watch: {
            'count': function (count) {
                console.log(count);
                if(count > 0)
                {
                    console.log("LOADING RES");
                    this.publish_results();
                }
            },
        },
        methods: {
            publish_results()
            {
                switch(this.type)
                {
                    case 'get':
                        let filter_url = '';
                        let n =0;
                        for(let i in this.filter){
                            if(this.filter[i] !== '')
                            {
                                if(n === 0)
                                {
                                    filter_url += '?';
                                } else {
                                    filter_url += '?';
                                }
                                filter_url += i+'='+this.filter[i];
                                n++;
                            }
                        }
                        this.$hget(
                            this.$http.defaults.baseURL + this.url+filter_url).then((response) => {
                                if(typeof response.data !== 'undefined')
                                {
                                    this.callback_function(response);
                                }
                            });
                        break;
                    case 'post':
                        this.$hpost(
                            this.$http.defaults.baseURL + this.url, this.data).then((response) => {
                            if(typeof response.data !== 'undefined')
                            {
                                this.callback_function(response);
                            }
                        });
                        break;
                    case 'put':
                        this.$hput(
                            this.$http.defaults.baseURL + this.url, this.data).then((response) => {
                            if(typeof response.data !== 'undefined')
                            {
                                this.callback_function(response);
                            }
                        });
                        break;
                    case 'patch':
                        this.$hpatch(
                            this.$http.defaults.baseURL + this.url, this.data).then((response) => {
                            if(typeof response.data !== 'undefined')
                            {
                                this.callback_function(response);
                            }
                        });
                        break;
                    case 'delete':
                        this.$hdelete(
                            this.$http.defaults.baseURL + this.url).then((response) => {
                            if(typeof response.data !== 'undefined')
                            {
                                this.callback_function(response);
                            }
                        });
                        break;
                }
            }
        }
    }
</script>
