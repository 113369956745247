<template>
    <div class="uk-flex uk-flex-column uk-flex-center">
        <div class="uk-card-large uk-card-body uk-text-center">
            <v-icon color="#333333" size="160">{{icon}}</v-icon>
            <h2>{{ title }}</h2>
            <h4> {{ text }}</h4>
            <v-btn v-if="!!go_back_button" @click="$router.go(-1)">Back</v-btn>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Whiteboard",
        props:{
            title: {
                 type: String,
                 default: "Page under construction"
            },
            text: {
                type: String,
                default: "We thank your patience"
            },
            icon: {
                type: String,
                default: "mdi-towing"
            },
            go_back_button:{
                type: Boolean,
                default: true
            }
        }
    }
</script>

<style scoped>

</style>