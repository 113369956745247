// https://vuex.vuejs.org/en/getters.html

export default {
    access_token: (state) => 'jwt '+ state.accessToken,
    user: () => {
        if(!!localStorage.getItem('user'))
            return JSON.parse(localStorage.getItem('user'));
        else return {};
    },
    buffer_user: () =>{
        if(!!localStorage.getItem('_previous_details'))
            return JSON.parse(localStorage.getItem('_previous_details'));
        else return {};
    },
    user_groups: () => {
        if(!!localStorage.getItem('user')){
            let temp = JSON.parse(localStorage.getItem('user'));
            temp = temp.groups;
            return temp.map(x => x.id);
        }
        else return {};
    },
    user_groups_names: () => {
        if(!!localStorage.getItem('user')){
            let temp = JSON.parse(localStorage.getItem('user'));
            temp = temp.groups;
            return temp.map(x => x.name);
        }
        else return {};
    },
    user_permissions: () => {
        if(!!localStorage.getItem('user')){
            let temp = JSON.parse(localStorage.getItem('user'));
            temp = temp.user_permissions;
            return temp.map(x => x.id);
        }
        else return {};
    }


}
