<template>
    <div style="padding:20px;">
        <v-layout row wrap>
            <v-flex xs12 po2>
                <h3>{{ title }}</h3>
                <v-form ref="form">
                    <div v-for="field in ed_fields">
                        <div v-if="field.type === 'text' || field.type === 'number' || field.type === 'email' || field.type === 'password'">
                            <v-text-field
                                    :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                    :name="field.table"
                                    v-validate="'required'"
                                    v-model="field.value"
                                    :rules="field.rules"
                                    :label="field.label"
                                    :type="field.type"
                                    :required="field.required"
                            ></v-text-field>
                        </div>

                        <div v-if="field.type === 'textarea'">
                            <v-textarea
                                    :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                    :name="field.table"
                                    v-validate="'required'"
                                    v-model="field.value"
                                    :rules="field.rules"
                                    :label="field.label"
                                    :required="field.required"
                            ></v-textarea>
                        </div>

                        <div v-if="field.type === 'select'" style="margin:20px 0;">
                            <div v-if="!field.async && !allow_new">
                                <v-select
                                        :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                        :name="field.table"
                                        v-validate="'required'"
                                        v-model="field.value"
                                        :items="field.options"
                                        :item-value="field.track_by"
                                        :item-text="field.show"
                                        :label="field.label"
                                        :required="field.required"
                                        required=""></v-select>
                            </div>

                            <div v-if="field.async === true && !allow_new">
                                <div style="padding:5px 0;" class="wrapper" @mouseover="set_field(field)">
                                    <multiselect
                                            :label="field.show"
                                            v-model="field.value"
                                            :options="field.options"
                                            :multiple="field.multiple"
                                            :searchable="true"
                                            :placeholder="'Select a '+field.label"
                                            :loading="isLoading"
                                            @search-change="asyncFind"
                                            @open="set_field(field)"
                                            @click="set_field(field)"
                                            @hover="set_field(field)"
                                            @close="drop_field"
                                            @remove="remove"
                                            @select="select"
                                            :taggable="true"
                                            :close-on-select="true"
                                            :track-by="field.track_by"
                                            :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                            :name="field.table"

                                    ></multiselect>
                                </div>
                            </div>

                            <div class="allow_new" v-if="field.allow_new">
                                <v-checkbox v-model="allow_new" :label="'Add New ' + field.allow_new_table"> </v-checkbox>
                                <div v-if="allow_new" v-for="f in field.allow_new_fields">
                                    <div v-if="f.type === 'text' || f.type === 'number' || f.type === 'email' || f.type === 'password'">
                                        <v-text-field
                                                :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                                :name="field.table"
                                                v-validate="'required'"
                                                v-model="f.value"
                                                :rules="f.rules"
                                                :label="f.label"
                                                :type="f.type"
                                                :required="f.required"
                                        ></v-text-field>
                                    </div>
                                    <div v-if="f.type === 'textarea'">
                                        <v-textarea
                                                :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                                :name="field.table"
                                                v-validate="'required'"
                                                v-model="f.value"
                                                :rules="f.rules"
                                                :label="f.label"
                                                :required="f.required"
                                        ></v-textarea>
                                    </div>
                                    <div v-if="f.type === 'select'" style="margin:20px 0;">
                                        <div v-if="!f.async">
                                            <v-select
                                                    :class="{ 'is-invalid': submitted && errors.has(field.table) }"
                                                    :name="field.table"
                                                    v-validate="'required'"
                                                    v-model="f.value"
                                                    :items="f.options"
                                                    :item-value="f.track_by"
                                                    :item-text="f.show"
                                                    :label="f.label"
                                                    :required="f.required"
                                                    required=""></v-select>
                                        </div>
                                        <div v-if="f.async === true">
                                            <div style="padding:5px 0;" class="wrapper" @mouseover="set_field(field)">
                                                <multiselect
                                                        :label="f.show"
                                                        v-model="f.value"
                                                        :options="f.options"
                                                        :multiple="f.multiple"
                                                        :searchable="true"
                                                        :placeholder="'Select a '+f.label"
                                                        :loading="isLoading"
                                                        @search-change="asyncFind"
                                                        @open="set_field(field)"
                                                        @click="set_field(field)"
                                                        @hover="set_field(field)"
                                                        @close="drop_field"
                                                        @remove="remove"
                                                        @select="select"
                                                        :taggable="true"
                                                        :close-on-select="true"
                                                        :track-by="f.track_by"

                                                ></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="f.type === 'hidden'">
                                        <input type="hidden" v-model="f.value">
                                    </div>
                                </div>
                                <v-divider color="pink"></v-divider>
                            </div>


                        </div>


                        <div v-if="field.type === 'hidden'">
                            <input type="hidden" v-model="field.value">
                        </div>


                    </div>
                    <v-btn color="success" @click="process_form(ed_fields)">
                        Save
                    </v-btn>
                </v-form>
            </v-flex>
        </v-layout>
    </div>
</template>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>

</style>


<script>
    //TODO: When update return info to parent, rerun _init() if exists and close modal
    import Multi_select from 'vue-multiselect'

    export default {
        name: "edit-data",
        components: {
            Multi_select
        },
        props: {
            title: String,
            db_link: String,
            type: String,
            ed_fields: Array,
        },
        data: () => ({
            processed_data: [],
            notification: false,
            notification_message: '',
            isLoading: false,
            selected_field: {},
            allow_new:false,
            submitted: false
        }),
        created() {
            console.log("Created");
        },
        watch: {
            ed_fields: function(newVal, oldVal) { // watch it
                this.selected_field = {}
            }
        },
        methods: {
            set_field(field) {
                if (this.selected_field.table !== field.table) {
                    console.log("field_set");
                    this.selected_field = field;
                }
            },

            drop_field() {
                // this.selected_field = {}
            },

            select(selectedOption, id) {
                this.selected_field.value = this.selected_field.options;
                this.isLoading = false;
            },

            remove(option, id) {
                console.log(this.selected_field);

                if (!!this.selected_field.value) {
                    console.log(this.selected_field.value);
                    let removeIndex = this.selected_field.value.map(item => item.id).indexOf(option.id);
                    console.log("removed");
                    this.selected_field.value.splice(removeIndex, 1);
                }
            },

            asyncFind(query) {
                this.isLoading = true;
                if (!!query) {
                    this.$http.get(this.selected_field.async_url + query)
                        .then((response) => {
                            this.selected_field.options = response.data;
                            console.log(this.selected_field);
                            console.log(response);
                        })
                        .catch((error) => {
                            console.log("Error");
                            console.log(error);
                            this.isLoading = false;
                        });
                }

            },

            async process_form(fields_to_process) {
                this.submitted = true;

                if (!await this.$validator.validate()){
                    console.log("invalid");
                    this.notification = true;
                    this.notification_message = 'Please fill in all required inputs';
                    return;
                }

                let parsed_data = {};

                for(let value of fields_to_process){

                    if (!!value.async) {
                        value.async = true;
                        parsed_data[value.table] = value.multiple? value.value.map(item => item.id) : value.value.id;
                    } else {
                        value.async = false;
                        parsed_data[value.table] = value.value;
                    }

                    /** -- Sub fields for new item -- **/
                    if(!!value.allow_new && !! this.allow_new) {

                        parsed_data[value.allow_new_table] = {};

                        for(let sub_value of value.allow_new_fields){
                            if(!!sub_value.async){
                                sub_value.sync = true;
                                parsed_data[value.allow_new_table][sub_value.table] = (!!sub_value.multiple)? sub_value.value.map(item => item.id): sub_value.value.id;
                            }else{
                                sub_value.sync = false;
                                parsed_data[value.allow_new_table][sub_value.table] = sub_value.value;
                            }
                        }
                    }
                }

                let method = (this.type === 'update') ? this.$http.patch : this.$http.post;

                method(parsed_data).then( response => {
                    console.log(response);
                    if(!!response.data.success) this.notify( 'Successfully Updated');
                    else this.notify( !!response.data.details ? 'General error' : response.data.details);
                }).catch(error => {
                    this.notify('Error: Please contact administrator');
                    console.log("Error");
                    console.log(error);
                })
            },

            notify(notification_message){
                this.notification = true;
                this.notification_message = notification_message;
            },
        }

    }
</script>

