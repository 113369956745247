<template>
    <v-layout justify-center row>

        <v-avatar class="px-1"
                :size="icon_size">
            <img @click="load_homepage()" src="/res/wst/wellstar-icon_b-white.svg" alt="avatar">
        </v-avatar>


        <h3 v-if="!$store.state.responsive" class="white--text ml-4 mt-2">Wellstar Trading Ltd</h3>

        <v-spacer></v-spacer>

        <div class="top-menu pt-1">
            <v-btn
                    small
                    text to="/about" class="my-1 white--text">
                <span>About Us</span>
            </v-btn>

            <v-btn
                    small
                    text to="/our-partners" class="my-1 white--text">
                <span>Our Partners</span>
            </v-btn>

            <v-btn
                    small
                    text to="/contact-us" class="my-1 white--text">
                <span>Contact</span>
            </v-btn>

            <w-login-widget v-if="!$store.state.responsive" color="white" :class_string="'my-1'"></w-login-widget>

            <v-menu v-if="$store.state.responsive">
                <template slot="activator" slot-scope="{ on }">
                    <v-btn text color="white" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                </template>
                <v-list>
                    <v-list-item v-if="!!Object.keys(user).length">
                        <v-btn v-if="!!Object.keys(user).length" block text to="/c-panel" class="my-1">
                            <v-icon>mdi-settings</v-icon>
                            <span>C-Panel</span>
                        </v-btn>
                    </v-list-item>

                    <v-list-item v-if="!!Object.keys(user).length">
                        <v-btn v-if="!!Object.keys(user).length"
                               block text to="/wishlist" class="my-1">
                            <v-icon>mdi-format-list-bulleted</v-icon>
                            <span>WishList</span>
                        </v-btn>
                    </v-list-item>

                    <v-list-item v-if="!!Object.keys(user).length">
                        <v-btn v-if="!!Object.keys(user).length" block text to="/messenger" class="my-1">
                            <v-icon>mdi-message</v-icon>
                            <span>Chat</span>
                        </v-btn>
                    </v-list-item>

                    <v-list-item>
                        <w-login-widget class="my-1"></w-login-widget>
                    </v-list-item>
                </v-list>
            </v-menu>
        </div>
    </v-layout>
</template>

<script>
    export default {
        name: "Toolbar",
        props: {
            user: Object,
            icon_size: Number
        },
        data: () => ({
            title: "Control Panel"
        }),
        methods: {
            load_homepage()
            {
                this.$router.push({path: '/'});
            }
        }
    }
</script>

<style scoped>

</style>
