<template>
    <div>
        <v-list two-line>
            <template v-for="(room, index) in rooms">
                <v-list-tile avatar
                        :key="room.title"
                        @click="">
                    <v-list-tile-avatar>
                        <img :src="room.avatar" alt="x">
                    </v-list-tile-avatar>

                    <v-list-tile-content>
                        <v-list-tile-title v-html="room.name | room.members.map(item => item.name).join(', ')"></v-list-tile-title>
                        <v-list-tile-sub-title v-if="!room.name" v-html="room.members.map(item => item.name).join(', ')"></v-list-tile-sub-title>
                    </v-list-tile-content>
                </v-list-tile>
            </template>
        </v-list>
    </div>
</template>

<script>
    export default {
        name: "Rooms",
        props: {
            rooms: Array
        },
        data: () => ({

        }),
    }
</script>

<style scoped>

</style>