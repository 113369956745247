<template>
  <v-footer id="core-footer" absolute>
    <v-layout row>
      <v-flex shrink>
        <router-link to="/" class="tertiary--text footer-links">
          Store
        </router-link>

        <router-link to="/sitemap" class="tertiary--text footer-links">
          Sitemap
        </router-link>

        <router-link to="/about" class="tertiary--text footer-links">
          About us
        </router-link>

        <a href="https://linkedin.com/company/wellstartrading/"
           target="_blank"
           class="tertiary--text footer-links">
          LinkedIn
        </a>

      </v-flex>

      <v-spacer> </v-spacer>

      <v-flex shrink>
        <span class="font-weight-light copyright" style="font-size: 70%;">
          &copy; {{(new Date()).getFullYear()}}
          <a href="https://info.wellstartrading.com/" target="_blank">Wellstar International Trading Ltd.</a>
        </span>
      </v-flex>

    </v-layout>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    showing_extra: false,

   tabs: null,
   text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do ' +
           'eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, ' +
           'quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',


    links: [
      { name: 'Sitemap', Link: 'https://info.wellstartrading.com' },
      { name: 'About Us', Link: 'https://info.wellstartrading.com' },
      { name: 'LinkedIn', Link: 'https://www.linkedin.com/company/wellstarinternationaltradingltd/', Logo: 'mdi-linkedin' }
    ]
  })
}
</script>

<style>

</style>
