<template>
    <v-navigation-drawer app persistent dark
                         id="app-drawer"
                         ref="app_drawer"
                         class="py-5 px-1 my-nav"
                         :value="drawer_showing"
                         mobile-break-point="991"
                         color="blue-grey darken-4">

        <v-expansion-panels accordion
                            :mandatory="true"
                            :floating="true">
            <v-expansion-panel v-for="group_links of user_group_links"
                               :key="group_links.title">

                <v-expansion-panel-header>{{group_links.title}}</v-expansion-panel-header>

                <v-expansion-panel-content>
                    <v-list dense>
                        <v-list-item-group>
                            <v-list-item
                                    v-for="(link, i) in group_links.links"
                                    :key="i"
                                    @click="load_link(link)">
                                <v-list-item-icon>
                                    <v-icon v-text="link.icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title v-text="link.text"></v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </v-navigation-drawer>

</template>

<script>
    export default {
        data: () => ({
            user_groups: [],
            user_group_links:[],
            available_links: [
                {
                    title: 'Admin',
                    access: [2],
                    links: [
                        {
                            to: '/',
                            icon: 'mdi-truck-delivery',
                            text: 'Store'
                        },
                        {
                            to: '/c-panel/users',
                            icon: 'mdi-account-multiple',
                            text: 'Users'
                        },
                        {
                            to: '/c-panel/orders',
                            icon: 'mdi-clipboard-text',
                            text: 'Orders'
                        },
                        {
                            to: '/c-panel/organisations',
                            icon: 'mdi-briefcase',
                            text: 'Organisations'
                        },
                        {
                            to: '/c-panel/products',
                            icon: 'mdi-package-variant',
                            text: 'Products'
                        },
                        {
                            to: '/c-panel/categories',
                            icon: 'mdi-notebook',
                            text: 'Categories'
                        },
                        {
                            to: '/c-panel/presentations',
                            icon: 'mdi-projector-screen',
                            text: 'Presentations'
                        },
                        {
                            to: '/c-panel/ipad-fair',
                            icon: 'mdi-projector-screen',
                            text: 'IPad Fair'
                        },
                    ]
                },
                {
                    title: 'Sys Admin',
                    access: [1],
                    links: [
                        {
                            to: '/c-panel/developer/work-times',
                            icon: 'mdi-calendar-clock',
                            text: 'Work Times'
                        },
                        {
                            to: '/c-panel/developer/tasks',
                            icon: 'mdi-playlist-check',
                            text: 'Tasks'
                        },
                        {
                            to: '/c-panel/developer/logs',
                            icon: 'mdi-bug',
                            text: 'Logs'
                        },
                        {
                            to: '/c-panel/developer/scripts',
                            icon: 'mdi-script',
                            text: 'Scripts'
                        },

                    ],
                },
                {
                    title: 'Sourcer',
                    access: [3, 17],
                    links: [
                        {
                            to: '/',
                            icon: 'mdi-truck-delivery',
                            text: 'Store'
                        },
                        {
                            to: '/c-panel/sourcer/factories',
                            icon: 'mdi-factory',
                            text: 'Factories'
                        },
                        {
                            to: '/c-panel/sourcer/orders',
                            icon: 'mdi-clipboard-text',
                            text: 'Orders'
                        },
                        {
                            to: '/c-panel/sourcer/products',
                            icon: 'mdi-package-variant',
                            text: 'Products'
                        },
                        {
                            to: '/c-panel/sourcer/items-to-source',
                            icon: 'mdi-view-dashboard',
                            text: 'Items To Source'
                        },
                    ]
                },
                {
                    title: 'Sales',
                    access: [4, 7, 8, 9],
                    links: [
                        {
                            to: '/c-panel/sales/departments',
                            icon: 'mdi-clipboard-text',
                            text: 'Store Departments'
                        },
                        {
                            to: '/',
                            icon: 'mdi-truck-delivery',
                            text: 'Store'
                        },
                        {
                            to: '/c-panel/sales/orders',
                            icon: 'mdi-clipboard-text',
                            text: 'Orders'
                        },
                    ],
                },
                {
                    title: 'Sales Manager',
                    access: [11],
                    links: [
                        {
                            to: '/',
                            icon: 'mdi-truck-delivery',
                            text: 'Store'
                        },
                        {
                            to: '/c-panel/sales-manager/users',
                            icon: 'mdi-clipboard-text',
                            text: 'Users'
                        },
                        {
                            to: '/c-panel/sales-manager/orders',
                            icon: 'mdi-clipboard-text',
                            text: 'Orders'
                        },
                    ],
                },
                {
                    title: 'Logistics',
                    access: [15],
                    links: [
                        {
                            to: '/c-panel/logistics/orders',
                            icon: 'mdi-clipboard-text',
                            text: 'Orders'
                        },
                        {
                            to: '/c-panel/logistics/labels',
                            icon: 'mdi-view-dashboard',
                            text: 'Design Labels'
                        },
                        {
                            to: '/c-panel/logistics/boxes',
                            icon: 'mdi-view-dashboard',
                            text: 'Design Boxes'
                        },
                    ],
                },
                {
                    title: 'Organisation',
                    access: [6],
                    links: [
                        {
                            to: '/c-panel/org/factories',
                            icon: 'mdi-factory',
                            text: 'Factories'
                        },
                        {
                            to: '/c-panel/org/users',
                            icon: 'mdi-users',
                            text: 'Users'
                        },
                        {
                            to: '/c-panel/org/products',
                            icon: 'mdi-package-variant',
                            text: 'Products'
                        },
                        {
                            to: '/c-panel/org/orders',
                            icon: 'mdi-clipboard-text',
                            text: 'Orders'
                        },
                    ],
                },
                {
                    title: 'Partner',
                    access: [13],
                    links: [
                        {
                            to: '/c-panel/partners/orders',
                            icon: 'mdi-clipboard-text',
                            text: 'Orders'
                        },
                        {
                            to: '/c-panel/partners/users',
                            icon: 'mdi-account-multiple',
                            text: 'Users'
                        },
                    ],
                },
                {
                    title: 'Business Intel',
                    access: [19],
                    links: [
                        {
                            to: '/c-panel/business-intel/data',
                            icon: 'mdi-square-root',
                            text: 'Business Intel'
                        },
                        {
                            to: '/c-panel/business-intel/pre-pis',
                            icon: 'mdi-format-list-bulleted',
                            text: 'Pre PI\'s'
                        },
                        {
                            to: '/c-panel/business-intel/stock',
                            icon: 'mdi-trending-up',
                            text: 'Stock Counts'
                        },
                        {
                            to: '/c-panel/business-intel/barcodes',
                            icon: 'mdi-barcode',
                            text: 'Barcodes'
                        }
                    ]
                },
                {
                    title: 'Audit',
                    access: [18],
                    links: [
                        {
                            to: '/c-panel/audits/orders',
                            icon: 'mdi-clipboard-text',
                            text: 'Orders'
                        },

                    ],
                },
                {
                    title: 'Category Manager',
                    access: [1],
                    links: [
                        {
                            to: '/c-panel/category-manager/categories',
                            icon: 'mdi-clipboard-text',
                            text: 'Categories'
                        },
                        {
                            to: '/c-panel/category-manager/tags',
                            icon: 'mdi-clipboard-text',
                            text: 'Tags'
                        },
                        {
                            to: '/c-panel/category-manager/products',
                            icon: 'mdi-clipboard-text',
                            text: 'Products'
                        },

                    ],
                },

            ],
        }),
        props:{
            drawer: {
                type: Boolean,
                default: false
            },
        },
        computed:{
            drawer_showing :{
                get: function () { return this.drawer },
                set: () => this.$emit('toggle-drawer')
            }
        },
        created () {
            if(this.$store.getters.user && this.$store.getters.user_groups)
                this.user_groups = this.$store.getters.user_groups;

            // This can be async
            for (let link of this.available_links)
                for (let group of this.user_groups)
                    if (link.access.includes(group)) {
                        this.user_group_links.push(link);
                        break;
                    }

        },
        methods: {
            load_link(l)
            {

                if(this.$route.path === l.to)
                {
                    this.$router.push({path: l.to + '?val='+ Math.floor(Math.random() * (10 - 1 + 1)) + 1});
                } else {
                    this.$router.push({path: l.to});
                }
            }
        }
    }
</script>

<style scoped lang="scss">

    #app-drawer{
        box-shadow: -1px 0 4px #000000BB inset;
        max-height: 100vh;
        height:100vh;
        width:260px;
        overflow: no-content;

        & .v-expansion-panel{
            box-shadow: none;
            background-color: transparent;

            &::before{
                box-shadow: none;
            }

            &::after{
                box-shadow: none;
            }

        }


    }


</style>
