<template>
<div>

</div>
</template>

<script>

    export default {
        name: "Categories",

        methods: {

        },
        created () {

        },
        mounted () {

        },
    }
</script>

<style type="text/scss" scoped>

</style>