<template>
  <v-app light v-cloak>
      <v-content>
          <div id="core-view" style="padding-bottom: 100px;">
              <v-fade-transition mode="out-in">
                  <router-view />
              </v-fade-transition>
          </div>
          <core-footer></core-footer>
          <modals-container></modals-container>
          <notifications group="notifications" :position="'top right'"></notifications>
      </v-content>
  </v-app>
</template>

<script>
    export default {
        mounted () {
            this.onResponsiveInverted();
            window.addEventListener('resize', this.onResponsiveInverted)
        },
        beforeDestroy () {
            window.removeEventListener('resize', this.onResponsiveInverted)
        },
        methods: {
            onResponsiveInverted () {
                this.$store.dispatch('setResponsive', window.innerWidth);
            }
        }

    }
</script>

<style lang="scss">
    @import 'styles/imports/uikit_import';
    @import 'styles/general/index';
</style>
