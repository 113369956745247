<template>
    <div>
        <v-container v-if="!!loading">
            <v-row style="min-height:60vh"  align="center" justify="center">
                <v-progress-circular
                        :size="200"
                        color="primary"
                        indeterminate
                ></v-progress-circular>
            </v-row>
        </v-container>

        <v-container v-if="!loading">
            <v-row>
                <v-col :cols="12" :sm="6" class="py-1">
                    <v-select return-object item-text="name"
                            v-model="selected_parent_category"
                            :items="categories_parents"
                            @change="selected_child_category = undefined"
                            label="Category"
                              required
                              :error-messages="checkCategory"
                              @input="$v.selected_parent_category.$touch()"
                              @blur="$v.selected_parent_category.$touch()"
                    >
                    </v-select>
                </v-col>
                <v-col :cols="12" :sm="6" class="py-1">
                    <v-select return-object item-text="name"
                            v-model="selected_child_category"
                            :items="select_children_categories"
                            label="Sub Category"
                              required
                              :error-messages="checkSubCat"
                              @input="$v.selected_child_category.$touch()"
                              @blur="$v.selected_child_category.$touch()"
                    >
                    </v-select>
                </v-col>

                <v-col :cols="12" class="py-1">
                    <v-row class="mx-0" :align="'center'">
                        <v-autocomplete chips multiple
                                        class="mx-1"
                                        color="primary"
                                        v-model="product.product_tags"
                                        :items="default_tags"
                                        :disabled="isUpdating"
                                        :menu-props='{"closeOnClick":false, "closeOnContentClick":true}'
                                        label="Tags"
                                        item-text="name"
                                        item-value="id">
                            <template v-slot:selection="data">
                                <v-chip
                                        v-bind="data.attrs"
                                        :input-value="data.selected"
                                        close
                                        @click="data.select"
                                        @click:close="remove_tag(data.item)"
                                >
                                    {{ data.item.name }}
                                </v-chip>
                            </template>
                            <template v-slot:item="data">
                                <template v-if="typeof data.item !== 'object'">
                                    <v-list-item-content v-text="data.item"></v-list-item-content>
                                </template>
                                <template v-else>
                                    <v-list-item-content>
                                        <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </template>
                        </v-autocomplete>

                        <v-btn fab small outlined
                               color="primary"
                               v-tooltip="'Add Tag'"
                               class="mx-1"
                               @click="new_tag()"
                        >
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>

            <v-divider class="my-4"></v-divider>

            <v-row>
                <v-col :cols="12" :sm="3" class="py-1">
                    <v-text-field
                            v-model="product.item_number"
                            :counter="200"
                            required
                            :error-messages="checkItemNumber"
                            @input="$v.product.item_number.$touch()"
                            @blur="$v.product.item_number.$touch()"
                            label="Item Number"

                    ></v-text-field>
                </v-col>
                <v-col :cols="12" :sm="9" class="py-1">
                    <v-text-field
                            v-model="product.name"
                            :counter="200"
                            label="Name"
                            required
                            :error-messages="checkName"
                            @input="$v.product.name.$touch()"
                            @blur="$v.product.name.$touch()"

                    ></v-text-field>
                </v-col>
            </v-row>

            <h5>Product album:</h5>

            <v-row class="my-3" v-if="!!product.album">
                <template v-if="!!product.album.files">
                    <v-col v-for="file of product.album.files"
                           :key="file.id"
                           class="pa-0 my-2">
                        <v-img contain
                               class="mx-2"
                               style=" background-color: #333333"
                               height="100" width="100"
                               :src="file.url"
                               :alt="product.id + '-' + file.id">
                        </v-img>
                    </v-col>
                </template>
            </v-row>

            <v-divider></v-divider>

            <v-row>

                <v-col :cols="12" class="py-1">
                    <v-textarea auto-grow counter :rows="2"
                                v-for="(field_obj, field_key) of
                                        {
                                        'description': {label: 'Description', error_message: checkDesc, error_check: $v.product.description.$touch()},
                                        'packaging': {label: 'Packaging', error_message: checkPacking, error_check: $v.product.packaging.$touch()},
                                        'material': {label: 'Material', error_message: checkMaterial, error_check: $v.product.material.$touch()}
                                        }"
                                :key="field_key"
                                :error-messages="field_obj['error_message']"
                                @input="field_obj['error_check']"
                                required
                                v-model="product[field_key]"
                                :label="field_obj['label']"
                    ></v-textarea>
                </v-col>

                <v-col :cols="12" :sm='4' :md="3" :lg="2" class="py-1"
                       v-for="(field_obj, field_key) of
                                        {
                                        'fob_price': {'label': 'FOB', 'type': 'number', error_message: checkFobPrice, error_check: $v.product.fob_price.$touch() },
                                        'qty_moq': {'label': 'MOQ', 'type': 'number', error_message: checkMOQ, error_check: $v.product.qty_moq.$touch() },
                                        'qty_20': {'label': '20\' Carton', 'type': 'number', error_message: checkQty20, error_check: $v.product.qty_20.$touch() },
                                        'qty_40': {'label': '40\' Carton', 'type': 'number',  error_message: checkQty40, error_check: $v.product.qty_40.$touch() },
                                        'qty_40hq': {'label': '40hq\' Carton', 'type': 'number',  error_message: checkQty40hq, error_check: $v.product.qty_40hq.$touch() },
                                        'unit': {'label': 'Unit', 'type': 'text', error_message: checkUnit, error_check: $v.product.unit.$touch()},

                                        'item_size_width': {'label': 'Item Width', 'type': 'number',  error_message: checkItemWidth, error_check: $v.product.item_size_width.$touch() },
                                        'item_size_height': {'label': 'Item Height', 'type': 'number',  error_message: checkItemHeight, error_check: $v.product.item_size_height.$touch() },
                                        'item_size_depth': {'label': 'Item Depth', 'type': 'number',  error_message: checkItemDepth, error_check: $v.product.item_size_depth.$touch() },

                                        'carton_measures_width': {'label': 'Carton Width', 'type': 'number',  error_message: checkCtnWidth, error_check: $v.product.carton_measures_width.$touch() },
                                        'carton_measures_height': {'label': 'Carton Height', 'type': 'number',  error_message: checkCtnHeight, error_check: $v.product.carton_measures_height.$touch() },
                                        'carton_measures_depth': {'label': 'Carton Depth', 'type': 'number',  error_message: checkCtnDepth, error_check: $v.product.carton_measures_depth.$touch() },

                                        'gross_w': {'label': 'Gross Weight', 'type': 'text',  error_message: checkGrossWeight, error_check: $v.product.gross_w.$touch() },
                                        'net_weight': {'label': 'Net Weight', 'type': 'text',  error_message: checkNetWeight, error_check: $v.product.net_weight.$touch() },
                                        'pieces_per_master_carton': {'label': 'Pieces Per Carton', 'type': 'number',  error_message: checkPcsPerCarton, error_check: $v.product.pieces_per_master_carton.$touch() },
                                        }"
                       :key="field_key">
                    <v-text-field
                            v-model="product[field_key]"
                            :label="field_obj['label']"
                            :type="field_obj['type']"
                            :error-messages="field_obj['error_message']"
                            @input="field_obj['error_check']"
                            required
                    ></v-text-field>
                </v-col>



            </v-row>

            <v-btn block color="primary"
                   @click="save()">
                Save changes
            </v-btn>

            <v-dialog v-model="tag_dialog" width="500">
                <v-card>
                    <v-card-title> New Tag:  {{ tag.name }}</v-card-title>

                    <v-card-text>
                        <v-text-field
                                class="mx-2"
                              v-model="tag.name"
                              :counter="200"
                              label="Name"
                            ></v-text-field>
                    </v-card-text>
                    <v-divider class="mt-1 mb-0"></v-divider>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn block text
                               color="primary"
                               @click="save_tag(tag)">
                              Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-container>
    </div>
</template>

<script>
    import {validationMixin} from 'vuelidate'
    import {required, maxLength, minLength, email, numeric} from 'vuelidate/lib/validators'

    export default {
        name: 'EditProduct',
        mixins: [validationMixin],

        validations: {
            selected_parent_category: {
                required
            },
            selected_child_category: {
                required
            },
            product: {
                qty_moq: {
                    required,
                    numeric
                },
                fob_price: {
                    required,
                },
                name: {
                    required,
                    maxLength: maxLength(200)
                },
                packaging: {
                    required
                },
                material: {
                    required
                },
                description: {
                    required
                },
                item_number: {
                    required,
                    maxLength: maxLength(200)
                },
                qty_20: {
                    required,
                    numeric
                },
                qty_40: {
                    required,
                    numeric
                },
                qty_40hq: {
                    required,
                    numeric
                },
                unit: {
                    required
                },
                item_size_width: {
                    required,
                },
                item_size_height: {
                    required,
                },
                item_size_depth: {
                    required,
                },
                carton_measures_width: {
                    required,
                },
                carton_measures_height: {
                    required,
                },
                carton_measures_depth: {
                    required,
                },
                gross_w: {
                    required,
                },
                net_weight: {
                    required,
                },
                pieces_per_master_carton: {
                    required,
                }

            }
        },
        props: {
            product_id: {
                type: String,
                default: 0
            },
            auth_type: {
                type: Number,
                default: 2
            },
        },
        components: {},
        data() {
            return {
                loading: true,

                product: {},
                categories_parents:[],
                categories_children:[],
                categories_options_children:[],

                selected_category: undefined,
                selected_parent_category: undefined,
                selected_child_category: undefined,

                isUpdating:false,
                default_tags: [],
                default_tag: {
                    name: ''
                },
                tag: {
                    name: ''
                },
                tag_dialog:false,
            }
        },
        watch: {
            isUpdating (val) {
                if (val) {
                    setTimeout(() => (this.isUpdating = false), 3000)
                }
            }
        },
        computed: {
            checkSubCat() {
                const errors = [];
                if (!this.$v.selected_child_category.$dirty) return errors;
                !this.$v.selected_child_category.required && errors.push('selected_child_category is required.');
                return errors;
            },
            checkCategory() {
                const errors = [];
                if (!this.$v.selected_parent_category.$dirty) return errors;
                !this.$v.selected_parent_category.required && errors.push('Category is required.');
                return errors;
            },
            checkName() {
                const errors = [];
                if (!this.$v.product.name.$dirty) return errors;
                !this.$v.product.name.maxLength && errors.push('product.name must be at most 200 characters long');
                !this.$v.product.name.required && errors.push('product.name is required.');
                return errors;
            },
            checkDesc() {
                const errors = [];
                if (!this.$v.product.description.$dirty) return errors;
                !this.$v.product.description.required && errors.push('product.description is required.');
                return errors;
            },
            checkItemNumber() {
                const errors = [];
                if (!this.$v.product.item_number.$dirty) return errors;
                !this.$v.product.item_number.maxLength && errors.push('product.item_number must be at most 200 characters long');
                !this.$v.product.item_number.required && errors.push('product.item_number is required.');
                return errors;
            },
            checkPacking() {
                const errors = [];
                if (!this.$v.product.packaging.$dirty) return errors;
                !this.$v.product.packaging.required && errors.push('product.packing is required.');
                return errors;
            },
            checkMaterial() {
                const errors = [];
                if (!this.$v.product.material.$dirty) return errors;
                !this.$v.product.material.required && errors.push('product.material is required.');
                return errors;
            },
            checkMOQ() {
                const errors = [];
                if (!this.$v.product.qty_moq.$dirty) return errors;
                !this.$v.product.qty_moq.numeric && errors.push('product.moq must be a number value');
                !this.$v.product.qty_moq.required && errors.push('product.moq is required.');
                return errors;
            },
            checkFobPrice() {
                const errors = [];
                if (!this.$v.product.fob_price.$dirty) return errors;
                !this.$v.product.fob_price.required && errors.push('product.fob_price is required.');
                return errors;
            },
            checkQty20() {
                const errors = [];
                if (!this.$v.product.qty_20.$dirty) return errors;
                !this.$v.product.qty_20.required && errors.push('product.qty_20 is required.');
                !this.$v.product.qty_20.numeric && errors.push('product.qty_20 must be a valid number.');
                return errors;
            },
            checkQty40() {
                const errors = [];
                if (!this.$v.product.qty_40.$dirty) return errors;
                !this.$v.product.qty_40.required && errors.push('product.qty_20 is required.');
                !this.$v.product.qty_40.numeric && errors.push('product.qty_40 must be a valid number.');
                return errors;
            },
            checkQty40hq() {
                const errors = [];
                if (!this.$v.product.qty_40hq.$dirty) return errors;
                !this.$v.product.qty_40hq.required && errors.push('product.qty_40hq is required.');
                !this.$v.product.qty_40hq.numeric && errors.push('product.qty_40hq must be a valid number.');
                return errors;
            },
            checkUnit() {
                const errors = [];
                if (!this.$v.product.unit.$dirty) return errors;
                !this.$v.product.unit.required && errors.push('product.qty_40hq is required.');
                return errors;
            },
            checkItemWidth() {
                const errors = [];
                if (!this.$v.product.item_size_width.$dirty) return errors;
                !this.$v.product.item_size_width.required && errors.push('product.item_size_width is required.');
                return errors;
            },
            checkItemHeight() {
                const errors = [];
                if (!this.$v.product.item_size_height.$dirty) return errors;
                !this.$v.product.item_size_height.required && errors.push('product.item_size_height is required.');
                return errors;
            },
            checkItemDepth() {
                const errors = [];
                if (!this.$v.product.item_size_depth.$dirty) return errors;
                !this.$v.product.item_size_depth.required && errors.push('product.item_size_depth is required.');
                return errors;
            },
            checkCtnWidth() {
                const errors = [];
                if (!this.$v.product.carton_measures_width.$dirty) return errors;
                !this.$v.product.carton_measures_width.required && errors.push('product.carton_measures_width is required.');
                return errors;
            },
            checkCtnHeight() {
                const errors = [];
                if (!this.$v.product.carton_measures_height.$dirty) return errors;
                !this.$v.product.carton_measures_height.required && errors.push('product.carton_measures_height is required.');
                return errors;
            },
            checkCtnDepth() {
                const errors = [];
                if (!this.$v.product.carton_measures_depth.$dirty) return errors;
                !this.$v.product.carton_measures_depth.required && errors.push('product.carton_measures_depth is required.');
                return errors;
            },
            checkGrossWeight() {
                const errors = [];
                if (!this.$v.product.gross_w.$dirty) return errors;
                !this.$v.product.gross_w.required && errors.push('product.carton_measures_depth is required.');
                return errors;
            },
            checkNetWeight() {
                const errors = [];
                if (!this.$v.product.net_weight.$dirty) return errors;
                !this.$v.product.net_weight.required && errors.push('product.carton_measures_depth is required.');
                return errors;
            },
            checkPcsPerCarton() {
                const errors = [];
                if (!this.$v.product.pieces_per_master_carton.$dirty) return errors;
                !this.$v.product.pieces_per_master_carton.required && errors.push('product.pieces_per_master_carton is required.');
                return errors;
            },
            select_children_categories: function(){
                console.log("Parent category selection change:", this.selected_parent_category);
                if(this.selected_parent_category)
                {
                    return this.categories_children.filter(cat => cat.parent === this.selected_parent_category.id);
                }
            },
            select_child_category: function(){
                let res = this.categories_children.find(cat => cat.id === this.product.category_id);
                return (!!res)? res: undefined;
            },
            select_parent_category: function(){
                if(!this.selected_child_category) return undefined;
                let res = this.categories_parents.find(cat => cat.id === this.selected_child_category.parent);
                return (!!res)? res: undefined;
            },
            link: function(){
                return ([1, 2, 3, 15].includes(this.auth_type)? '/admins/': '/factories/') + 'products/' + this.product_id + '/';
            }
        },
        created() {
            this.init();
        },
        methods: {
            async init(){
                this.load_product().then(product => {
                    console.log(product);

                    Promise.all([
                        this.load_categories_parents(),
                        this.load_categories_children(),
                        this.load_tags().then(response => {
                            console.log("TAGs");
                            this.default_tags = response.data;
                            console.log(response.data);
                        })
                    ])
                    .then( _ => {
                        this.selected_category = undefined;
                        if(!!this.product.category_id){
                            let res = this.select_child_category;
                            if(!!res){
                                this.selected_category = res;
                                this.selected_child_category = res;
                                this.selected_parent_category = this.select_parent_category;
                            }else{
                                res = this.select_parent_category;
                                if(!!res) this.selected_category = this.selected_parent_category = res;
                            }
                        }
                    })
                    .then( _ => this.loading = false);
                });
            },
            generate_slug() {
                let name = this.product.name;
                return name.replace(' ', '-').toLowerCase().replace(/\W/g, '');
            },
            load_product() {
                return this.$hget(this.link)
                    .then(response => response.data)
                    .then(product => this.product = product);
            },

            load_categories_parents(){
                return this.$hget('/admins/product/categories/parents')
                        .then(response => response.data)
                        .then(categories => {
                            //console.log(categories);
                            this.categories_parents = categories;
                            return categories;
                        })
            },

            load_categories_children(){
                return this.$hget('/admins/product/categories/children')
                    .then(response => response.data)
                    .then(categories => {
                        //console.log(categories);
                        this.categories_children = categories;
                        return categories;
                    })
            },

            async load_tags(){
                return this.$hget('/admins/default-tags/');
            },

            save_tag(tag){
                this.$hpost('/admins/product-tags/', {
                    name:tag.name,
                    category_id: this.product.category_id,
                    product_id: this.product.id,
                }).then((response) => {
                    tag.id = response.data.id;
                    this.default_tags.push(tag);
                    this.product.product_tags.push(tag);
                    this.tag_dialog = false;
                    this.$notify({
                        title: 'Success:',
                        text: 'Successfully added new tag',
                        group: 'notifications',
                        position: 'top right'
                    });
                })
            },

            new_tag(){
                this.tag = this.default_tag;
                this.tag_dialog = true;
            },

            remove_tag(tag){
                let index = this.product.product_tags.map(function (t) { return t.id; }).indexOf(tag.id);

                if(!index || index === -1)
                    index = this.product.product_tags.map(function (t) { return t; }).indexOf(tag.id);

                if(index > 0){
                    this.$hdelete('/admins/product-tags/'+tag.id+'/?product_id='+this.product.id)
                        .then( _ => {
                            if (index >= 0) this.product.product_tags.splice(index, 1)
                        })
                }
            },

            save() {
                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.$notify({
                        title: 'Error:',
                        text: 'All required fields must pass validation.',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return false;
                }
                console.log("SAVE TEST");
                let slug = this.generate_slug();
                this.product.slug = slug;
                console.log(this.product);
                let tags = [];

                if (!!this.product.product_tags)
                    this.product.product_tags.forEach(function(t){
                       if(typeof t.id !== 'undefined')
                           tags.push(t.id);
                       else
                           tags.push(t)
                    });

                if(typeof this.selected_parent_category === 'undefined')
                {
                    this.$notify({
                        title: 'Error:',
                        text: 'You musts elect at least one category!',
                        group: 'notifications',
                        position: 'top right'
                    });
                    return false;
                }
                this.product.product_tags = tags;
                this.product.category_id = this.selected_child_category.id || this.selected_parent_category.id;

                console.log(this.link);

                this.$hpatch(this.link, this.product)
                    .then((response) => {
                        if(response.status >=200 || response.status < 300) {
                            console.log(response);
                            this.product = response.data;
                            this.$notify({
                                group: 'notifications',
                                title: 'Success',
                                text: 'Successfully Saved'
                            });
                        }

                })
            }
        }
    }
</script>
