<template>

    <div class="pa-5" id="shop_filter_container">
        <h3>
            Filter
        </h3>
        <v-form ref="form">
            <v-slider
                    v-model="min_price.val"
                    :color="min_price.color"
                    :label="min_price.label"
                    :step="0.1"
            ></v-slider>
            <v-text-field
                    v-model="min_price.val"
                    :label="min_price.label"
            ></v-text-field>

            <v-slider
                    v-model="max_price.val"
                    :label="max_price.label"
                    :track-color="max_price.color"
                    :step="0.1"
                    max="1000"
            ></v-slider>
            <v-text-field
                    v-model="max_price.val"
                    :label="max_price.label"
            ></v-text-field>

            <v-btn
                    block
                  color="success"
                  @click="price_set(min_price.val, max_price.val)"
                >
                  Filter
                </v-btn>
        </v-form>


    </div>
</template>

<script>
    export default {
        name: "store-filter",
        props: {
            price_set:Function
        },
        data() {
            return {
                min_price: { label: 'Min Price', val: 0.2, color: 'orange darken-3' },
                max_price: { label: 'Max Price', val: 500, color: 'orange darken-3' },
            };
        },
        created() {

        },
        mounted() {

        },
        methods:{

        },
        computed: {

        }
    }
</script>

<style lang="scss" scoped>

</style>
