<template>
  <div class="toolbar">

    <v-toolbar text
               id="core-toolbar"
               class="elevation-2"
               color="blue-grey darken-4"
               style="border-radius: 0">
      <v-toolbar-title>
        <v-btn icon @click.stop="toggleDrawer">
          <v-icon color="white">mdi-view-list</v-icon>
        </v-btn>
        <span class="font-weight-light">{{ title }}</span>
      </v-toolbar-title>

      <v-spacer> </v-spacer>

      <v-toolbar-items>
        <v-flex align-center layout py-2>

          <v-text-field label="Search..."
                        style="display: none"
                        v-if="$store.state.responsive"
                        class="mr-4 mt-2 uk-margin-remove"
                        hide-details>
          </v-text-field>

          <v-btn text color="white"
                 v-if="$store.getters.user_groups_names.includes('Super Admin')"
                 @click="copyToken">
            <v-icon>mdi-fingerprint</v-icon>
            <span>JWT</span>
          </v-btn>

          <v-btn text to="/c-panel" color="white" >
            <v-icon>mdi-view-dashboard</v-icon>
            <span>Dashboard</span>
          </v-btn>

          <v-btn text to="/" color="white" >
            <v-icon>mdi-shopping</v-icon>
            <span>Store</span>
          </v-btn>

<!--          <v-btn text to="/messenger" color="white" >-->
<!--            <v-icon>mdi-message</v-icon>-->
<!--            <span>Chat</span>-->
<!--          </v-btn>-->
        </v-flex>
      </v-toolbar-items>
      <template v-if="$vuetify.breakpoint.smAndUp">
        <w-login-widget :theme="'light'"> </w-login-widget>
      </template>
    </v-toolbar>


  </div>

</template>

<script>
export default {
  data: () => ({
    title: "Control Panel",
  }),
  watch: {
    '$route' (val) {
      this.title = val.name
    }
  },
  methods: {
    toggleDrawer(){
      this.$emit('toggle-drawer')
    },
    copyToken(){
      navigator.clipboard.writeText(this.$store.getters.access_token)
    }
  }
}
</script>

<style>
  #core-toolbar a {
    text-decoration: none;
  }
</style>
