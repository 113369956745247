<template>
    <v-snackbar
            v-model="notification"
            :color="'success'"
            :multi-line="false"
            :timeout="3000"
            :vertical="false"
            :top="true"
    >
        {{ notification_message }}
        <v-btn
                dark
                text
                @click="notification = false"
        >
            Close
        </v-btn>
    </v-snackbar>
</template>

<script>

export default {
    name: "flash-item",
    props: {
        notification: Boolean,
        notification_message: String
    },

  data () {
    return {
      //
    }
  }
}
</script>
