import Vue from 'vue'
import moment from 'moment'

Vue.filter('simple_date', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

Vue.filter('name', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

Vue.filter('simple_date', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

Vue.filter('simple_date', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});

Vue.filter('simple_date', function(value) {
    if (value) {
        return moment(String(value)).format('MM/DD/YYYY')
    }
});