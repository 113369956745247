<template>
    <v-container fill-height>
        <v-layout grid-list row wrap  align-center justify-center>

            <v-flex xs12 md4>

                <v-layout column>
                    <h3 style="margin:1px">Title</h3>
                    <h5 style="margin:1px">description</h5>
                </v-layout>

            </v-flex>

            <v-spacer> </v-spacer>

            <v-flex xs12 md4 wrap>

                <v-text-field
                        v-model="search"
                        append-icon="mdi-search"
                        label="Search"
                        single-line
                        hide-details>
                </v-text-field>

            </v-flex>

            <v-flex xs12 ref="table_ref">
                <v-data-table :headers="headers"
                              :items="invoices"
                              header-props.sort-icon="mdi-menu-up"
                              item-key="id"
                              hide-default-footer>

                    <template slot="headers" slot-scope="props">

                        <tr>
                            <th v-for="header in props.headers"
                                :key="header.value"
                                ref="fixed_cell">

                                <v-tooltip slot="append" top dark open-delay="300">

                                    <span slot="activator" class="subheading font-weight-light">
                                        <v-icon v-if="header.sortable" small>mdi-menu-up</v-icon>
                                        <span v-if="!header.icon">{{ header.text }}</span>
                                        <v-icon v-else>{{ header.icon }}</v-icon>
                                    </span>

                                    <span>{{ header.alt || header.text }}</span>

                                </v-tooltip>

                            </th>

                        </tr>
                    </template>

                    <template slot="items" slot-scope="props">

                        <td v-for="(header, key, index) in headers" class="elf">

                            <v-layout>
                                {{ header.value }} - {{ props.item[header.value] }}
                            </v-layout>
                        </td>

                    </template>

                </v-data-table>
            </v-flex>


        </v-layout>
    </v-container>
</template>

<script>
    import axios from 'axios';

    export default {
        mounted () {

            this.$nextTick(function() {
                window.addEventListener('resize', this.setFixedCells);
            });

            axios.get('/test/data/test_02_structure.json')
                .then(response => {
                    //console.log(response);
                    this.headers = response.data.headers;
                    this.invoices = response.data.invoices;
                    this.unique_data_row_key = response.data.unique_data_row_key;
                })
                .catch(error => {
                    console.log(error);
                    this.errored = true;
                })
                .then(()=>{
                    console.log(this.$refs['table_ref']);
                    this.ref_table = this.$refs['table_ref'];
                    this.ref_parent = this.ref_table.offsetParent;
                })
                .finally(this.setFixedCells);
        },
        computed : {
            top_offset : function () {
                if(this.ref_table !== undefined && this.ref_parent !== undefined)
                    return (this.ref_table.getBoundingClientRect().top - this.ref_parent.getBoundingClientRect().top);
                return 0;
            },
            left_offset : function () {
                if(this.ref_table !== undefined && this.ref_parent !== undefined)
                    return this.ref_table.getBoundingClientRect().left - this.ref_parent.getBoundingClientRect().left;
                return 0;
            }
        },
        methods : {
            setFixedCells: function (){

                let fixed_el = this.ref_table.getElementsByClassName('elf');

                // let backgroundColor = this.ref_table.getElementsByTagName('table')[0];
                // console.log(backgroundColor);

                for( let i = 0; i< fixed_el.length; i++){
                    let row = fixed_el[i].parentElement;

                    let tempDiv = document.createElement('td');
                    tempDiv.setIdentifier('elf-')
                    tempDiv.innerHTML = fixed_el[i].innerHTML;
                    tempDiv.style = fixed_el[i].style;
                    tempDiv.style.padding = '0 !important';
                    tempDiv.style.height = row.clientHeight + 'px';

                    tempDiv.children[0].style.wordWrap = 'break-word';
                    tempDiv.children[0].style.padding = '2px';
                    tempDiv.children[0].style.maxWidth = (fixed_el[i].clientWidth) + 'px';
                    tempDiv.children[0].style.position = 'absolute';

                    tempDiv.children[0].style.top =  (fixed_el[i].offsetTop + fixed_el[i].children[0].offsetTop + this.top_offset) + 'px';
                    tempDiv.children[0].style.left =  (fixed_el[i].offsetLeft + fixed_el[i].children[0].offsetLeft + this.left_offset) + 'px';

                    console.log(tempDiv);

                    row.insertAdjacentElement('beforeend', tempDiv);

                }
            }

        },
        watch:{

        },
        data: () => ({
            search: '',
            headers: undefined,
            invoices: undefined,
            errored: false,

            windowHeight: 0,

            ref_table: undefined,
            ref_parent: undefined,
            fixed_cells: []

        })
    }
</script>

<style scoped>

</style>
